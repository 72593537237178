import { P as qt, Q as be, R as Nt, S as Pe, U as Gt, V as jt, W as at, X as Zt, Y as We, Z as Xt, _ as Qt, g as ne, p as ke } from "./color-scale-utils-f3PWy_Op.mjs";
import { $ as ye, T as V, o as z } from "./axis-scales-CPuwbFQf.mjs";
var Jt = /\s/;
function Kt(e) {
  for (var t = e.length; t-- && Jt.test(e.charAt(t)););
  return t;
}
var en = /^\s+/;
function tn(e) {
  return e && e.slice(0, Kt(e) + 1).replace(en, "");
}
var $e = NaN,
  nn = /^[-+]0x[0-9a-f]+$/i,
  rn = /^0b[01]+$/i,
  on = /^0o[0-7]+$/i,
  sn = parseInt;
function Ye(e) {
  if (typeof e == "number") return e;
  if (qt(e)) return $e;
  if (be(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = be(t) ? t + "" : t;
  }
  if (typeof e != "string") return e === 0 ? e : +e;
  e = tn(e);
  var n = rn.test(e);
  return n || on.test(e) ? sn(e.slice(2), n ? 2 : 8) : nn.test(e) ? $e : +e;
}
var pe = function () {
    return Nt.Date.now();
  },
  an = "Expected a function",
  un = Math.max,
  cn = Math.min;
function ln(e, t, n) {
  var r,
    i,
    o,
    s,
    a,
    c,
    g = 0,
    m = !1,
    d = !1,
    h = !0;
  if (typeof e != "function") throw new TypeError(an);
  t = Ye(t) || 0, be(n) && (m = !!n.leading, d = "maxWait" in n, o = d ? un(Ye(n.maxWait) || 0, t) : o, h = "trailing" in n ? !!n.trailing : h);
  function T(f) {
    var S = r,
      W = i;
    return r = i = void 0, g = f, s = e.apply(W, S), s;
  }
  function M(f) {
    return g = f, a = setTimeout(P, t), m ? T(f) : s;
  }
  function E(f) {
    var S = f - c,
      W = f - g,
      ae = t - S;
    return d ? cn(ae, o - W) : ae;
  }
  function H(f) {
    var S = f - c,
      W = f - g;
    return c === void 0 || S >= t || S < 0 || d && W >= o;
  }
  function P() {
    var f = pe();
    if (H(f)) return Q(f);
    a = setTimeout(P, E(f));
  }
  function Q(f) {
    return a = void 0, h && r ? T(f) : (r = i = void 0, s);
  }
  function A() {
    a !== void 0 && clearTimeout(a), g = 0, r = c = i = a = void 0;
  }
  function O() {
    return a === void 0 ? s : Q(pe());
  }
  function N() {
    var f = pe(),
      S = H(f);
    if (r = arguments, i = this, c = f, S) {
      if (a === void 0) return M(c);
      if (d) return clearTimeout(a), a = setTimeout(P, t), T(c);
    }
    return a === void 0 && (a = setTimeout(P, t)), s;
  }
  return N.cancel = A, N.flush = O, N;
}
function fn(e, t, n) {
  e = +e, t = +t, n = (i = arguments.length) < 2 ? (t = e, e = 0, 1) : i < 3 ? 1 : +n;
  for (var r = -1, i = Math.max(0, Math.ceil((t - e) / n)) | 0, o = new Array(i); ++r < i;) o[r] = e + r * n;
  return o;
}
function D(e) {
  return typeof e == "string" ? new Pe([[document.querySelector(e)]], [document.documentElement]) : new Pe([[e]], Gt);
}
function hn() {
  var e = jt().unknown(void 0),
    t = e.domain,
    n = e.range,
    r = 0,
    i = 1,
    o,
    s,
    a = !1,
    c = 0,
    g = 0,
    m = 0.5;
  delete e.unknown;
  function d() {
    var h = t().length,
      T = i < r,
      M = T ? i : r,
      E = T ? r : i;
    o = (E - M) / Math.max(1, h - c + g * 2), a && (o = Math.floor(o)), M += (E - M - o * (h - c)) * m, s = o * (1 - c), a && (M = Math.round(M), s = Math.round(s));
    var H = fn(h).map(function (P) {
      return M + o * P;
    });
    return n(T ? H.reverse() : H);
  }
  return e.domain = function (h) {
    return arguments.length ? (t(h), d()) : t();
  }, e.range = function (h) {
    return arguments.length ? ([r, i] = h, r = +r, i = +i, d()) : [r, i];
  }, e.rangeRound = function (h) {
    return [r, i] = h, r = +r, i = +i, a = !0, d();
  }, e.bandwidth = function () {
    return s;
  }, e.step = function () {
    return o;
  }, e.round = function (h) {
    return arguments.length ? (a = !!h, d()) : a;
  }, e.padding = function (h) {
    return arguments.length ? (c = Math.min(1, g = +h), d()) : c;
  }, e.paddingInner = function (h) {
    return arguments.length ? (c = Math.min(1, h), d()) : c;
  }, e.paddingOuter = function (h) {
    return arguments.length ? (g = +h, d()) : g;
  }, e.align = function (h) {
    return arguments.length ? (m = Math.max(0, Math.min(1, h)), d()) : m;
  }, e.copy = function () {
    return hn(t(), [r, i]).round(a).paddingInner(c).paddingOuter(g).align(m);
  }, at.apply(d(), arguments);
}
function gn(e, t) {
  e = e.slice();
  var n = 0,
    r = e.length - 1,
    i = e[n],
    o = e[r],
    s;
  return o < i && (s = n, n = r, r = s, s = i, i = o, o = s), e[n] = t.floor(i), e[r] = t.ceil(o), e;
}
const we = /* @__PURE__ */new Date(),
  Te = /* @__PURE__ */new Date();
function x(e, t, n, r) {
  function i(o) {
    return e(o = arguments.length === 0 ? /* @__PURE__ */new Date() : /* @__PURE__ */new Date(+o)), o;
  }
  return i.floor = o => (e(o = /* @__PURE__ */new Date(+o)), o), i.ceil = o => (e(o = new Date(o - 1)), t(o, 1), e(o), o), i.round = o => {
    const s = i(o),
      a = i.ceil(o);
    return o - s < a - o ? s : a;
  }, i.offset = (o, s) => (t(o = /* @__PURE__ */new Date(+o), s == null ? 1 : Math.floor(s)), o), i.range = (o, s, a) => {
    const c = [];
    if (o = i.ceil(o), a = a == null ? 1 : Math.floor(a), !(o < s) || !(a > 0)) return c;
    let g;
    do c.push(g = /* @__PURE__ */new Date(+o)), t(o, a), e(o); while (g < o && o < s);
    return c;
  }, i.filter = o => x(s => {
    if (s >= s) for (; e(s), !o(s);) s.setTime(s - 1);
  }, (s, a) => {
    if (s >= s) if (a < 0) for (; ++a <= 0;) for (; t(s, -1), !o(s););else for (; --a >= 0;) for (; t(s, 1), !o(s););
  }), n && (i.count = (o, s) => (we.setTime(+o), Te.setTime(+s), e(we), e(Te), Math.floor(n(we, Te))), i.every = o => (o = Math.floor(o), !isFinite(o) || !(o > 0) ? null : o > 1 ? i.filter(r ? s => r(s) % o === 0 : s => i.count(0, s) % o === 0) : i)), i;
}
const ce = x(() => {}, (e, t) => {
  e.setTime(+e + t);
}, (e, t) => t - e);
ce.every = e => (e = Math.floor(e), !isFinite(e) || !(e > 0) ? null : e > 1 ? x(t => {
  t.setTime(Math.floor(t / e) * e);
}, (t, n) => {
  t.setTime(+t + n * e);
}, (t, n) => (n - t) / e) : ce);
ce.range;
const k = 1e3,
  I = k * 60,
  $ = I * 60,
  _ = $ * 24,
  Ue = _ * 7,
  _e = _ * 30,
  Ce = _ * 365,
  K = x(e => {
    e.setTime(e - e.getMilliseconds());
  }, (e, t) => {
    e.setTime(+e + t * k);
  }, (e, t) => (t - e) / k, e => e.getUTCSeconds());
K.range;
const Ee = x(e => {
  e.setTime(e - e.getMilliseconds() - e.getSeconds() * k);
}, (e, t) => {
  e.setTime(+e + t * I);
}, (e, t) => (t - e) / I, e => e.getMinutes());
Ee.range;
const mn = x(e => {
  e.setUTCSeconds(0, 0);
}, (e, t) => {
  e.setTime(+e + t * I);
}, (e, t) => (t - e) / I, e => e.getUTCMinutes());
mn.range;
const Fe = x(e => {
  e.setTime(e - e.getMilliseconds() - e.getSeconds() * k - e.getMinutes() * I);
}, (e, t) => {
  e.setTime(+e + t * $);
}, (e, t) => (t - e) / $, e => e.getHours());
Fe.range;
const dn = x(e => {
  e.setUTCMinutes(0, 0, 0);
}, (e, t) => {
  e.setTime(+e + t * $);
}, (e, t) => (t - e) / $, e => e.getUTCHours());
dn.range;
const se = x(e => e.setHours(0, 0, 0, 0), (e, t) => e.setDate(e.getDate() + t), (e, t) => (t - e - (t.getTimezoneOffset() - e.getTimezoneOffset()) * I) / _, e => e.getDate() - 1);
se.range;
const Re = x(e => {
  e.setUTCHours(0, 0, 0, 0);
}, (e, t) => {
  e.setUTCDate(e.getUTCDate() + t);
}, (e, t) => (t - e) / _, e => e.getUTCDate() - 1);
Re.range;
const yn = x(e => {
  e.setUTCHours(0, 0, 0, 0);
}, (e, t) => {
  e.setUTCDate(e.getUTCDate() + t);
}, (e, t) => (t - e) / _, e => Math.floor(e / _));
yn.range;
function Z(e) {
  return x(t => {
    t.setDate(t.getDate() - (t.getDay() + 7 - e) % 7), t.setHours(0, 0, 0, 0);
  }, (t, n) => {
    t.setDate(t.getDate() + n * 7);
  }, (t, n) => (n - t - (n.getTimezoneOffset() - t.getTimezoneOffset()) * I) / Ue);
}
const me = Z(0),
  le = Z(1),
  pn = Z(2),
  wn = Z(3),
  ee = Z(4),
  Tn = Z(5),
  Cn = Z(6);
me.range;
le.range;
pn.range;
wn.range;
ee.range;
Tn.range;
Cn.range;
function X(e) {
  return x(t => {
    t.setUTCDate(t.getUTCDate() - (t.getUTCDay() + 7 - e) % 7), t.setUTCHours(0, 0, 0, 0);
  }, (t, n) => {
    t.setUTCDate(t.getUTCDate() + n * 7);
  }, (t, n) => (n - t) / Ue);
}
const ut = X(0),
  fe = X(1),
  Sn = X(2),
  Mn = X(3),
  te = X(4),
  xn = X(5),
  bn = X(6);
ut.range;
fe.range;
Sn.range;
Mn.range;
te.range;
xn.range;
bn.range;
const Le = x(e => {
  e.setDate(1), e.setHours(0, 0, 0, 0);
}, (e, t) => {
  e.setMonth(e.getMonth() + t);
}, (e, t) => t.getMonth() - e.getMonth() + (t.getFullYear() - e.getFullYear()) * 12, e => e.getMonth());
Le.range;
const Dn = x(e => {
  e.setUTCDate(1), e.setUTCHours(0, 0, 0, 0);
}, (e, t) => {
  e.setUTCMonth(e.getUTCMonth() + t);
}, (e, t) => t.getUTCMonth() - e.getUTCMonth() + (t.getUTCFullYear() - e.getUTCFullYear()) * 12, e => e.getUTCMonth());
Dn.range;
const B = x(e => {
  e.setMonth(0, 1), e.setHours(0, 0, 0, 0);
}, (e, t) => {
  e.setFullYear(e.getFullYear() + t);
}, (e, t) => t.getFullYear() - e.getFullYear(), e => e.getFullYear());
B.every = e => !isFinite(e = Math.floor(e)) || !(e > 0) ? null : x(t => {
  t.setFullYear(Math.floor(t.getFullYear() / e) * e), t.setMonth(0, 1), t.setHours(0, 0, 0, 0);
}, (t, n) => {
  t.setFullYear(t.getFullYear() + n * e);
});
B.range;
const j = x(e => {
  e.setUTCMonth(0, 1), e.setUTCHours(0, 0, 0, 0);
}, (e, t) => {
  e.setUTCFullYear(e.getUTCFullYear() + t);
}, (e, t) => t.getUTCFullYear() - e.getUTCFullYear(), e => e.getUTCFullYear());
j.every = e => !isFinite(e = Math.floor(e)) || !(e > 0) ? null : x(t => {
  t.setUTCFullYear(Math.floor(t.getUTCFullYear() / e) * e), t.setUTCMonth(0, 1), t.setUTCHours(0, 0, 0, 0);
}, (t, n) => {
  t.setUTCFullYear(t.getUTCFullYear() + n * e);
});
j.range;
function vn(e, t, n, r, i, o) {
  const s = [[K, 1, k], [K, 5, 5 * k], [K, 15, 15 * k], [K, 30, 30 * k], [o, 1, I], [o, 5, 5 * I], [o, 15, 15 * I], [o, 30, 30 * I], [i, 1, $], [i, 3, 3 * $], [i, 6, 6 * $], [i, 12, 12 * $], [r, 1, _], [r, 2, 2 * _], [n, 1, Ue], [t, 1, _e], [t, 3, 3 * _e], [e, 1, Ce]];
  function a(g, m, d) {
    const h = m < g;
    h && ([g, m] = [m, g]);
    const T = d && typeof d.range == "function" ? d : c(g, m, d),
      M = T ? T.range(g, +m + 1) : [];
    return h ? M.reverse() : M;
  }
  function c(g, m, d) {
    const h = Math.abs(m - g) / d,
      T = Zt(([,, H]) => H).right(s, h);
    if (T === s.length) return e.every(We(g / Ce, m / Ce, d));
    if (T === 0) return ce.every(Math.max(We(g, m, d), 1));
    const [M, E] = s[h / s[T - 1][2] < s[T][2] / h ? T - 1 : T];
    return M.every(E);
  }
  return [a, c];
}
const [Un, En] = vn(B, Le, me, se, Fe, Ee);
function Se(e) {
  if (0 <= e.y && e.y < 100) {
    var t = new Date(-1, e.m, e.d, e.H, e.M, e.S, e.L);
    return t.setFullYear(e.y), t;
  }
  return new Date(e.y, e.m, e.d, e.H, e.M, e.S, e.L);
}
function Me(e) {
  if (0 <= e.y && e.y < 100) {
    var t = new Date(Date.UTC(-1, e.m, e.d, e.H, e.M, e.S, e.L));
    return t.setUTCFullYear(e.y), t;
  }
  return new Date(Date.UTC(e.y, e.m, e.d, e.H, e.M, e.S, e.L));
}
function re(e, t, n) {
  return {
    y: e,
    m: t,
    d: n,
    H: 0,
    M: 0,
    S: 0,
    L: 0
  };
}
function Fn(e) {
  var t = e.dateTime,
    n = e.date,
    r = e.time,
    i = e.periods,
    o = e.days,
    s = e.shortDays,
    a = e.months,
    c = e.shortMonths,
    g = ie(i),
    m = oe(i),
    d = ie(o),
    h = oe(o),
    T = ie(s),
    M = oe(s),
    E = ie(a),
    H = oe(a),
    P = ie(c),
    Q = oe(c),
    A = {
      a: Ht,
      A: At,
      b: Ot,
      B: Pt,
      c: null,
      d: Ge,
      e: Ge,
      f: Jn,
      g: ur,
      G: lr,
      H: Zn,
      I: Xn,
      j: Qn,
      L: ct,
      m: Kn,
      M: er,
      p: Wt,
      q: kt,
      Q: Xe,
      s: Qe,
      S: tr,
      u: nr,
      U: rr,
      V: ir,
      w: or,
      W: sr,
      x: null,
      X: null,
      y: ar,
      Y: cr,
      Z: fr,
      "%": Ze
    },
    O = {
      a: $t,
      A: Yt,
      b: _t,
      B: Bt,
      c: null,
      d: je,
      e: je,
      f: dr,
      g: Dr,
      G: Ur,
      H: hr,
      I: gr,
      j: mr,
      L: ft,
      m: yr,
      M: pr,
      p: Vt,
      q: zt,
      Q: Xe,
      s: Qe,
      S: wr,
      u: Tr,
      U: Cr,
      V: Sr,
      w: Mr,
      W: xr,
      x: null,
      X: null,
      y: br,
      Y: vr,
      Z: Er,
      "%": Ze
    },
    N = {
      a: vt,
      A: Ut,
      b: Et,
      B: Ft,
      c: Rt,
      d: qe,
      e: qe,
      f: qn,
      g: ze,
      G: Ve,
      H: Ne,
      I: Ne,
      j: _n,
      L: zn,
      m: Yn,
      M: Bn,
      p: ae,
      q: $n,
      Q: Gn,
      s: jn,
      S: Vn,
      u: An,
      U: On,
      V: Pn,
      w: Hn,
      W: Wn,
      x: Lt,
      X: It,
      y: ze,
      Y: Ve,
      Z: kn,
      "%": Nn
    };
  A.x = f(n, A), A.X = f(r, A), A.c = f(t, A), O.x = f(n, O), O.X = f(r, O), O.c = f(t, O);
  function f(l, y) {
    return function (p) {
      var u = [],
        v = -1,
        C = 0,
        F = l.length,
        R,
        G,
        Oe;
      for (p instanceof Date || (p = /* @__PURE__ */new Date(+p)); ++v < F;) l.charCodeAt(v) === 37 && (u.push(l.slice(C, v)), (G = Be[R = l.charAt(++v)]) != null ? R = l.charAt(++v) : G = R === "e" ? " " : "0", (Oe = y[R]) && (R = Oe(p, G)), u.push(R), C = v + 1);
      return u.push(l.slice(C, v)), u.join("");
    };
  }
  function S(l, y) {
    return function (p) {
      var u = re(1900, void 0, 1),
        v = W(u, l, p += "", 0),
        C,
        F;
      if (v != p.length) return null;
      if ("Q" in u) return new Date(u.Q);
      if ("s" in u) return new Date(u.s * 1e3 + ("L" in u ? u.L : 0));
      if (y && !("Z" in u) && (u.Z = 0), "p" in u && (u.H = u.H % 12 + u.p * 12), u.m === void 0 && (u.m = "q" in u ? u.q : 0), "V" in u) {
        if (u.V < 1 || u.V > 53) return null;
        "w" in u || (u.w = 1), "Z" in u ? (C = Me(re(u.y, 0, 1)), F = C.getUTCDay(), C = F > 4 || F === 0 ? fe.ceil(C) : fe(C), C = Re.offset(C, (u.V - 1) * 7), u.y = C.getUTCFullYear(), u.m = C.getUTCMonth(), u.d = C.getUTCDate() + (u.w + 6) % 7) : (C = Se(re(u.y, 0, 1)), F = C.getDay(), C = F > 4 || F === 0 ? le.ceil(C) : le(C), C = se.offset(C, (u.V - 1) * 7), u.y = C.getFullYear(), u.m = C.getMonth(), u.d = C.getDate() + (u.w + 6) % 7);
      } else ("W" in u || "U" in u) && ("w" in u || (u.w = "u" in u ? u.u % 7 : "W" in u ? 1 : 0), F = "Z" in u ? Me(re(u.y, 0, 1)).getUTCDay() : Se(re(u.y, 0, 1)).getDay(), u.m = 0, u.d = "W" in u ? (u.w + 6) % 7 + u.W * 7 - (F + 5) % 7 : u.w + u.U * 7 - (F + 6) % 7);
      return "Z" in u ? (u.H += u.Z / 100 | 0, u.M += u.Z % 100, Me(u)) : Se(u);
    };
  }
  function W(l, y, p, u) {
    for (var v = 0, C = y.length, F = p.length, R, G; v < C;) {
      if (u >= F) return -1;
      if (R = y.charCodeAt(v++), R === 37) {
        if (R = y.charAt(v++), G = N[R in Be ? y.charAt(v++) : R], !G || (u = G(l, p, u)) < 0) return -1;
      } else if (R != p.charCodeAt(u++)) return -1;
    }
    return u;
  }
  function ae(l, y, p) {
    var u = g.exec(y.slice(p));
    return u ? (l.p = m.get(u[0].toLowerCase()), p + u[0].length) : -1;
  }
  function vt(l, y, p) {
    var u = T.exec(y.slice(p));
    return u ? (l.w = M.get(u[0].toLowerCase()), p + u[0].length) : -1;
  }
  function Ut(l, y, p) {
    var u = d.exec(y.slice(p));
    return u ? (l.w = h.get(u[0].toLowerCase()), p + u[0].length) : -1;
  }
  function Et(l, y, p) {
    var u = P.exec(y.slice(p));
    return u ? (l.m = Q.get(u[0].toLowerCase()), p + u[0].length) : -1;
  }
  function Ft(l, y, p) {
    var u = E.exec(y.slice(p));
    return u ? (l.m = H.get(u[0].toLowerCase()), p + u[0].length) : -1;
  }
  function Rt(l, y, p) {
    return W(l, t, y, p);
  }
  function Lt(l, y, p) {
    return W(l, n, y, p);
  }
  function It(l, y, p) {
    return W(l, r, y, p);
  }
  function Ht(l) {
    return s[l.getDay()];
  }
  function At(l) {
    return o[l.getDay()];
  }
  function Ot(l) {
    return c[l.getMonth()];
  }
  function Pt(l) {
    return a[l.getMonth()];
  }
  function Wt(l) {
    return i[+(l.getHours() >= 12)];
  }
  function kt(l) {
    return 1 + ~~(l.getMonth() / 3);
  }
  function $t(l) {
    return s[l.getUTCDay()];
  }
  function Yt(l) {
    return o[l.getUTCDay()];
  }
  function _t(l) {
    return c[l.getUTCMonth()];
  }
  function Bt(l) {
    return a[l.getUTCMonth()];
  }
  function Vt(l) {
    return i[+(l.getUTCHours() >= 12)];
  }
  function zt(l) {
    return 1 + ~~(l.getUTCMonth() / 3);
  }
  return {
    format: function (l) {
      var y = f(l += "", A);
      return y.toString = function () {
        return l;
      }, y;
    },
    parse: function (l) {
      var y = S(l += "", !1);
      return y.toString = function () {
        return l;
      }, y;
    },
    utcFormat: function (l) {
      var y = f(l += "", O);
      return y.toString = function () {
        return l;
      }, y;
    },
    utcParse: function (l) {
      var y = S(l += "", !0);
      return y.toString = function () {
        return l;
      }, y;
    }
  };
}
var Be = {
    "-": "",
    _: " ",
    0: "0"
  },
  b = /^\s*\d+/,
  Rn = /^%/,
  Ln = /[\\^$*+?|[\]().{}]/g;
function w(e, t, n) {
  var r = e < 0 ? "-" : "",
    i = (r ? -e : e) + "",
    o = i.length;
  return r + (o < n ? new Array(n - o + 1).join(t) + i : i);
}
function In(e) {
  return e.replace(Ln, "\\$&");
}
function ie(e) {
  return new RegExp("^(?:" + e.map(In).join("|") + ")", "i");
}
function oe(e) {
  return new Map(e.map((t, n) => [t.toLowerCase(), n]));
}
function Hn(e, t, n) {
  var r = b.exec(t.slice(n, n + 1));
  return r ? (e.w = +r[0], n + r[0].length) : -1;
}
function An(e, t, n) {
  var r = b.exec(t.slice(n, n + 1));
  return r ? (e.u = +r[0], n + r[0].length) : -1;
}
function On(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.U = +r[0], n + r[0].length) : -1;
}
function Pn(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.V = +r[0], n + r[0].length) : -1;
}
function Wn(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.W = +r[0], n + r[0].length) : -1;
}
function Ve(e, t, n) {
  var r = b.exec(t.slice(n, n + 4));
  return r ? (e.y = +r[0], n + r[0].length) : -1;
}
function ze(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.y = +r[0] + (+r[0] > 68 ? 1900 : 2e3), n + r[0].length) : -1;
}
function kn(e, t, n) {
  var r = /^(Z)|([+-]\d\d)(?::?(\d\d))?/.exec(t.slice(n, n + 6));
  return r ? (e.Z = r[1] ? 0 : -(r[2] + (r[3] || "00")), n + r[0].length) : -1;
}
function $n(e, t, n) {
  var r = b.exec(t.slice(n, n + 1));
  return r ? (e.q = r[0] * 3 - 3, n + r[0].length) : -1;
}
function Yn(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.m = r[0] - 1, n + r[0].length) : -1;
}
function qe(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.d = +r[0], n + r[0].length) : -1;
}
function _n(e, t, n) {
  var r = b.exec(t.slice(n, n + 3));
  return r ? (e.m = 0, e.d = +r[0], n + r[0].length) : -1;
}
function Ne(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.H = +r[0], n + r[0].length) : -1;
}
function Bn(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.M = +r[0], n + r[0].length) : -1;
}
function Vn(e, t, n) {
  var r = b.exec(t.slice(n, n + 2));
  return r ? (e.S = +r[0], n + r[0].length) : -1;
}
function zn(e, t, n) {
  var r = b.exec(t.slice(n, n + 3));
  return r ? (e.L = +r[0], n + r[0].length) : -1;
}
function qn(e, t, n) {
  var r = b.exec(t.slice(n, n + 6));
  return r ? (e.L = Math.floor(r[0] / 1e3), n + r[0].length) : -1;
}
function Nn(e, t, n) {
  var r = Rn.exec(t.slice(n, n + 1));
  return r ? n + r[0].length : -1;
}
function Gn(e, t, n) {
  var r = b.exec(t.slice(n));
  return r ? (e.Q = +r[0], n + r[0].length) : -1;
}
function jn(e, t, n) {
  var r = b.exec(t.slice(n));
  return r ? (e.s = +r[0], n + r[0].length) : -1;
}
function Ge(e, t) {
  return w(e.getDate(), t, 2);
}
function Zn(e, t) {
  return w(e.getHours(), t, 2);
}
function Xn(e, t) {
  return w(e.getHours() % 12 || 12, t, 2);
}
function Qn(e, t) {
  return w(1 + se.count(B(e), e), t, 3);
}
function ct(e, t) {
  return w(e.getMilliseconds(), t, 3);
}
function Jn(e, t) {
  return ct(e, t) + "000";
}
function Kn(e, t) {
  return w(e.getMonth() + 1, t, 2);
}
function er(e, t) {
  return w(e.getMinutes(), t, 2);
}
function tr(e, t) {
  return w(e.getSeconds(), t, 2);
}
function nr(e) {
  var t = e.getDay();
  return t === 0 ? 7 : t;
}
function rr(e, t) {
  return w(me.count(B(e) - 1, e), t, 2);
}
function lt(e) {
  var t = e.getDay();
  return t >= 4 || t === 0 ? ee(e) : ee.ceil(e);
}
function ir(e, t) {
  return e = lt(e), w(ee.count(B(e), e) + (B(e).getDay() === 4), t, 2);
}
function or(e) {
  return e.getDay();
}
function sr(e, t) {
  return w(le.count(B(e) - 1, e), t, 2);
}
function ar(e, t) {
  return w(e.getFullYear() % 100, t, 2);
}
function ur(e, t) {
  return e = lt(e), w(e.getFullYear() % 100, t, 2);
}
function cr(e, t) {
  return w(e.getFullYear() % 1e4, t, 4);
}
function lr(e, t) {
  var n = e.getDay();
  return e = n >= 4 || n === 0 ? ee(e) : ee.ceil(e), w(e.getFullYear() % 1e4, t, 4);
}
function fr(e) {
  var t = e.getTimezoneOffset();
  return (t > 0 ? "-" : (t *= -1, "+")) + w(t / 60 | 0, "0", 2) + w(t % 60, "0", 2);
}
function je(e, t) {
  return w(e.getUTCDate(), t, 2);
}
function hr(e, t) {
  return w(e.getUTCHours(), t, 2);
}
function gr(e, t) {
  return w(e.getUTCHours() % 12 || 12, t, 2);
}
function mr(e, t) {
  return w(1 + Re.count(j(e), e), t, 3);
}
function ft(e, t) {
  return w(e.getUTCMilliseconds(), t, 3);
}
function dr(e, t) {
  return ft(e, t) + "000";
}
function yr(e, t) {
  return w(e.getUTCMonth() + 1, t, 2);
}
function pr(e, t) {
  return w(e.getUTCMinutes(), t, 2);
}
function wr(e, t) {
  return w(e.getUTCSeconds(), t, 2);
}
function Tr(e) {
  var t = e.getUTCDay();
  return t === 0 ? 7 : t;
}
function Cr(e, t) {
  return w(ut.count(j(e) - 1, e), t, 2);
}
function ht(e) {
  var t = e.getUTCDay();
  return t >= 4 || t === 0 ? te(e) : te.ceil(e);
}
function Sr(e, t) {
  return e = ht(e), w(te.count(j(e), e) + (j(e).getUTCDay() === 4), t, 2);
}
function Mr(e) {
  return e.getUTCDay();
}
function xr(e, t) {
  return w(fe.count(j(e) - 1, e), t, 2);
}
function br(e, t) {
  return w(e.getUTCFullYear() % 100, t, 2);
}
function Dr(e, t) {
  return e = ht(e), w(e.getUTCFullYear() % 100, t, 2);
}
function vr(e, t) {
  return w(e.getUTCFullYear() % 1e4, t, 4);
}
function Ur(e, t) {
  var n = e.getUTCDay();
  return e = n >= 4 || n === 0 ? te(e) : te.ceil(e), w(e.getUTCFullYear() % 1e4, t, 4);
}
function Er() {
  return "+0000";
}
function Ze() {
  return "%";
}
function Xe(e) {
  return +e;
}
function Qe(e) {
  return Math.floor(+e / 1e3);
}
var J, gt;
Fr({
  dateTime: "%x, %X",
  date: "%-m/%-d/%Y",
  time: "%-I:%M:%S %p",
  periods: ["AM", "PM"],
  days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
});
function Fr(e) {
  return J = Fn(e), gt = J.format, J.parse, J.utcFormat, J.utcParse, J;
}
function Rr(e) {
  return new Date(e);
}
function Lr(e) {
  return e instanceof Date ? +e : + /* @__PURE__ */new Date(+e);
}
function mt(e, t, n, r, i, o, s, a, c, g) {
  var m = Xt(),
    d = m.invert,
    h = m.domain,
    T = g(".%L"),
    M = g(":%S"),
    E = g("%I:%M"),
    H = g("%I %p"),
    P = g("%a %d"),
    Q = g("%b %d"),
    A = g("%B"),
    O = g("%Y");
  function N(f) {
    return (c(f) < f ? T : a(f) < f ? M : s(f) < f ? E : o(f) < f ? H : r(f) < f ? i(f) < f ? P : Q : n(f) < f ? A : O)(f);
  }
  return m.invert = function (f) {
    return new Date(d(f));
  }, m.domain = function (f) {
    return arguments.length ? h(Array.from(f, Lr)) : h().map(Rr);
  }, m.ticks = function (f) {
    var S = h();
    return e(S[0], S[S.length - 1], f ?? 10);
  }, m.tickFormat = function (f, S) {
    return S == null ? N : g(S);
  }, m.nice = function (f) {
    var S = h();
    return (!f || typeof f.range != "function") && (f = t(S[0], S[S.length - 1], f ?? 10)), f ? h(gn(S, f)) : m;
  }, m.copy = function () {
    return Qt(m, mt(e, t, n, r, i, o, s, a, c, g));
  }, m;
}
function Fi() {
  return at.apply(mt(Un, En, B, Le, me, se, Fe, Ee, K, gt).domain([new Date(2e3, 0, 1), new Date(2e3, 0, 2)]), arguments);
}
const Ri = Math.abs,
  Li = Math.atan2,
  Ii = Math.cos,
  Hi = Math.max,
  Ai = Math.min,
  Oi = Math.sin,
  Pi = Math.sqrt,
  Wi = 1e-12,
  Ie = Math.PI,
  Je = Ie / 2,
  ki = 2 * Ie;
function $i(e) {
  return e > 1 ? 0 : e < -1 ? Ie : Math.acos(e);
}
function Yi(e) {
  return e >= 1 ? Je : e <= -1 ? -Je : Math.asin(e);
}
function dt(e) {
  this._context = e;
}
dt.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._point = 0;
  },
  lineEnd: function () {
    (this._line || this._line !== 0 && this._point === 1) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (e, t) {
    switch (e = +e, t = +t, this._point) {
      case 0:
        this._point = 1, this._line ? this._context.lineTo(e, t) : this._context.moveTo(e, t);
        break;
      case 1:
        this._point = 2;
      default:
        this._context.lineTo(e, t);
        break;
    }
  }
};
function _i(e) {
  return new dt(e);
}
function Ke() {}
function yt(e) {
  this._context = e;
}
yt.prototype = {
  areaStart: Ke,
  areaEnd: Ke,
  lineStart: function () {
    this._point = 0;
  },
  lineEnd: function () {
    this._point && this._context.closePath();
  },
  point: function (e, t) {
    e = +e, t = +t, this._point ? this._context.lineTo(e, t) : (this._point = 1, this._context.moveTo(e, t));
  }
};
function Bi(e) {
  return new yt(e);
}
function Ir(e, t) {
  if (e.match(/^[a-z]+:\/\//i)) return e;
  if (e.match(/^\/\//)) return window.location.protocol + e;
  if (e.match(/^[a-z]+:/i)) return e;
  const n = document.implementation.createHTMLDocument(),
    r = n.createElement("base"),
    i = n.createElement("a");
  return n.head.appendChild(r), n.body.appendChild(i), t && (r.href = t), i.href = e, i.href;
}
const Hr = /* @__PURE__ */(() => {
  let e = 0;
  const t = () =>
  // eslint-disable-next-line no-bitwise
  `0000${(Math.random() * 36 ** 4 << 0).toString(36)}`.slice(-4);
  return () => (e += 1, `u${t()}${e}`);
})();
function Y(e) {
  const t = [];
  for (let n = 0, r = e.length; n < r; n++) t.push(e[n]);
  return t;
}
function he(e, t) {
  const r = (e.ownerDocument.defaultView || window).getComputedStyle(e).getPropertyValue(t);
  return r ? parseFloat(r.replace("px", "")) : 0;
}
function Ar(e) {
  const t = he(e, "border-left-width"),
    n = he(e, "border-right-width");
  return e.clientWidth + t + n;
}
function Or(e) {
  const t = he(e, "border-top-width"),
    n = he(e, "border-bottom-width");
  return e.clientHeight + t + n;
}
function pt(e, t = {}) {
  const n = t.width || Ar(e),
    r = t.height || Or(e);
  return {
    width: n,
    height: r
  };
}
function Pr() {
  let e, t;
  try {
    t = process;
  } catch {}
  const n = t && t.env ? t.env.devicePixelRatio : null;
  return n && (e = parseInt(n, 10), Number.isNaN(e) && (e = 1)), e || window.devicePixelRatio || 1;
}
const L = 16384;
function Wr(e) {
  (e.width > L || e.height > L) && (e.width > L && e.height > L ? e.width > e.height ? (e.height *= L / e.width, e.width = L) : (e.width *= L / e.height, e.height = L) : e.width > L ? (e.height *= L / e.width, e.width = L) : (e.width *= L / e.height, e.height = L));
}
function ge(e) {
  return new Promise((t, n) => {
    const r = new Image();
    r.decode = () => t(r), r.onload = () => t(r), r.onerror = n, r.crossOrigin = "anonymous", r.decoding = "async", r.src = e;
  });
}
async function kr(e) {
  return Promise.resolve().then(() => new XMLSerializer().serializeToString(e)).then(encodeURIComponent).then(t => `data:image/svg+xml;charset=utf-8,${t}`);
}
async function $r(e, t, n) {
  const r = "http://www.w3.org/2000/svg",
    i = document.createElementNS(r, "svg"),
    o = document.createElementNS(r, "foreignObject");
  return i.setAttribute("width", `${t}`), i.setAttribute("height", `${n}`), i.setAttribute("viewBox", `0 0 ${t} ${n}`), o.setAttribute("width", "100%"), o.setAttribute("height", "100%"), o.setAttribute("x", "0"), o.setAttribute("y", "0"), o.setAttribute("externalResourcesRequired", "true"), i.appendChild(o), o.appendChild(e), kr(i);
}
const U = (e, t) => {
  if (e instanceof t) return !0;
  const n = Object.getPrototypeOf(e);
  return n === null ? !1 : n.constructor.name === t.name || U(n, t);
};
function Yr(e) {
  const t = e.getPropertyValue("content");
  return `${e.cssText} content: '${t.replace(/'|"/g, "")}';`;
}
function _r(e) {
  return Y(e).map(t => {
    const n = e.getPropertyValue(t),
      r = e.getPropertyPriority(t);
    return `${t}: ${n}${r ? " !important" : ""};`;
  }).join(" ");
}
function Br(e, t, n) {
  const r = `.${e}:${t}`,
    i = n.cssText ? Yr(n) : _r(n);
  return document.createTextNode(`${r}{${i}}`);
}
function et(e, t, n) {
  const r = window.getComputedStyle(e, n),
    i = r.getPropertyValue("content");
  if (i === "" || i === "none") return;
  const o = Hr();
  try {
    t.className = `${t.className} ${o}`;
  } catch {
    return;
  }
  const s = document.createElement("style");
  s.appendChild(Br(o, n, r)), t.appendChild(s);
}
function Vr(e, t) {
  et(e, t, ":before"), et(e, t, ":after");
}
const tt = "application/font-woff",
  nt = "image/jpeg",
  zr = {
    woff: tt,
    woff2: tt,
    ttf: "application/font-truetype",
    eot: "application/vnd.ms-fontobject",
    png: "image/png",
    jpg: nt,
    jpeg: nt,
    gif: "image/gif",
    tiff: "image/tiff",
    svg: "image/svg+xml",
    webp: "image/webp"
  };
function qr(e) {
  const t = /\.([^./]*?)$/g.exec(e);
  return t ? t[1] : "";
}
function He(e) {
  const t = qr(e).toLowerCase();
  return zr[t] || "";
}
function Nr(e) {
  return e.split(/,/)[1];
}
function De(e) {
  return e.search(/^(data:)/) !== -1;
}
function Gr(e, t) {
  return `data:${t};base64,${e}`;
}
async function wt(e, t, n) {
  const r = await fetch(e, t);
  if (r.status === 404) throw new Error(`Resource "${r.url}" not found`);
  const i = await r.blob();
  return new Promise((o, s) => {
    const a = new FileReader();
    a.onerror = s, a.onloadend = () => {
      try {
        o(n({
          res: r,
          result: a.result
        }));
      } catch (c) {
        s(c);
      }
    }, a.readAsDataURL(i);
  });
}
const xe = {};
function jr(e, t, n) {
  let r = e.replace(/\?.*/, "");
  return n && (r = e), /ttf|otf|eot|woff2?/i.test(r) && (r = r.replace(/.*\//, "")), t ? `[${t}]${r}` : r;
}
async function Ae(e, t, n) {
  const r = jr(e, t, n.includeQueryParams);
  if (xe[r] != null) return xe[r];
  n.cacheBust && (e += (/\?/.test(e) ? "&" : "?") + ( /* @__PURE__ */new Date()).getTime());
  let i;
  try {
    const o = await wt(e, n.fetchRequestInit, ({
      res: s,
      result: a
    }) => (t || (t = s.headers.get("Content-Type") || ""), Nr(a)));
    i = Gr(o, t);
  } catch (o) {
    i = n.imagePlaceholder || "";
    let s = `Failed to fetch resource: ${e}`;
    o && (s = typeof o == "string" ? o : o.message), s && console.warn(s);
  }
  return xe[r] = i, i;
}
async function Zr(e) {
  const t = e.toDataURL();
  return t === "data:," ? e.cloneNode(!1) : ge(t);
}
async function Xr(e, t) {
  if (e.currentSrc) {
    const o = document.createElement("canvas"),
      s = o.getContext("2d");
    o.width = e.clientWidth, o.height = e.clientHeight, s == null || s.drawImage(e, 0, 0, o.width, o.height);
    const a = o.toDataURL();
    return ge(a);
  }
  const n = e.poster,
    r = He(n),
    i = await Ae(n, r, t);
  return ge(i);
}
async function Qr(e) {
  var t;
  try {
    if (!((t = e == null ? void 0 : e.contentDocument) === null || t === void 0) && t.body) return await de(e.contentDocument.body, {}, !0);
  } catch {}
  return e.cloneNode(!1);
}
async function Jr(e, t) {
  return U(e, HTMLCanvasElement) ? Zr(e) : U(e, HTMLVideoElement) ? Xr(e, t) : U(e, HTMLIFrameElement) ? Qr(e) : e.cloneNode(!1);
}
const Kr = e => e.tagName != null && e.tagName.toUpperCase() === "SLOT";
async function ei(e, t, n) {
  var r, i;
  let o = [];
  return Kr(e) && e.assignedNodes ? o = Y(e.assignedNodes()) : U(e, HTMLIFrameElement) && !((r = e.contentDocument) === null || r === void 0) && r.body ? o = Y(e.contentDocument.body.childNodes) : o = Y(((i = e.shadowRoot) !== null && i !== void 0 ? i : e).childNodes), o.length === 0 || U(e, HTMLVideoElement) || (await o.reduce((s, a) => s.then(() => de(a, n)).then(c => {
    c && t.appendChild(c);
  }), Promise.resolve())), t;
}
function ti(e, t) {
  const n = t.style;
  if (!n) return;
  const r = window.getComputedStyle(e);
  r.cssText ? (n.cssText = r.cssText, n.transformOrigin = r.transformOrigin) : Y(r).forEach(i => {
    let o = r.getPropertyValue(i);
    i === "font-size" && o.endsWith("px") && (o = `${Math.floor(parseFloat(o.substring(0, o.length - 2))) - 0.1}px`), U(e, HTMLIFrameElement) && i === "display" && o === "inline" && (o = "block"), i === "d" && t.getAttribute("d") && (o = `path(${t.getAttribute("d")})`), n.setProperty(i, o, r.getPropertyPriority(i));
  });
}
function ni(e, t) {
  U(e, HTMLTextAreaElement) && (t.innerHTML = e.value), U(e, HTMLInputElement) && t.setAttribute("value", e.value);
}
function ri(e, t) {
  if (U(e, HTMLSelectElement)) {
    const n = t,
      r = Array.from(n.children).find(i => e.value === i.getAttribute("value"));
    r && r.setAttribute("selected", "");
  }
}
function ii(e, t) {
  return U(t, Element) && (ti(e, t), Vr(e, t), ni(e, t), ri(e, t)), t;
}
async function oi(e, t) {
  const n = e.querySelectorAll ? e.querySelectorAll("use") : [];
  if (n.length === 0) return e;
  const r = {};
  for (let o = 0; o < n.length; o++) {
    const a = n[o].getAttribute("xlink:href");
    if (a) {
      const c = e.querySelector(a),
        g = document.querySelector(a);
      !c && g && !r[a] && (r[a] = await de(g, t, !0));
    }
  }
  const i = Object.values(r);
  if (i.length) {
    const o = "http://www.w3.org/1999/xhtml",
      s = document.createElementNS(o, "svg");
    s.setAttribute("xmlns", o), s.style.position = "absolute", s.style.width = "0", s.style.height = "0", s.style.overflow = "hidden", s.style.display = "none";
    const a = document.createElementNS(o, "defs");
    s.appendChild(a);
    for (let c = 0; c < i.length; c++) a.appendChild(i[c]);
    e.appendChild(s);
  }
  return e;
}
async function de(e, t, n) {
  return !n && t.filter && !t.filter(e) ? null : Promise.resolve(e).then(r => Jr(r, t)).then(r => ei(e, r, t)).then(r => ii(e, r)).then(r => oi(r, t));
}
const Tt = /url\((['"]?)([^'"]+?)\1\)/g,
  si = /url\([^)]+\)\s*format\((["']?)([^"']+)\1\)/g,
  ai = /src:\s*(?:url\([^)]+\)\s*format\([^)]+\)[,;]\s*)+/g;
function ui(e) {
  const t = e.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");
  return new RegExp(`(url\\(['"]?)(${t})(['"]?\\))`, "g");
}
function ci(e) {
  const t = [];
  return e.replace(Tt, (n, r, i) => (t.push(i), n)), t.filter(n => !De(n));
}
async function li(e, t, n, r, i) {
  try {
    const o = n ? Ir(t, n) : t,
      s = He(t);
    let a;
    return i || (a = await Ae(o, s, r)), e.replace(ui(t), `$1${a}$3`);
  } catch {}
  return e;
}
function fi(e, {
  preferredFontFormat: t
}) {
  return t ? e.replace(ai, n => {
    for (;;) {
      const [r,, i] = si.exec(n) || [];
      if (!i) return "";
      if (i === t) return `src: ${r};`;
    }
  }) : e;
}
function Ct(e) {
  return e.search(Tt) !== -1;
}
async function St(e, t, n) {
  if (!Ct(e)) return e;
  const r = fi(e, n);
  return ci(r).reduce((o, s) => o.then(a => li(a, s, t, n)), Promise.resolve(r));
}
async function ue(e, t, n) {
  var r;
  const i = (r = t.style) === null || r === void 0 ? void 0 : r.getPropertyValue(e);
  if (i) {
    const o = await St(i, null, n);
    return t.style.setProperty(e, o, t.style.getPropertyPriority(e)), !0;
  }
  return !1;
}
async function hi(e, t) {
  (await ue("background", e, t)) || (await ue("background-image", e, t)), (await ue("mask", e, t)) || (await ue("mask-image", e, t));
}
async function gi(e, t) {
  const n = U(e, HTMLImageElement);
  if (!(n && !De(e.src)) && !(U(e, SVGImageElement) && !De(e.href.baseVal))) return;
  const r = n ? e.src : e.href.baseVal,
    i = await Ae(r, He(r), t);
  await new Promise((o, s) => {
    e.onload = o, e.onerror = s;
    const a = e;
    a.decode && (a.decode = o), a.loading === "lazy" && (a.loading = "eager"), n ? (e.srcset = "", e.src = i) : e.href.baseVal = i;
  });
}
async function mi(e, t) {
  const r = Y(e.childNodes).map(i => Mt(i, t));
  await Promise.all(r).then(() => e);
}
async function Mt(e, t) {
  U(e, Element) && (await hi(e, t), await gi(e, t), await mi(e, t));
}
function di(e, t) {
  const {
    style: n
  } = e;
  t.backgroundColor && (n.backgroundColor = t.backgroundColor), t.width && (n.width = `${t.width}px`), t.height && (n.height = `${t.height}px`);
  const r = t.style;
  return r != null && Object.keys(r).forEach(i => {
    n[i] = r[i];
  }), e;
}
const rt = {};
async function it(e) {
  let t = rt[e];
  if (t != null) return t;
  const r = await (await fetch(e)).text();
  return t = {
    url: e,
    cssText: r
  }, rt[e] = t, t;
}
async function ot(e, t) {
  let n = e.cssText;
  const r = /url\(["']?([^"')]+)["']?\)/g,
    o = (n.match(/url\([^)]+\)/g) || []).map(async s => {
      let a = s.replace(r, "$1");
      return a.startsWith("https://") || (a = new URL(a, e.url).href), wt(a, t.fetchRequestInit, ({
        result: c
      }) => (n = n.replace(s, `url(${c})`), [s, c]));
    });
  return Promise.all(o).then(() => n);
}
function st(e) {
  if (e == null) return [];
  const t = [],
    n = /(\/\*[\s\S]*?\*\/)/gi;
  let r = e.replace(n, "");
  const i = new RegExp("((@.*?keyframes [\\s\\S]*?){([\\s\\S]*?}\\s*?)})", "gi");
  for (;;) {
    const c = i.exec(r);
    if (c === null) break;
    t.push(c[0]);
  }
  r = r.replace(i, "");
  const o = /@import[\s\S]*?url\([^)]*\)[\s\S]*?;/gi,
    s = "((\\s*?(?:\\/\\*[\\s\\S]*?\\*\\/)?\\s*?@media[\\s\\S]*?){([\\s\\S]*?)}\\s*?})|(([\\s\\S]*?){([\\s\\S]*?)})",
    a = new RegExp(s, "gi");
  for (;;) {
    let c = o.exec(r);
    if (c === null) {
      if (c = a.exec(r), c === null) break;
      o.lastIndex = a.lastIndex;
    } else a.lastIndex = o.lastIndex;
    t.push(c[0]);
  }
  return t;
}
async function yi(e, t) {
  const n = [],
    r = [];
  return e.forEach(i => {
    if ("cssRules" in i) try {
      Y(i.cssRules || []).forEach((o, s) => {
        if (o.type === CSSRule.IMPORT_RULE) {
          let a = s + 1;
          const c = o.href,
            g = it(c).then(m => ot(m, t)).then(m => st(m).forEach(d => {
              try {
                i.insertRule(d, d.startsWith("@import") ? a += 1 : i.cssRules.length);
              } catch (h) {
                console.error("Error inserting rule from remote css", {
                  rule: d,
                  error: h
                });
              }
            })).catch(m => {
              console.error("Error loading remote css", m.toString());
            });
          r.push(g);
        }
      });
    } catch (o) {
      const s = e.find(a => a.href == null) || document.styleSheets[0];
      i.href != null && r.push(it(i.href).then(a => ot(a, t)).then(a => st(a).forEach(c => {
        s.insertRule(c, i.cssRules.length);
      })).catch(a => {
        console.error("Error loading remote stylesheet", a);
      })), console.error("Error inlining remote css file", o);
    }
  }), Promise.all(r).then(() => (e.forEach(i => {
    if ("cssRules" in i) try {
      Y(i.cssRules || []).forEach(o => {
        n.push(o);
      });
    } catch (o) {
      console.error(`Error while reading CSS rules from ${i.href}`, o);
    }
  }), n));
}
function pi(e) {
  return e.filter(t => t.type === CSSRule.FONT_FACE_RULE).filter(t => Ct(t.style.getPropertyValue("src")));
}
async function wi(e, t) {
  if (e.ownerDocument == null) throw new Error("Provided element is not within a Document");
  const n = Y(e.ownerDocument.styleSheets),
    r = await yi(n, t);
  return pi(r);
}
async function Ti(e, t) {
  const n = await wi(e, t);
  return (await Promise.all(n.map(i => {
    const o = i.parentStyleSheet ? i.parentStyleSheet.href : null;
    return St(i.cssText, o, t);
  }))).join(`
`);
}
async function Ci(e, t) {
  const n = t.fontEmbedCSS != null ? t.fontEmbedCSS : t.skipFonts ? null : await Ti(e, t);
  if (n) {
    const r = document.createElement("style"),
      i = document.createTextNode(n);
    r.appendChild(i), e.firstChild ? e.insertBefore(r, e.firstChild) : e.appendChild(r);
  }
}
async function Si(e, t = {}) {
  const {
      width: n,
      height: r
    } = pt(e, t),
    i = await de(e, t, !0);
  return await Ci(i, t), await Mt(i, t), di(i, t), await $r(i, n, r);
}
async function xt(e, t = {}) {
  const {
      width: n,
      height: r
    } = pt(e, t),
    i = await Si(e, t),
    o = await ge(i),
    s = document.createElement("canvas"),
    a = s.getContext("2d"),
    c = t.pixelRatio || Pr(),
    g = t.canvasWidth || n,
    m = t.canvasHeight || r;
  return s.width = g * c, s.height = m * c, t.skipAutoScale || Wr(s), s.style.width = `${g}`, s.style.height = `${m}`, t.backgroundColor && (a.fillStyle = t.backgroundColor, a.fillRect(0, 0, s.width, s.height)), a.drawImage(o, 0, 0, s.width, s.height), s;
}
async function Mi(e, t = {}) {
  return (await xt(e, t)).toDataURL();
}
async function xi(e, t = {}) {
  return (await xt(e, t)).toDataURL("image/jpeg", t.quality || 1);
}
class bt {
  constructor(t, n) {
    this.model = t, this.services = n, this.init();
  }
  init() {}
  update() {}
  // Used to pass down information to the components
  setModel(t) {
    this.model = t;
  }
  // Used to pass down services to the components
  setServices(t) {
    this.services = t;
  }
}
const bi = "DONT_STYLE_ME_css_styles_verifier";
class ve extends bt {
  // initialized in initializeID() called by init()
  constructor(t, n) {
    super(t, n);
  }
  static getHTMLElementSize(t) {
    return {
      width: t.clientWidth,
      height: t.clientHeight
    };
  }
  static getSVGElementSize(t, n = {
    useAttrs: !1,
    useClientDimensions: !1,
    useBBox: !1,
    useBoundingRect: !1
  }) {
    t.attr || (t = D(t));
    const r = {
        width: 0,
        height: 0
      },
      i = h => {
        h && Object.keys(r).forEach(T => {
          if (h[T]) {
            const M = h[T],
              E = parseFloat(M);
            M && E > r[T] && ("" + M).indexOf("%") === -1 && (r[T] = E);
          }
        });
      },
      o = {
        width: t.attr("width"),
        height: t.attr("height")
      },
      s = t.node();
    let a, c, g, m;
    try {
      typeof s.getBBox == "function" && (a = s.getBBox(), c = {
        width: a.width,
        height: a.height
      });
    } catch (h) {
      console.error(h);
    }
    try {
      typeof (s == null ? void 0 : s.getBoundingClientRect) == "function" && (g = s.getBoundingClientRect(), m = {
        width: g.width,
        height: g.height
      });
    } catch (h) {
      console.error(h);
    }
    let d;
    if (s instanceof SVGSVGElement && (d = {
      width: s.clientWidth,
      height: s.clientHeight
    }), n) {
      if (n.useAttrs && (i(o), r.width > 0 && r.height > 0)) return r;
      if (n.useClientDimensions && (i(d), r.width > 0 && r.height > 0)) return d;
      if (n.useBBox && (i(c), r.width > 0 && r.height > 0)) return c;
      if (n.useBoundingRect && (i(m), r.width > 0 && r.height > 0)) return m;
    }
    try {
      const h = {
        width: ne(t.node(), "width", "baseVal", "value"),
        height: ne(t.node(), "height", "baseVal", "value")
      };
      i(h);
    } catch {
      i(d), i(c), i(o);
    }
    return r;
  }
  static appendOrSelect(t, n) {
    const r = t.select(`${n}`);
    if (r.empty()) {
      let i = n.split("#"),
        o,
        s;
      return i.length === 2 ? (o = i[0], i = i[1].split("."), s = i[0]) : (i = n.split("."), o = i[0]), t.append(o).attr("id", s).attr("class", i.slice(1).join(" "));
    }
    return r;
  }
  init() {
    this.initializeID(), this.styleHolderElement(), this.addMainContainer(), this.model.getOptions().resizable && this.addResizeListener(), this.addHolderListeners(), this.handleFullscreenChange();
  }
  getChartID() {
    return this.chartID;
  }
  getElementOffset(t, n = !1) {
    const r = {
        left: 0,
        top: 0
      },
      i = t.getBoundingClientRect(),
      o = n ? {
        left: 0,
        top: 0
      } : this.getHolder().getBoundingClientRect();
    try {
      r.left = i.left - o.left, r.top = i.top - o.top;
    } catch (s) {
      console.error(s);
    }
    return r;
  }
  generateElementIDString(t) {
    return `chart-${this.chartID}-${t}`;
  }
  initializeID() {
    this.chartID = Math.floor((1 + Math.random()) * 281474976710656).toString(16);
  }
  addMainContainer() {
    const t = this.model.getOptions(),
      n = ne(t, "style", "prefix"),
      r = D(this.getHolder()).append("div").classed(`${ke}--${n}--chart-wrapper`, !0).attr("id", `chart-${this.getChartID()}`).style("height", "100%").style("width", "100%");
    r.append("g").attr("class", bi), this.mainContainer = r.node();
  }
  update() {
    this.styleHolderElement();
  }
  styleHolderElement() {
    const t = this.getHolder(),
      {
        width: n,
        height: r,
        theme: i
      } = this.model.getOptions();
    n !== this.width && (t.style.width = n, this.width = n), r !== this.height && (t.style.height = r, this.height = r), D(this.getHolder()).classed(`${ke}--chart-holder`, !0).attr("data-carbon-theme", i);
  }
  getHolder() {
    return this.model.get("holder");
  }
  exportToJPG() {
    const t = this,
      n = this.model.getOptions(),
      r = this.getHolder(),
      i = D(r);
    i.classed("filled", !0), xi(this.getMainContainer(), {
      quality: 1,
      // Remove toolbar
      filter: o => !(o.classList && o.classList.contains("cds--cc--toolbar"))
    }).then(function (o) {
      var c;
      let s = "myChart";
      const a = ne(n, "fileDownload", "fileName");
      typeof a == "function" ? s = a("jpg") : typeof a == "string" && (s = a), (c = t.services.files) == null || c.downloadImage(o, `${s}.jpg`), i.classed("filled", !1);
    });
  }
  exportToPNG() {
    const t = this,
      n = this.model.getOptions(),
      r = this.getHolder(),
      i = D(r);
    i.classed("filled", !0), Mi(this.getMainContainer(), {
      quality: 1,
      // Remove toolbar
      filter: o => !(o.classList && o.classList.contains("cds--cc--toolbar"))
    }).then(function (o) {
      var c;
      let s = "myChart";
      const a = ne(n, "fileDownload", "fileName");
      typeof a == "function" ? s = a("png") : typeof a == "string" && (s = a), (c = t.services.files) == null || c.downloadImage(o, `${s}.png`), i.classed("filled", !1);
    }).catch(function (o) {
      console.error("oops, something went wrong!", o);
    });
  }
  isFullScreenMode() {
    return D(this.getHolder()).classed("fullscreen");
  }
  toggleFullscreen() {
    const t = this.getHolder();
    D(t).classed("fullscreen") && (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) ? document.exitFullscreen ? document.exitFullscreen() : document.webkitExitFullscreen ? document.webkitExitFullscreen() : document.mozCancelFullScreen ? document.mozCancelFullScreen() : document.msExitFullscreen && document.msExitFullscreen() : t.requestFullscreen ? t.requestFullscreen() : t.webkitRequestFullscreen ? t.webkitRequestFullscreen() : t.mozRequestFullScreen ? t.mozRequestFullScreen() : t.msRequestFullscreen && t.msRequestFullscreen();
  }
  handleFullscreenChange() {
    document.addEventListener("fullscreenchange", () => {
      const t = D(this.getHolder()),
        n = t.classed("fullscreen");
      t.classed("fullscreen", !n);
    });
  }
  setSVGMaxHeight() {
    if (!this.model.getOptions().height) {
      const {
          height: t
        } = ve.getSVGElementSize(D(this.mainContainer), {
          useBBox: !0
        }),
        n = D(this.mainContainer).attr("class"),
        r = D(this.mainContainer).selectAll(`.${n} > svg`);
      let i = 0;
      r.nodes().forEach(function (o) {
        i += Number(ve.getSVGElementSize(D(o), {
          useBBox: !0
        }).height);
      }), i <= t ? D(this.mainContainer).attr("height", i) : D(this.mainContainer).attr("height", "100%");
    }
  }
  getMainContainer() {
    return this.mainContainer;
  }
  addHolderListeners() {
    const t = this.getHolder();
    t && D(t).on("mouseover", () => {
      var n;
      (n = this.services.events) == null || n.dispatchEvent(ye.Chart.MOUSEOVER);
    }).on("mouseout", () => {
      var n;
      (n = this.services.events) == null || n.dispatchEvent(ye.Chart.MOUSEOUT);
    });
  }
  addResizeListener() {
    const t = this.getHolder();
    if (!t) return;
    let n = t.clientWidth,
      r = t.clientHeight;
    const i = ln(() => {
      var s;
      t && (Math.abs(n - t.clientWidth) > 1 || Math.abs(r - t.clientHeight) > 1) && (n = t.clientWidth, r = t.clientHeight, (s = this.services.events) == null || s.dispatchEvent(ye.Chart.RESIZE));
    }, 12.5);
    new ResizeObserver(i).observe(t);
  }
}
class Dt extends bt {
  static appendOrUpdateLinearGradient(t) {
    let n = t.svg.select(`defs linearGradient#${t.id}`);
    n.empty() && (n = t.svg.append("defs").append("linearGradient").attr("id", t.id).attr("x1", t.x1).attr("x2", t.x2).attr("y1", t.y1).attr("y2", t.y2)), n.selectAll("stop").remove(), n.selectAll("stop").data(t.stops).enter().append("stop").attr("offset", r => r.offset).style("stop-color", r => r.color).style("stop-opacity", r => r.opacity);
  }
  static getOffsetRatio(t) {
    return (Math.abs(t[1]) * 100 / Math.abs(t[0] - t[1])).toFixed(2) + "%";
  }
  static getStops(t, n) {
    const r = t[0] < 0 && t[1] > 0;
    let i = [{
      offset: "0%",
      color: n,
      opacity: "0.6"
    }, {
      offset: "80%",
      color: n,
      opacity: "0"
    }];
    return r && (i = [{
      offset: "0%",
      color: n,
      opacity: "0.6"
    }, {
      offset: Dt.getOffsetRatio(t),
      color: n,
      opacity: "0"
    }, {
      offset: "100%",
      color: n,
      opacity: "0.6"
    }]), i;
  }
}
function Vi(e) {
  const t = Di(vi(e), 360);
  return q(t, [0, 10]) || q(t, [350, 0]) ? {
    textAnchor: V.START,
    dominantBaseline: z.MIDDLE
  } : q(t, [10, 80]) ? {
    textAnchor: V.START,
    dominantBaseline: z.HANGING
  } : q(t, [80, 100]) ? {
    textAnchor: V.MIDDLE,
    dominantBaseline: z.HANGING
  } : q(t, [100, 170]) ? {
    textAnchor: V.END,
    dominantBaseline: z.HANGING
  } : q(t, [170, 190]) ? {
    textAnchor: V.END,
    dominantBaseline: z.MIDDLE
  } : q(t, [190, 260]) ? {
    textAnchor: V.END,
    dominantBaseline: z.BASELINE
  } : q(t, [260, 280]) ? {
    textAnchor: V.MIDDLE,
    dominantBaseline: z.BASELINE
  } : {
    textAnchor: V.START,
    dominantBaseline: z.BASELINE
  };
}
function Di(e, t) {
  return (e % t + t) % t;
}
function q(e, [t, n]) {
  return e >= t && e <= n;
}
function vi(e) {
  return e * (180 / Math.PI);
}
function zi(e) {
  return e * (Math.PI / 180);
}
function qi(e, t, n = {
  x: 0,
  y: 0
}) {
  const r = t * Math.cos(e) + n.x,
    i = t * Math.sin(e) + n.y;
  return {
    x: isNaN(r) ? 0 : r,
    y: isNaN(i) ? 0 : i
  };
}
function Ni(e, t) {
  return t * Math.sin(e - Math.PI / 2);
}
export { Ke as A, ve as D, Dt as G, bt as S, Ni as a, vi as b, Ie as c, zi as d, Ii as e, Oi as f, Wi as g, Je as h, ki as i, Pi as j, Ri as k, Li as l, Ai as m, Yi as n, $i as o, qi as p, Hi as q, Vi as r, D as s, Ye as t, _i as u, Fi as v, ln as w, hn as x, Bi as y, gn as z };
