import * as i0 from '@angular/core';
import { Component, Input, NgModule, EventEmitter, Output, HostBinding } from '@angular/core';
import { AlluvialChart, AreaChart, BoxplotChart, BubbleChart, BulletChart, CirclePackChart, ComboChart, DonutChart, ChoroplethChart, GaugeChart, GroupedBarChart, HeatmapChart, HistogramChart, LineChart, LollipopChart, MeterChart, PieChart, RadarChart, ScatterChart, SimpleBarChart, StackedAreaChart, StackedBarChart, TreeChart, TreemapChart, WordCloudChart, arrowLeft, arrowRight, circle, diamond, square, tee, buildStraightPathString } from '@carbon/charts';
const _c0 = a0 => ({
  "fill": a0
});
const _c1 = (a0, a1) => [a0, a1];
const _c2 = a0 => ({
  stroke: a0
});
const _c3 = ["*"];
const _c4 = (a0, a1, a2) => [a0, a1, a2];
const _c5 = (a0, a1) => ({
  "border-color": a0,
  position: a1
});
function CardNodeComponent_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CardNodeComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("mouseenter", function CardNodeComponent_div_1_Template_div_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseEnter.emit($event));
    })("mouseover", function CardNodeComponent_div_1_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOver.emit($event));
    })("mouseout", function CardNodeComponent_div_1_Template_div_mouseout_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOut.emit($event));
    })("mouseleave", function CardNodeComponent_div_1_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseLeave.emit($event));
    })("mousemove", function CardNodeComponent_div_1_Template_div_mousemove_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseMove.emit($event));
    });
    i0.ɵɵtemplate(1, CardNodeComponent_div_1_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const nodeTemplate_r3 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(3, _c4, ctx_r1.namespace, ctx_r1.stacked ? ctx_r1.namespace + "--stacked" : "", ctx_r1.namespace + "--" + ctx_r1.component))("ngStyle", i0.ɵɵpureFunction2(7, _c5, ctx_r1.color, ctx_r1.position));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", nodeTemplate_r3);
  }
}
function CardNodeComponent_button_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CardNodeComponent_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function CardNodeComponent_button_2_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.click.emit($event));
    })("mouseenter", function CardNodeComponent_button_2_Template_button_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseEnter.emit($event));
    })("mouseover", function CardNodeComponent_button_2_Template_button_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOver.emit($event));
    })("mouseout", function CardNodeComponent_button_2_Template_button_mouseout_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOut.emit($event));
    })("mouseleave", function CardNodeComponent_button_2_Template_button_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseLeave.emit($event));
    })("mousemove", function CardNodeComponent_button_2_Template_button_mousemove_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseMove.emit($event));
    });
    i0.ɵɵtemplate(1, CardNodeComponent_button_2_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const nodeTemplate_r3 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(3, _c4, ctx_r1.namespace, ctx_r1.stacked ? ctx_r1.namespace + "--stacked" : "", ctx_r1.namespace + "--" + ctx_r1.component))("ngStyle", i0.ɵɵpureFunction2(7, _c5, ctx_r1.color, ctx_r1.position));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", nodeTemplate_r3);
  }
}
function CardNodeComponent_a_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CardNodeComponent_a_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵlistener("mouseenter", function CardNodeComponent_a_3_Template_a_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseEnter.emit($event));
    })("mouseover", function CardNodeComponent_a_3_Template_a_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOver.emit($event));
    })("mouseout", function CardNodeComponent_a_3_Template_a_mouseout_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOut.emit($event));
    })("mouseleave", function CardNodeComponent_a_3_Template_a_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseLeave.emit($event));
    })("mousemove", function CardNodeComponent_a_3_Template_a_mousemove_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseMove.emit($event));
    });
    i0.ɵɵtemplate(1, CardNodeComponent_a_3_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const nodeTemplate_r3 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(4, _c4, ctx_r1.namespace, ctx_r1.stacked ? ctx_r1.namespace + "--stacked" : "", ctx_r1.namespace + "--" + ctx_r1.component))("ngStyle", i0.ɵɵpureFunction2(8, _c5, ctx_r1.color, ctx_r1.position));
    i0.ɵɵattribute("href", ctx_r1.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", nodeTemplate_r3);
  }
}
function CardNodeComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
const _c6 = (a0, a1, a2, a3) => [a0, a1, a2, a3];
const _c7 = (a0, a1, a2) => ({
  "height.px": a0,
  "width.px": a1,
  position: a2
});
const _c8 = a0 => ({
  position: a0
});
function ShapeNodeComponent_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ShapeNodeComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("mouseenter", function ShapeNodeComponent_div_1_Template_div_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseEnter.emit($event));
    })("mouseover", function ShapeNodeComponent_div_1_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOver.emit($event));
    })("mouseout", function ShapeNodeComponent_div_1_Template_div_mouseout_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOut.emit($event));
    })("mouseleave", function ShapeNodeComponent_div_1_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseLeave.emit($event));
    })("mousemove", function ShapeNodeComponent_div_1_Template_div_mousemove_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseMove.emit($event));
    });
    i0.ɵɵtemplate(1, ShapeNodeComponent_div_1_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const nodeTemplate_r3 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(3, _c6, ctx_r1.namespace, ctx_r1.stacked ? ctx_r1.namespace + "--stacked" : "", ctx_r1.shape ? ctx_r1.namespace + "--" + ctx_r1.shape : "", ctx_r1.namespace + "--" + ctx_r1.component))("ngStyle", i0.ɵɵpureFunction3(8, _c7, ctx_r1.size, ctx_r1.size, ctx_r1.position));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", nodeTemplate_r3);
  }
}
function ShapeNodeComponent_button_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ShapeNodeComponent_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function ShapeNodeComponent_button_2_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.click.emit($event));
    })("mouseenter", function ShapeNodeComponent_button_2_Template_button_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseEnter.emit($event));
    })("mouseover", function ShapeNodeComponent_button_2_Template_button_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOver.emit($event));
    })("mouseout", function ShapeNodeComponent_button_2_Template_button_mouseout_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOut.emit($event));
    })("mouseleave", function ShapeNodeComponent_button_2_Template_button_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseLeave.emit($event));
    })("mousemove", function ShapeNodeComponent_button_2_Template_button_mousemove_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseMove.emit($event));
    });
    i0.ɵɵtemplate(1, ShapeNodeComponent_button_2_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const nodeTemplate_r3 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(3, _c6, ctx_r1.namespace, ctx_r1.stacked ? ctx_r1.namespace + "--stacked" : "", ctx_r1.shape ? ctx_r1.namespace + "--" + ctx_r1.shape : "", ctx_r1.namespace + "--" + ctx_r1.component))("ngStyle", i0.ɵɵpureFunction3(8, _c7, ctx_r1.size, ctx_r1.size, ctx_r1.position));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", nodeTemplate_r3);
  }
}
function ShapeNodeComponent_a_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ShapeNodeComponent_a_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵlistener("mouseenter", function ShapeNodeComponent_a_3_Template_a_mouseenter_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseEnter.emit($event));
    })("mouseover", function ShapeNodeComponent_a_3_Template_a_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOver.emit($event));
    })("mouseout", function ShapeNodeComponent_a_3_Template_a_mouseout_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseOut.emit($event));
    })("mouseleave", function ShapeNodeComponent_a_3_Template_a_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseLeave.emit($event));
    })("mousemove", function ShapeNodeComponent_a_3_Template_a_mousemove_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.mouseMove.emit($event));
    });
    i0.ɵɵtemplate(1, ShapeNodeComponent_a_3_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const nodeTemplate_r3 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(4, _c6, ctx_r1.namespace, ctx_r1.stacked ? ctx_r1.namespace + "--stacked" : "", ctx_r1.shape ? ctx_r1.namespace + "--" + ctx_r1.shape : "", ctx_r1.namespace + "--" + ctx_r1.component))("ngStyle", i0.ɵɵpureFunction3(9, _c7, ctx_r1.size, ctx_r1.size, ctx_r1.position));
    i0.ɵɵattribute("href", ctx_r1.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", nodeTemplate_r3);
  }
}
function ShapeNodeComponent_ng_template_4_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ShapeNodeComponent_ng_template_4_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ShapeNodeComponent_ng_template_4_div_0_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("class", ctx_r1.namespace + "__icon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.renderIcon);
  }
}
function ShapeNodeComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ShapeNodeComponent_ng_template_4_div_0_Template, 2, 2, "div", 7);
    i0.ɵɵelementStart(1, "div", 8)(2, "div");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.renderIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(7, _c8, ctx_r1.bodyPosition));
    i0.ɵɵattribute("class", ctx_r1.namespace + "__body");
    i0.ɵɵadvance();
    i0.ɵɵattribute("class", ctx_r1.namespace + "__title");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.title);
    i0.ɵɵadvance();
    i0.ɵɵattribute("class", ctx_r1.namespace + "__subtitle");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.subtitle, " ");
  }
}
export { Alignments, AreaEvent, ArrowDirections, AxisEvent, AxisFlavor, AxisPositions, AxisTitleOrientations, BarEvent, BoxplotEvent, CalloutDirections, CanvasZoomEvent, CartesianOrientations, ChartEvent, ChartTheme, ChartTypes, ColorClassNameTypes, ColorLegendType, DividerStatus, DominantBaseline, GaugeEvent, GaugeTypes, LayoutAlignItems, LayoutDirection, LayoutGrowth, LegendItemType, LegendOrientations, LegendPositions, LineEvent, ModalEvent, ModelEvent, PieEvent, Projection, RadarEvent, RenderTypes, ScaleTypes, ScatterEvent, Skeletons, Statuses, TextAnchor, ThresholdEvent, TickRotations, ToolbarControlTypes, TooltipEvent, TreeEvent, TreeTypes, TreemapEvent, TruncationTypes, WordCloudEvent, ZoomBarTypes, ZoomDomainEvent, ZoombarEvent } from '@carbon/charts';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * Wrapper around `BaseChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class BaseChartComponent {
  /**
   * Data passed to charts library for displaying
   */
  set data(newData) {
    // If data already exists, that means the chart has been initialized
    const dataExistsAlready = !!this._data;
    this._data = newData;
    if (dataExistsAlready) {
      this.chart?.model.setData(newData);
    }
  }
  get data() {
    return this._data;
  }
  /**
   * Options passed to charts library
   */
  set options(newOptions) {
    // If data already exists, that means the chart has been initialized
    const optionsExistAlready = !!this._options;
    this._options = newOptions;
    if (optionsExistAlready) {
      this.chart?.model.setOptions(newOptions);
    }
  }
  get options() {
    return this._options;
  }
  constructor(elementRef) {
    this.elementRef = elementRef;
  }
  ngOnInit() {
    // Width prop is mandatory for the wrappers
    if (this.width) {
      this.options.width = this.width;
    }
    // Height prop is mandatory for the wrappers
    if (this.height) {
      this.options.height = this.height;
    }
  }
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    console.log('Class that extended BaseChartComponent did not implement ngAfterViewInit().');
  }
  static {
    this.ɵfac = function BaseChartComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BaseChartComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BaseChartComponent,
      selectors: [["ibm-base-chart"]],
      inputs: {
        data: "data",
        options: "options",
        width: "width",
        height: "height"
      },
      decls: 0,
      vars: 0,
      template: function BaseChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-base-chart',
      template: ``
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    data: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }]
  });
})();

/**
 * Wrapper around `AlluvialChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class AlluvialChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new AlluvialChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵAlluvialChartComponent_BaseFactory;
      return function AlluvialChartComponent_Factory(__ngFactoryType__) {
        return (ɵAlluvialChartComponent_BaseFactory || (ɵAlluvialChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(AlluvialChartComponent)))(__ngFactoryType__ || AlluvialChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AlluvialChartComponent,
      selectors: [["ibm-alluvial-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function AlluvialChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlluvialChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-alluvial-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `AreaChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class AreaChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new AreaChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵAreaChartComponent_BaseFactory;
      return function AreaChartComponent_Factory(__ngFactoryType__) {
        return (ɵAreaChartComponent_BaseFactory || (ɵAreaChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(AreaChartComponent)))(__ngFactoryType__ || AreaChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AreaChartComponent,
      selectors: [["ibm-area-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function AreaChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AreaChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-area-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `BoxplotChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class BoxplotChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new BoxplotChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBoxplotChartComponent_BaseFactory;
      return function BoxplotChartComponent_Factory(__ngFactoryType__) {
        return (ɵBoxplotChartComponent_BaseFactory || (ɵBoxplotChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(BoxplotChartComponent)))(__ngFactoryType__ || BoxplotChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BoxplotChartComponent,
      selectors: [["ibm-boxplot-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function BoxplotChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BoxplotChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-boxplot-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `BubbleChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class BubbleChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new BubbleChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBubbleChartComponent_BaseFactory;
      return function BubbleChartComponent_Factory(__ngFactoryType__) {
        return (ɵBubbleChartComponent_BaseFactory || (ɵBubbleChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(BubbleChartComponent)))(__ngFactoryType__ || BubbleChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BubbleChartComponent,
      selectors: [["ibm-bubble-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function BubbleChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BubbleChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-bubble-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `BulletChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class BulletChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new BulletChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBulletChartComponent_BaseFactory;
      return function BulletChartComponent_Factory(__ngFactoryType__) {
        return (ɵBulletChartComponent_BaseFactory || (ɵBulletChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(BulletChartComponent)))(__ngFactoryType__ || BulletChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BulletChartComponent,
      selectors: [["ibm-bullet-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function BulletChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BulletChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-bullet-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `CirclePackChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class CirclePackChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new CirclePackChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCirclePackChartComponent_BaseFactory;
      return function CirclePackChartComponent_Factory(__ngFactoryType__) {
        return (ɵCirclePackChartComponent_BaseFactory || (ɵCirclePackChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CirclePackChartComponent)))(__ngFactoryType__ || CirclePackChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CirclePackChartComponent,
      selectors: [["ibm-circle-pack-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function CirclePackChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CirclePackChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-circle-pack-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `ComboChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class ComboChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new ComboChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵComboChartComponent_BaseFactory;
      return function ComboChartComponent_Factory(__ngFactoryType__) {
        return (ɵComboChartComponent_BaseFactory || (ɵComboChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ComboChartComponent)))(__ngFactoryType__ || ComboChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ComboChartComponent,
      selectors: [["ibm-combo-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function ComboChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComboChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-combo-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `DonutChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class DonutChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new DonutChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵDonutChartComponent_BaseFactory;
      return function DonutChartComponent_Factory(__ngFactoryType__) {
        return (ɵDonutChartComponent_BaseFactory || (ɵDonutChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(DonutChartComponent)))(__ngFactoryType__ || DonutChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DonutChartComponent,
      selectors: [["ibm-donut-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function DonutChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DonutChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-donut-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `ChoroplethChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class ChoroplethChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new ChoroplethChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵChoroplethChartComponent_BaseFactory;
      return function ChoroplethChartComponent_Factory(__ngFactoryType__) {
        return (ɵChoroplethChartComponent_BaseFactory || (ɵChoroplethChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ChoroplethChartComponent)))(__ngFactoryType__ || ChoroplethChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ChoroplethChartComponent,
      selectors: [["ibm-choropleth-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function ChoroplethChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChoroplethChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-choropleth-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * @deprecated
 * Wrapper around `ChoroplethChart` in carbon charts library for deprecated selector.
 *
 * Most functions just call their equivalent from the chart library.
 */
class ExperimentalChoroplethChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new ChoroplethChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵExperimentalChoroplethChartComponent_BaseFactory;
      return function ExperimentalChoroplethChartComponent_Factory(__ngFactoryType__) {
        return (ɵExperimentalChoroplethChartComponent_BaseFactory || (ɵExperimentalChoroplethChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ExperimentalChoroplethChartComponent)))(__ngFactoryType__ || ExperimentalChoroplethChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ExperimentalChoroplethChartComponent,
      selectors: [["ibm-experimental-choropleth-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function ExperimentalChoroplethChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExperimentalChoroplethChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-experimental-choropleth-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `GaugeChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class GaugeChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new GaugeChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵGaugeChartComponent_BaseFactory;
      return function GaugeChartComponent_Factory(__ngFactoryType__) {
        return (ɵGaugeChartComponent_BaseFactory || (ɵGaugeChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(GaugeChartComponent)))(__ngFactoryType__ || GaugeChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: GaugeChartComponent,
      selectors: [["ibm-gauge-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function GaugeChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaugeChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-gauge-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `GroupedBarChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class GroupedBarChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new GroupedBarChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵGroupedBarChartComponent_BaseFactory;
      return function GroupedBarChartComponent_Factory(__ngFactoryType__) {
        return (ɵGroupedBarChartComponent_BaseFactory || (ɵGroupedBarChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(GroupedBarChartComponent)))(__ngFactoryType__ || GroupedBarChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: GroupedBarChartComponent,
      selectors: [["ibm-grouped-bar-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function GroupedBarChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GroupedBarChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-grouped-bar-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `HeatmapChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class HeatmapChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new HeatmapChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵHeatmapChartComponent_BaseFactory;
      return function HeatmapChartComponent_Factory(__ngFactoryType__) {
        return (ɵHeatmapChartComponent_BaseFactory || (ɵHeatmapChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(HeatmapChartComponent)))(__ngFactoryType__ || HeatmapChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HeatmapChartComponent,
      selectors: [["ibm-heatmap-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function HeatmapChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeatmapChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-heatmap-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `HistogramChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class HistogramChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new HistogramChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵHistogramChartComponent_BaseFactory;
      return function HistogramChartComponent_Factory(__ngFactoryType__) {
        return (ɵHistogramChartComponent_BaseFactory || (ɵHistogramChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(HistogramChartComponent)))(__ngFactoryType__ || HistogramChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: HistogramChartComponent,
      selectors: [["ibm-histogram-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function HistogramChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HistogramChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-histogram-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `LineChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class LineChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new LineChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLineChartComponent_BaseFactory;
      return function LineChartComponent_Factory(__ngFactoryType__) {
        return (ɵLineChartComponent_BaseFactory || (ɵLineChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LineChartComponent)))(__ngFactoryType__ || LineChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LineChartComponent,
      selectors: [["ibm-line-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function LineChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LineChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-line-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `LollipopChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class LollipopChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new LollipopChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLollipopChartComponent_BaseFactory;
      return function LollipopChartComponent_Factory(__ngFactoryType__) {
        return (ɵLollipopChartComponent_BaseFactory || (ɵLollipopChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LollipopChartComponent)))(__ngFactoryType__ || LollipopChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LollipopChartComponent,
      selectors: [["ibm-lollipop-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function LollipopChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LollipopChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-lollipop-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `MeterChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class MeterChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new MeterChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMeterChartComponent_BaseFactory;
      return function MeterChartComponent_Factory(__ngFactoryType__) {
        return (ɵMeterChartComponent_BaseFactory || (ɵMeterChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MeterChartComponent)))(__ngFactoryType__ || MeterChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MeterChartComponent,
      selectors: [["ibm-meter-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function MeterChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MeterChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-meter-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `PieChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class PieChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new PieChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵPieChartComponent_BaseFactory;
      return function PieChartComponent_Factory(__ngFactoryType__) {
        return (ɵPieChartComponent_BaseFactory || (ɵPieChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PieChartComponent)))(__ngFactoryType__ || PieChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PieChartComponent,
      selectors: [["ibm-pie-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function PieChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PieChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-pie-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `RadarChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class RadarChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new RadarChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵRadarChartComponent_BaseFactory;
      return function RadarChartComponent_Factory(__ngFactoryType__) {
        return (ɵRadarChartComponent_BaseFactory || (ɵRadarChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(RadarChartComponent)))(__ngFactoryType__ || RadarChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RadarChartComponent,
      selectors: [["ibm-radar-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function RadarChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadarChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-radar-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `ScatterChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class ScatterChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new ScatterChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵScatterChartComponent_BaseFactory;
      return function ScatterChartComponent_Factory(__ngFactoryType__) {
        return (ɵScatterChartComponent_BaseFactory || (ɵScatterChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ScatterChartComponent)))(__ngFactoryType__ || ScatterChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ScatterChartComponent,
      selectors: [["ibm-scatter-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function ScatterChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScatterChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-scatter-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `SimpleBarChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class SimpleBarChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new SimpleBarChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵSimpleBarChartComponent_BaseFactory;
      return function SimpleBarChartComponent_Factory(__ngFactoryType__) {
        return (ɵSimpleBarChartComponent_BaseFactory || (ɵSimpleBarChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SimpleBarChartComponent)))(__ngFactoryType__ || SimpleBarChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SimpleBarChartComponent,
      selectors: [["ibm-simple-bar-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function SimpleBarChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SimpleBarChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-simple-bar-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `StackedAreaChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class StackedAreaChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new StackedAreaChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵStackedAreaChartComponent_BaseFactory;
      return function StackedAreaChartComponent_Factory(__ngFactoryType__) {
        return (ɵStackedAreaChartComponent_BaseFactory || (ɵStackedAreaChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(StackedAreaChartComponent)))(__ngFactoryType__ || StackedAreaChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: StackedAreaChartComponent,
      selectors: [["ibm-stacked-area-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function StackedAreaChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedAreaChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-stacked-area-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `StackedBarChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class StackedBarChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new StackedBarChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵStackedBarChartComponent_BaseFactory;
      return function StackedBarChartComponent_Factory(__ngFactoryType__) {
        return (ɵStackedBarChartComponent_BaseFactory || (ɵStackedBarChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(StackedBarChartComponent)))(__ngFactoryType__ || StackedBarChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: StackedBarChartComponent,
      selectors: [["ibm-stacked-bar-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function StackedBarChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedBarChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-stacked-bar-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `TreeChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class TreeChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new TreeChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTreeChartComponent_BaseFactory;
      return function TreeChartComponent_Factory(__ngFactoryType__) {
        return (ɵTreeChartComponent_BaseFactory || (ɵTreeChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TreeChartComponent)))(__ngFactoryType__ || TreeChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreeChartComponent,
      selectors: [["ibm-tree-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function TreeChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-tree-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `TreemapChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class TreemapChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new TreemapChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTreemapChartComponent_BaseFactory;
      return function TreemapChartComponent_Factory(__ngFactoryType__) {
        return (ɵTreemapChartComponent_BaseFactory || (ɵTreemapChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TreemapChartComponent)))(__ngFactoryType__ || TreemapChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TreemapChartComponent,
      selectors: [["ibm-treemap-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function TreemapChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreemapChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-treemap-chart',
      template: ``
    }]
  }], null, null);
})();

/**
 * Wrapper around `WordCloudChart` in carbon charts library
 *
 * Most functions just call their equivalent from the chart library.
 */
class WordCloudChartComponent extends BaseChartComponent {
  /**
   * Runs after view init to create a chart, attach it to `elementRef` and draw it.
   */
  ngAfterViewInit() {
    this.chart = new WordCloudChart(this.elementRef.nativeElement, {
      data: this.data,
      options: this.options
    });
    Object.assign(this, this.chart);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵWordCloudChartComponent_BaseFactory;
      return function WordCloudChartComponent_Factory(__ngFactoryType__) {
        return (ɵWordCloudChartComponent_BaseFactory || (ɵWordCloudChartComponent_BaseFactory = i0.ɵɵgetInheritedFactory(WordCloudChartComponent)))(__ngFactoryType__ || WordCloudChartComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WordCloudChartComponent,
      selectors: [["ibm-wordcloud-chart"]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function WordCloudChartComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WordCloudChartComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-wordcloud-chart',
      template: ``
    }]
  }], null, null);
})();
class ChartsModule {
  static {
    this.ɵfac = function ChartsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ChartsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ChartsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChartsModule, [{
    type: NgModule,
    args: [{
      declarations: [BaseChartComponent, AlluvialChartComponent, AreaChartComponent, StackedAreaChartComponent, SimpleBarChartComponent, GroupedBarChartComponent, StackedBarChartComponent, BoxplotChartComponent, BubbleChartComponent, BulletChartComponent, ChoroplethChartComponent, DonutChartComponent, ExperimentalChoroplethChartComponent, GaugeChartComponent, HeatmapChartComponent, HistogramChartComponent, LineChartComponent, LollipopChartComponent, PieChartComponent, ScatterChartComponent, MeterChartComponent, RadarChartComponent, ComboChartComponent, TreeChartComponent, TreemapChartComponent, CirclePackChartComponent, WordCloudChartComponent],
      imports: [CommonModule],
      exports: [BaseChartComponent, AlluvialChartComponent, AreaChartComponent, StackedAreaChartComponent, SimpleBarChartComponent, GroupedBarChartComponent, StackedBarChartComponent, BoxplotChartComponent, BubbleChartComponent, BulletChartComponent, ChoroplethChartComponent, DonutChartComponent,
      /**
       * @deprecated Use `ChoroplethChartComponent` instead.
       */
      ExperimentalChoroplethChartComponent, GaugeChartComponent, HeatmapChartComponent, HistogramChartComponent, LineChartComponent, LollipopChartComponent, PieChartComponent, ScatterChartComponent, MeterChartComponent, RadarChartComponent, ComboChartComponent, TreeChartComponent, TreemapChartComponent, CirclePackChartComponent, WordCloudChartComponent]
    }]
  }], null, null);
})();
const carbonPrefix = 'cds';
const template = `
<svg:marker
	[ngClass]="namespace"
	[attr.markerHeight]="height"
	[attr.markerWidth]="width"
	[attr.orient]="orient"
	[attr.id]="id"
	[attr.refX]="refX"
	[attr.refY]="refY"
	markerUnits="userSpaceOnUse">
	<svg:path [attr.d]="d" [ngStyle]="{'fill': color}" ></svg:path>
</svg:marker>
`;
class MarkerComponent {
  constructor() {
    this.d = '';
    this.color = '';
    this.id = '';
    this.orient = 'auto';
    this.height = '';
    this.width = '';
    this.refX = '';
    this.refY = '';
    this.position = 'end';
    this.namespace = `${carbonPrefix}--cc--marker`;
    this.setAttributes = ({
      d,
      id,
      height,
      width
    }) => {
      const xPos = this.position === 'end' ? width / 2 + 0.5 : 0.5;
      const yPos = height / 2;
      this.d = this.d || d;
      this.id = this.id || id;
      this.height = this.height || height;
      this.width = this.width || width;
      this.refX = this.refX || xPos;
      this.refY = this.refY || yPos;
    };
  }
  static {
    this.ɵfac = function MarkerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerComponent,
      selectors: [["ibm-graph-marker"], ["", "ibm-graph-marker", ""]],
      inputs: {
        d: "d",
        color: "color",
        id: "id",
        orient: "orient",
        height: "height",
        width: "width",
        refX: "refX",
        refY: "refY",
        position: "position"
      },
      decls: 2,
      vars: 11,
      consts: [["markerUnits", "userSpaceOnUse", 3, "ngClass"], [3, "ngStyle"]],
      template: function MarkerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "marker", 0);
          i0.ɵɵelement(1, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
          i0.ɵɵattribute("markerHeight", ctx.height)("markerWidth", ctx.width)("orient", ctx.orient)("id", ctx.id)("refX", ctx.refX)("refY", ctx.refY);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(9, _c0, ctx.color));
          i0.ɵɵattribute("d", ctx.d);
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-marker,[ibm-graph-marker]',
      template
    }]
  }], null, {
    d: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    orient: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    refX: [{
      type: Input
    }],
    refY: [{
      type: Input
    }],
    position: [{
      type: Input
    }]
  });
})();
class MarkerArrowLeftComponent extends MarkerComponent {
  ngOnInit() {
    this.setAttributes({
      ...arrowLeft
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMarkerArrowLeftComponent_BaseFactory;
      return function MarkerArrowLeftComponent_Factory(__ngFactoryType__) {
        return (ɵMarkerArrowLeftComponent_BaseFactory || (ɵMarkerArrowLeftComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MarkerArrowLeftComponent)))(__ngFactoryType__ || MarkerArrowLeftComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerArrowLeftComponent,
      selectors: [["ibm-graph-marker-arrow-left"], ["", "ibm-graph-marker-arrow-left", ""]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 11,
      consts: [["markerUnits", "userSpaceOnUse", 3, "ngClass"], [3, "ngStyle"]],
      template: function MarkerArrowLeftComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "marker", 0);
          i0.ɵɵelement(1, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
          i0.ɵɵattribute("markerHeight", ctx.height)("markerWidth", ctx.width)("orient", ctx.orient)("id", ctx.id)("refX", ctx.refX)("refY", ctx.refY);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(9, _c0, ctx.color));
          i0.ɵɵattribute("d", ctx.d);
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerArrowLeftComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-marker-arrow-left,[ibm-graph-marker-arrow-left]',
      template
    }]
  }], null, null);
})();
class MarkerArrowRightComponent extends MarkerComponent {
  ngOnInit() {
    this.setAttributes({
      ...arrowRight
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMarkerArrowRightComponent_BaseFactory;
      return function MarkerArrowRightComponent_Factory(__ngFactoryType__) {
        return (ɵMarkerArrowRightComponent_BaseFactory || (ɵMarkerArrowRightComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MarkerArrowRightComponent)))(__ngFactoryType__ || MarkerArrowRightComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerArrowRightComponent,
      selectors: [["ibm-graph-marker-arrow-right"], ["", "ibm-graph-marker-arrow-right", ""]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 11,
      consts: [["markerUnits", "userSpaceOnUse", 3, "ngClass"], [3, "ngStyle"]],
      template: function MarkerArrowRightComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "marker", 0);
          i0.ɵɵelement(1, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
          i0.ɵɵattribute("markerHeight", ctx.height)("markerWidth", ctx.width)("orient", ctx.orient)("id", ctx.id)("refX", ctx.refX)("refY", ctx.refY);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(9, _c0, ctx.color));
          i0.ɵɵattribute("d", ctx.d);
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerArrowRightComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-marker-arrow-right,[ibm-graph-marker-arrow-right]',
      template
    }]
  }], null, null);
})();
class MarkerShapeNodeComponent extends MarkerComponent {
  ngOnInit() {
    this.setAttributes({
      ...circle
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMarkerShapeNodeComponent_BaseFactory;
      return function MarkerShapeNodeComponent_Factory(__ngFactoryType__) {
        return (ɵMarkerShapeNodeComponent_BaseFactory || (ɵMarkerShapeNodeComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MarkerShapeNodeComponent)))(__ngFactoryType__ || MarkerShapeNodeComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerShapeNodeComponent,
      selectors: [["ibm-graph-marker-circle"], ["", "ibm-graph-marker-circle", ""]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 11,
      consts: [["markerUnits", "userSpaceOnUse", 3, "ngClass"], [3, "ngStyle"]],
      template: function MarkerShapeNodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "marker", 0);
          i0.ɵɵelement(1, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
          i0.ɵɵattribute("markerHeight", ctx.height)("markerWidth", ctx.width)("orient", ctx.orient)("id", ctx.id)("refX", ctx.refX)("refY", ctx.refY);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(9, _c0, ctx.color));
          i0.ɵɵattribute("d", ctx.d);
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerShapeNodeComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-marker-circle,[ibm-graph-marker-circle]',
      template
    }]
  }], null, null);
})();
class MarkerDiamondComponent extends MarkerComponent {
  ngOnInit() {
    this.setAttributes({
      ...diamond
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMarkerDiamondComponent_BaseFactory;
      return function MarkerDiamondComponent_Factory(__ngFactoryType__) {
        return (ɵMarkerDiamondComponent_BaseFactory || (ɵMarkerDiamondComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MarkerDiamondComponent)))(__ngFactoryType__ || MarkerDiamondComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerDiamondComponent,
      selectors: [["ibm-graph-marker-diamond"], ["", "ibm-graph-marker-diamond", ""]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 11,
      consts: [["markerUnits", "userSpaceOnUse", 3, "ngClass"], [3, "ngStyle"]],
      template: function MarkerDiamondComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "marker", 0);
          i0.ɵɵelement(1, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
          i0.ɵɵattribute("markerHeight", ctx.height)("markerWidth", ctx.width)("orient", ctx.orient)("id", ctx.id)("refX", ctx.refX)("refY", ctx.refY);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(9, _c0, ctx.color));
          i0.ɵɵattribute("d", ctx.d);
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerDiamondComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-marker-diamond,[ibm-graph-marker-diamond]',
      template
    }]
  }], null, null);
})();
class MarkerSquareComponent extends MarkerComponent {
  ngOnInit() {
    this.setAttributes({
      ...square
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMarkerSquareComponent_BaseFactory;
      return function MarkerSquareComponent_Factory(__ngFactoryType__) {
        return (ɵMarkerSquareComponent_BaseFactory || (ɵMarkerSquareComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MarkerSquareComponent)))(__ngFactoryType__ || MarkerSquareComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerSquareComponent,
      selectors: [["ibm-graph-marker-square"], ["", "ibm-graph-marker-square", ""]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 11,
      consts: [["markerUnits", "userSpaceOnUse", 3, "ngClass"], [3, "ngStyle"]],
      template: function MarkerSquareComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "marker", 0);
          i0.ɵɵelement(1, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
          i0.ɵɵattribute("markerHeight", ctx.height)("markerWidth", ctx.width)("orient", ctx.orient)("id", ctx.id)("refX", ctx.refX)("refY", ctx.refY);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(9, _c0, ctx.color));
          i0.ɵɵattribute("d", ctx.d);
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerSquareComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-marker-square,[ibm-graph-marker-square]',
      template
    }]
  }], null, null);
})();
class MarkerTeeComponent extends MarkerComponent {
  ngOnInit() {
    this.setAttributes({
      ...tee
    });
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMarkerTeeComponent_BaseFactory;
      return function MarkerTeeComponent_Factory(__ngFactoryType__) {
        return (ɵMarkerTeeComponent_BaseFactory || (ɵMarkerTeeComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MarkerTeeComponent)))(__ngFactoryType__ || MarkerTeeComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkerTeeComponent,
      selectors: [["ibm-graph-marker-tee"], ["", "ibm-graph-marker-tee", ""]],
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 2,
      vars: 11,
      consts: [["markerUnits", "userSpaceOnUse", 3, "ngClass"], [3, "ngStyle"]],
      template: function MarkerTeeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "marker", 0);
          i0.ɵɵelement(1, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
          i0.ɵɵattribute("markerHeight", ctx.height)("markerWidth", ctx.width)("orient", ctx.orient)("id", ctx.id)("refX", ctx.refX)("refY", ctx.refY);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(9, _c0, ctx.color));
          i0.ɵɵattribute("d", ctx.d);
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerTeeComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-marker-tee,[ibm-graph-marker-tee]',
      template
    }]
  }], null, null);
})();
class MarkerModule {
  static {
    this.ɵfac = function MarkerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MarkerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerModule, [{
    type: NgModule,
    args: [{
      declarations: [MarkerComponent, MarkerArrowLeftComponent, MarkerArrowRightComponent, MarkerShapeNodeComponent, MarkerDiamondComponent, MarkerSquareComponent, MarkerTeeComponent],
      imports: [CommonModule],
      exports: [MarkerComponent, MarkerArrowLeftComponent, MarkerArrowRightComponent, MarkerShapeNodeComponent, MarkerDiamondComponent, MarkerSquareComponent, MarkerTeeComponent]
    }]
  }], null, null);
})();
class EdgeComponent {
  constructor() {
    this.color = '';
    this.markerEnd = '';
    this.markerStart = '';
    this.source = {
      x: 0,
      y: 0
    };
    this.target = {
      x: 0,
      y: 0
    };
    this.path = '';
    this.pathClasses = '';
    this.namespace = `${carbonPrefix}--cc--edge`;
    this.straight = buildStraightPathString;
  }
  static {
    this.ɵfac = function EdgeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EdgeComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EdgeComponent,
      selectors: [["ibm-graph-edge"], ["", "ibm-graph-edge", ""]],
      inputs: {
        color: "color",
        markerEnd: "markerEnd",
        markerStart: "markerStart",
        source: "source",
        target: "target",
        variant: "variant",
        path: "path"
      },
      decls: 4,
      vars: 15,
      consts: [[3, "ngClass"], [3, "ngClass", "ngStyle"]],
      template: function EdgeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵnamespaceSVG();
          i0.ɵɵelementStart(0, "g", 0);
          i0.ɵɵelement(1, "path", 0)(2, "path", 0)(3, "path", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(10, _c1, ctx.namespace, ctx.variant ? ctx.namespace + "--" + ctx.variant : ""));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", ctx.namespace + "__container");
          i0.ɵɵattribute("d", ctx.path ? ctx.path : ctx.straight(ctx.source, ctx.target));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", ctx.namespace + "__outer");
          i0.ɵɵattribute("d", ctx.path ? ctx.path : ctx.straight(ctx.source, ctx.target));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", ctx.namespace + "__inner")("ngStyle", i0.ɵɵpureFunction1(13, _c2, ctx.color));
          i0.ɵɵattribute("d", ctx.path ? ctx.path : ctx.straight(ctx.source, ctx.target))("marker-start", ctx.markerStart ? "url(#" + ctx.markerStart + ")" : "")("marker-end", ctx.markerEnd ? "url(#" + ctx.markerEnd + ")" : "");
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EdgeComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-graph-edge, [ibm-graph-edge]',
      template: `
		<svg:g [ngClass]="[namespace, variant ? namespace + '--' + variant : '']">
			<svg:path
				[ngClass]="namespace + '__container'"
				[attr.d]="path ? path : straight(source, target)" />
			<svg:path
				[ngClass]="namespace + '__outer'"
				[attr.d]="path ? path : straight(source, target)" />
			<svg:path
				[ngClass]="namespace + '__inner'"
				[attr.d]="path ? path : straight(source, target)"
				[ngStyle]="{ stroke: color }"
				[attr.marker-start]="markerStart ? 'url(#' + markerStart + ')' : ''"
				[attr.marker-end]="markerEnd ? 'url(#' + markerEnd + ')' : ''" />
		</svg:g>
	`
    }]
  }], null, {
    color: [{
      type: Input
    }],
    markerEnd: [{
      type: Input
    }],
    markerStart: [{
      type: Input
    }],
    source: [{
      type: Input
    }],
    target: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    path: [{
      type: Input
    }]
  });
})();
class EdgeModule {
  static {
    this.ɵfac = function EdgeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EdgeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: EdgeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EdgeModule, [{
    type: NgModule,
    args: [{
      declarations: [EdgeComponent],
      imports: [CommonModule],
      exports: [EdgeComponent]
    }]
  }], null, null);
})();
class CardNodeComponent {
  constructor() {
    this.as = 'div';
    this.href = '';
    this.color = '';
    this.stacked = false;
    this.position = 'static';
    this.click = new EventEmitter();
    this.mouseEnter = new EventEmitter();
    this.mouseOver = new EventEmitter();
    this.mouseOut = new EventEmitter();
    this.mouseLeave = new EventEmitter();
    this.mouseMove = new EventEmitter();
    this.namespace = `${carbonPrefix}--cc--card-node`;
    this.component = 'div';
  }
  ngOnInit() {
    if (this.href) {
      this.component = 'a';
    } else {
      this.component = this.as;
    }
  }
  static {
    this.ɵfac = function CardNodeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardNodeComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CardNodeComponent,
      selectors: [["ibm-diagram-card-node"]],
      inputs: {
        as: "as",
        href: "href",
        color: "color",
        stacked: "stacked",
        position: "position"
      },
      outputs: {
        click: "click",
        mouseEnter: "mouseEnter",
        mouseOver: "mouseOver",
        mouseOut: "mouseOut",
        mouseLeave: "mouseLeave",
        mouseMove: "mouseMove"
      },
      ngContentSelectors: _c3,
      decls: 6,
      vars: 4,
      consts: [["nodeTemplate", ""], [3, "ngSwitch"], ["tabindex", "0", 3, "ngClass", "ngStyle", "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", 4, "ngSwitchCase"], ["tabindex", "0", 3, "ngClass", "ngStyle", "click", "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", 4, "ngSwitchCase"], ["tabindex", "0", 3, "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", "ngClass", "ngStyle"], [4, "ngTemplateOutlet"], ["tabindex", "0", 3, "click", "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", "ngClass", "ngStyle"]],
      template: function CardNodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementContainerStart(0, 1);
          i0.ɵɵtemplate(1, CardNodeComponent_div_1_Template, 2, 10, "div", 2)(2, CardNodeComponent_button_2_Template, 2, 10, "button", 3)(3, CardNodeComponent_a_3_Template, 2, 11, "a", 2);
          i0.ɵɵelementContainerEnd();
          i0.ɵɵtemplate(4, CardNodeComponent_ng_template_4_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngSwitch", ctx.component);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "div");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "button");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "a");
        }
      },
      dependencies: [i1.NgClass, i1.NgTemplateOutlet, i1.NgStyle, i1.NgSwitch, i1.NgSwitchCase],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardNodeComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-diagram-card-node',
      template: `
		<ng-container [ngSwitch]="component">
			<xhtml:div
				*ngSwitchCase="'div'"
				[ngClass]="[
					namespace,
					stacked ? namespace + '--stacked' : '',
					namespace + '--' + component
				]"
				[ngStyle]="{
					'border-color': color,
					position: position
				}"
				(mouseenter)="mouseEnter.emit($event)"
				(mouseover)="mouseOver.emit($event)"
				(mouseout)="mouseOut.emit($event)"
				(mouseleave)="mouseLeave.emit($event)"
				(mousemove)="mouseMove.emit($event)"
				tabindex="0">
				<ng-container *ngTemplateOutlet="nodeTemplate"></ng-container>
			</xhtml:div>

			<xhtml:button
				*ngSwitchCase="'button'"
				[ngClass]="[
					namespace,
					stacked ? namespace + '--stacked' : '',
					namespace + '--' + component
				]"
				[ngStyle]="{
					'border-color': color,
					position: position
				}"
				(click)="click.emit($event)"
				(mouseenter)="mouseEnter.emit($event)"
				(mouseover)="mouseOver.emit($event)"
				(mouseout)="mouseOut.emit($event)"
				(mouseleave)="mouseLeave.emit($event)"
				(mousemove)="mouseMove.emit($event)"
				tabindex="0">
				<ng-container *ngTemplateOutlet="nodeTemplate"></ng-container>
			</xhtml:button>

			<xhtml:a
				*ngSwitchCase="'a'"
				[ngClass]="[
					namespace,
					stacked ? namespace + '--stacked' : '',
					namespace + '--' + component
				]"
				[attr.href]="href"
				[ngStyle]="{ 'border-color': color, position: position }"
				(mouseenter)="mouseEnter.emit($event)"
				(mouseover)="mouseOver.emit($event)"
				(mouseout)="mouseOut.emit($event)"
				(mouseleave)="mouseLeave.emit($event)"
				(mousemove)="mouseMove.emit($event)"
				tabindex="0">
				<ng-container *ngTemplateOutlet="nodeTemplate"></ng-container>
			</xhtml:a>
		</ng-container>
		<ng-template #nodeTemplate>
			<ng-content></ng-content>
		</ng-template>
	`
    }]
  }], null, {
    as: [{
      type: Input
    }],
    href: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    stacked: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    click: [{
      type: Output
    }],
    mouseEnter: [{
      type: Output
    }],
    mouseOver: [{
      type: Output
    }],
    mouseOut: [{
      type: Output
    }],
    mouseLeave: [{
      type: Output
    }],
    mouseMove: [{
      type: Output
    }]
  });
})();
class CardNodeColumnComponent {
  constructor() {
    this.farsideColumn = false;
  }
  get class() {
    const farsideClassName = this.farsideColumn ? `${carbonPrefix}--cc--card-node__column--farside` : '';
    return `${carbonPrefix}--cc--card-node__column ${farsideClassName}`;
  }
  static {
    this.ɵfac = function CardNodeColumnComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardNodeColumnComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CardNodeColumnComponent,
      selectors: [["ibm-diagram-card-node-column"]],
      hostVars: 2,
      hostBindings: function CardNodeColumnComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.class);
        }
      },
      inputs: {
        farsideColumn: "farsideColumn"
      },
      ngContentSelectors: _c3,
      decls: 2,
      vars: 0,
      template: function CardNodeColumnComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardNodeColumnComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-diagram-card-node-column',
      template: `
		<xhtml:div>
			<ng-content></ng-content>
		</xhtml:div>
	`
    }]
  }], null, {
    farsideColumn: [{
      type: Input
    }],
    class: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CardNodeLabelComponent {
  constructor() {
    this.namespace = `${carbonPrefix}--cc--card-node__label`;
  }
  static {
    this.ɵfac = function CardNodeLabelComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardNodeLabelComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CardNodeLabelComponent,
      selectors: [["ibm-diagram-card-node-label"]],
      ngContentSelectors: _c3,
      decls: 2,
      vars: 1,
      consts: [[3, "ngClass"]],
      template: function CardNodeLabelComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "label", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
        }
      },
      dependencies: [i1.NgClass],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardNodeLabelComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-diagram-card-node-label',
      template: `
		<xhtml:label [ngClass]="namespace">
			<ng-content></ng-content>
		</xhtml:label>
	`
    }]
  }], null, null);
})();
class CardNodeSubtitleComponent {
  constructor() {
    this.namespace = `${carbonPrefix}--cc--card-node__subtitle`;
  }
  static {
    this.ɵfac = function CardNodeSubtitleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardNodeSubtitleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CardNodeSubtitleComponent,
      selectors: [["ibm-diagram-card-node-subtitle"]],
      ngContentSelectors: _c3,
      decls: 2,
      vars: 1,
      consts: [[3, "ngClass"]],
      template: function CardNodeSubtitleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
        }
      },
      dependencies: [i1.NgClass],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardNodeSubtitleComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-diagram-card-node-subtitle',
      template: `
		<xhtml:div [ngClass]="namespace">
			<ng-content></ng-content>
		</xhtml:div>
	`
    }]
  }], null, null);
})();
class CardNodeTitleComponent {
  constructor() {
    this.namespace = `${carbonPrefix}--cc--card-node__title`;
  }
  static {
    this.ɵfac = function CardNodeTitleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardNodeTitleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CardNodeTitleComponent,
      selectors: [["ibm-diagram-card-node-title"]],
      ngContentSelectors: _c3,
      decls: 2,
      vars: 1,
      consts: [[3, "ngClass"]],
      template: function CardNodeTitleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.namespace);
        }
      },
      dependencies: [i1.NgClass],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardNodeTitleComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-diagram-card-node-title',
      template: `
		<xhtml:div [ngClass]="namespace">
			<ng-content></ng-content>
		</xhtml:div>
	`
    }]
  }], null, null);
})();
class CardNodeModule {
  static {
    this.ɵfac = function CardNodeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CardNodeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CardNodeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CardNodeModule, [{
    type: NgModule,
    args: [{
      declarations: [CardNodeComponent, CardNodeColumnComponent, CardNodeLabelComponent, CardNodeSubtitleComponent, CardNodeTitleComponent],
      imports: [CommonModule],
      exports: [CardNodeComponent, CardNodeColumnComponent, CardNodeLabelComponent, CardNodeSubtitleComponent, CardNodeTitleComponent]
    }]
  }], null, null);
})();
class ShapeNodeComponent {
  constructor() {
    this.as = 'div';
    this.href = '';
    this.size = 48;
    this.stacked = false;
    this.shape = 'circle';
    this.subtitle = '';
    this.title = '';
    this.position = 'fixed';
    this.bodyPosition = 'absolute';
    this.click = new EventEmitter();
    this.mouseEnter = new EventEmitter();
    this.mouseOver = new EventEmitter();
    this.mouseOut = new EventEmitter();
    this.mouseLeave = new EventEmitter();
    this.mouseMove = new EventEmitter();
    this.namespace = `${carbonPrefix}--cc--shape-node`;
    this.component = 'div';
  }
  ngOnInit() {
    if (this.href) {
      this.component = 'a';
    } else {
      this.component = this.as;
    }
  }
  static {
    this.ɵfac = function ShapeNodeComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ShapeNodeComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ShapeNodeComponent,
      selectors: [["ibm-diagram-shape-node"]],
      inputs: {
        as: "as",
        href: "href",
        renderIcon: "renderIcon",
        size: "size",
        stacked: "stacked",
        shape: "shape",
        subtitle: "subtitle",
        title: "title",
        position: "position",
        bodyPosition: "bodyPosition"
      },
      outputs: {
        click: "click",
        mouseEnter: "mouseEnter",
        mouseOver: "mouseOver",
        mouseOut: "mouseOut",
        mouseLeave: "mouseLeave",
        mouseMove: "mouseMove"
      },
      decls: 6,
      vars: 4,
      consts: [["nodeTemplate", ""], [3, "ngSwitch"], ["tabindex", "0", 3, "ngClass", "ngStyle", "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", 4, "ngSwitchCase"], ["tabindex", "0", 3, "ngClass", "ngStyle", "click", "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", 4, "ngSwitchCase"], ["tabindex", "0", 3, "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", "ngClass", "ngStyle"], [4, "ngTemplateOutlet"], ["tabindex", "0", 3, "click", "mouseenter", "mouseover", "mouseout", "mouseleave", "mousemove", "ngClass", "ngStyle"], [4, "ngIf"], [3, "ngStyle"]],
      template: function ShapeNodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainerStart(0, 1);
          i0.ɵɵtemplate(1, ShapeNodeComponent_div_1_Template, 2, 12, "div", 2)(2, ShapeNodeComponent_button_2_Template, 2, 12, "button", 3)(3, ShapeNodeComponent_a_3_Template, 2, 13, "a", 2);
          i0.ɵɵelementContainerEnd();
          i0.ɵɵtemplate(4, ShapeNodeComponent_ng_template_4_Template, 6, 9, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngSwitch", ctx.component);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "div");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "button");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "a");
        }
      },
      dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i1.NgSwitch, i1.NgSwitchCase],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShapeNodeComponent, [{
    type: Component,
    args: [{
      selector: 'ibm-diagram-shape-node',
      template: `
		<ng-container [ngSwitch]="component">
			<xhtml:div
				*ngSwitchCase="'div'"
				[ngClass]="[
					namespace,
					stacked ? namespace + '--stacked' : '',
					shape ? namespace + '--' + shape : '',
					namespace + '--' + component
				]"
				[ngStyle]="{
					'height.px': size,
					'width.px': size,
					position: position
				}"
				(mouseenter)="mouseEnter.emit($event)"
				(mouseover)="mouseOver.emit($event)"
				(mouseout)="mouseOut.emit($event)"
				(mouseleave)="mouseLeave.emit($event)"
				(mousemove)="mouseMove.emit($event)"
				tabindex="0">
				<ng-container *ngTemplateOutlet="nodeTemplate"></ng-container>
			</xhtml:div>

			<xhtml:button
				*ngSwitchCase="'button'"
				[ngClass]="[
					namespace,
					stacked ? namespace + '--stacked' : '',
					shape ? namespace + '--' + shape : '',
					namespace + '--' + component
				]"
				[ngStyle]="{
					'height.px': size,
					'width.px': size,
					position: position
				}"
				(click)="click.emit($event)"
				(mouseenter)="mouseEnter.emit($event)"
				(mouseover)="mouseOver.emit($event)"
				(mouseout)="mouseOut.emit($event)"
				(mouseleave)="mouseLeave.emit($event)"
				(mousemove)="mouseMove.emit($event)"
				tabindex="0">
				<ng-container *ngTemplateOutlet="nodeTemplate"></ng-container>
			</xhtml:button>

			<xhtml:a
				*ngSwitchCase="'a'"
				[ngClass]="[
					namespace,
					stacked ? namespace + '--stacked' : '',
					shape ? namespace + '--' + shape : '',
					namespace + '--' + component
				]"
				[attr.href]="href"
				[ngStyle]="{
					'height.px': size,
					'width.px': size,
					position: position
				}"
				(mouseenter)="mouseEnter.emit($event)"
				(mouseover)="mouseOver.emit($event)"
				(mouseout)="mouseOut.emit($event)"
				(mouseleave)="mouseLeave.emit($event)"
				(mousemove)="mouseMove.emit($event)"
				tabindex="0">
				<ng-container *ngTemplateOutlet="nodeTemplate"></ng-container>
			</xhtml:a>
		</ng-container>

		<ng-template #nodeTemplate>
			<div *ngIf="renderIcon" attr.class="{{ namespace + '__icon' }}">
				<ng-container *ngTemplateOutlet="renderIcon"></ng-container>
			</div>
			<div attr.class="{{ namespace + '__body' }}" [ngStyle]="{ position: bodyPosition }">
				<div attr.class="{{ namespace + '__title' }}">{{ title }}</div>
				<div attr.class="{{ namespace + '__subtitle' }}">
					{{ subtitle }}
				</div>
			</div>
		</ng-template>
	`
    }]
  }], null, {
    as: [{
      type: Input
    }],
    href: [{
      type: Input
    }],
    renderIcon: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    stacked: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    subtitle: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    bodyPosition: [{
      type: Input
    }],
    click: [{
      type: Output
    }],
    mouseEnter: [{
      type: Output
    }],
    mouseOver: [{
      type: Output
    }],
    mouseOut: [{
      type: Output
    }],
    mouseLeave: [{
      type: Output
    }],
    mouseMove: [{
      type: Output
    }]
  });
})();
class ShapeNodeModule {
  static {
    this.ɵfac = function ShapeNodeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ShapeNodeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ShapeNodeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShapeNodeModule, [{
    type: NgModule,
    args: [{
      declarations: [ShapeNodeComponent],
      imports: [CommonModule],
      exports: [ShapeNodeComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of charts-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AlluvialChartComponent, AreaChartComponent, BaseChartComponent, BoxplotChartComponent, BubbleChartComponent, BulletChartComponent, CardNodeColumnComponent, CardNodeComponent, CardNodeLabelComponent, CardNodeModule, CardNodeSubtitleComponent, CardNodeTitleComponent, ChartsModule, ChoroplethChartComponent, CirclePackChartComponent, ComboChartComponent, DonutChartComponent, EdgeComponent, EdgeModule, ExperimentalChoroplethChartComponent, GaugeChartComponent, GroupedBarChartComponent, HeatmapChartComponent, HistogramChartComponent, LineChartComponent, LollipopChartComponent, MarkerArrowLeftComponent, MarkerArrowRightComponent, MarkerComponent, MarkerDiamondComponent, MarkerModule, MarkerShapeNodeComponent, MarkerSquareComponent, MarkerTeeComponent, MeterChartComponent, PieChartComponent, RadarChartComponent, ScatterChartComponent, ShapeNodeComponent, ShapeNodeModule, SimpleBarChartComponent, StackedAreaChartComponent, StackedBarChartComponent, TreeChartComponent, TreemapChartComponent, WordCloudChartComponent };
