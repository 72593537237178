import { a3 as Bt, aC as Lt, aD as Nt, aE as Ct, aF as ft, aG as It, aH as Zt, aI as Ft, a2 as zt, aJ as Vt, aK as Yt, aL as Ht, aM as Xt, _ as Gt, W as $t, ax as j, aN as Ut, aO as Kt, n as W, g as c, F as J, aP as x, aQ as P, aR as qt, aS as mt, aT as dt, h as Q, ar as jt, ao as Wt, ad as pt, ai as Jt, H as Qt } from "./color-scale-utils-f3PWy_Op.mjs";
import { z as ti, A as O, g as tt, S as A, D as ii, s as ni, v as ei, x as si, u as oi, y as ai } from "./angle-utils-CGNI01fr.mjs";
import { $ as E, e as _, i as N, S as m } from "./axis-scales-CPuwbFQf.mjs";
function xt(i) {
  var t = i == null ? 0 : i.length;
  return t ? Bt(i, 1) : [];
}
function ri(i) {
  return Lt(Nt(i, void 0, xt), i + "");
}
function hi(i, t, n) {
  var e = -1,
    s = i.length;
  t < 0 && (t = -t > s ? 0 : s + t), n = n > s ? s : n, n < 0 && (n += s), s = t > n ? 0 : n - t >>> 0, t >>>= 0;
  for (var o = Array(s); ++e < s;) o[e] = i[e + t];
  return o;
}
function ci(i, t) {
  return t.length < 2 ? i : Ct(i, hi(t, 0, -1));
}
function li(i, t) {
  return t = ft(t, i), i = ci(i, t), i == null || delete i[It(Zt(t))];
}
function _i(i) {
  return Ft(i) ? void 0 : i;
}
var ui = 1,
  fi = 2,
  mi = 4,
  di = ri(function (i, t) {
    var n = {};
    if (i == null) return n;
    var e = !1;
    t = zt(t, function (o) {
      return o = ft(o, i), e || (e = o.length > 1), o;
    }), Vt(i, Yt(i), n), e && (n = Ht(n, ui | fi | mi, _i));
    for (var s = t.length; s--;) li(n, t[s]);
    return n;
  });
function it(i) {
  return Math.log(i);
}
function nt(i) {
  return Math.exp(i);
}
function pi(i) {
  return -Math.log(-i);
}
function xi(i) {
  return -Math.exp(-i);
}
function gi(i) {
  return isFinite(i) ? +("1e" + i) : i < 0 ? 0 : i;
}
function yi(i) {
  return i === 10 ? gi : i === Math.E ? Math.exp : t => Math.pow(i, t);
}
function vi(i) {
  return i === Math.E ? Math.log : i === 10 && Math.log10 || i === 2 && Math.log2 || (i = Math.log(i), t => Math.log(t) / i);
}
function et(i) {
  return (t, n) => -i(-t, n);
}
function Ti(i) {
  const t = i(it, nt),
    n = t.domain;
  let e = 10,
    s,
    o;
  function a() {
    return s = vi(e), o = yi(e), n()[0] < 0 ? (s = et(s), o = et(o), i(pi, xi)) : i(it, nt), t;
  }
  return t.base = function (r) {
    return arguments.length ? (e = +r, a()) : e;
  }, t.domain = function (r) {
    return arguments.length ? (n(r), a()) : n();
  }, t.ticks = r => {
    const h = n();
    let l = h[0],
      f = h[h.length - 1];
    const v = f < l;
    v && ([l, f] = [f, l]);
    let p = s(l),
      T = s(f),
      y,
      d;
    const D = r == null ? 10 : +r;
    let u = [];
    if (!(e % 1) && T - p < D) {
      if (p = Math.floor(p), T = Math.ceil(T), l > 0) {
        for (; p <= T; ++p) for (y = 1; y < e; ++y) if (d = p < 0 ? y / o(-p) : y * o(p), !(d < l)) {
          if (d > f) break;
          u.push(d);
        }
      } else for (; p <= T; ++p) for (y = e - 1; y >= 1; --y) if (d = p > 0 ? y / o(-p) : y * o(p), !(d < l)) {
        if (d > f) break;
        u.push(d);
      }
      u.length * 2 < D && (u = j(l, f, D));
    } else u = j(p, T, Math.min(T - p, D)).map(o);
    return v ? u.reverse() : u;
  }, t.tickFormat = (r, h) => {
    if (r == null && (r = 10), h == null && (h = e === 10 ? "s" : ","), typeof h != "function" && (!(e % 1) && (h = Ut(h)).precision == null && (h.trim = !0), h = Kt(h)), r === 1 / 0) return h;
    const l = Math.max(1, e * r / t.ticks().length);
    return f => {
      let v = f / o(Math.round(s(f)));
      return v * e < e - 0.5 && (v *= e), v <= l ? h(f) : "";
    };
  }, t.nice = () => n(ti(n(), {
    floor: r => o(Math.floor(s(r))),
    ceil: r => o(Math.ceil(s(r)))
  })), t;
}
function gt() {
  const i = Ti(Xt()).domain([1, 10]);
  return i.copy = () => Gt(i, gt()).base(i.base()), $t.apply(i, arguments), i;
}
function w(i, t, n) {
  i._context.bezierCurveTo((2 * i._x0 + i._x1) / 3, (2 * i._y0 + i._y1) / 3, (i._x0 + 2 * i._x1) / 3, (i._y0 + 2 * i._y1) / 3, (i._x0 + 4 * i._x1 + t) / 6, (i._y0 + 4 * i._y1 + n) / 6);
}
function B(i) {
  this._context = i;
}
B.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x0 = this._x1 = this._y0 = this._y1 = NaN, this._point = 0;
  },
  lineEnd: function () {
    switch (this._point) {
      case 3:
        w(this, this._x1, this._y1);
      case 2:
        this._context.lineTo(this._x1, this._y1);
        break;
    }
    (this._line || this._line !== 0 && this._point === 1) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (i, t) {
    switch (i = +i, t = +t, this._point) {
      case 0:
        this._point = 1, this._line ? this._context.lineTo(i, t) : this._context.moveTo(i, t);
        break;
      case 1:
        this._point = 2;
        break;
      case 2:
        this._point = 3, this._context.lineTo((5 * this._x0 + this._x1) / 6, (5 * this._y0 + this._y1) / 6);
      default:
        w(this, i, t);
        break;
    }
    this._x0 = this._x1, this._x1 = i, this._y0 = this._y1, this._y1 = t;
  }
};
function Di(i) {
  return new B(i);
}
function yt(i) {
  this._context = i;
}
yt.prototype = {
  areaStart: O,
  areaEnd: O,
  lineStart: function () {
    this._x0 = this._x1 = this._x2 = this._x3 = this._x4 = this._y0 = this._y1 = this._y2 = this._y3 = this._y4 = NaN, this._point = 0;
  },
  lineEnd: function () {
    switch (this._point) {
      case 1:
        {
          this._context.moveTo(this._x2, this._y2), this._context.closePath();
          break;
        }
      case 2:
        {
          this._context.moveTo((this._x2 + 2 * this._x3) / 3, (this._y2 + 2 * this._y3) / 3), this._context.lineTo((this._x3 + 2 * this._x2) / 3, (this._y3 + 2 * this._y2) / 3), this._context.closePath();
          break;
        }
      case 3:
        {
          this.point(this._x2, this._y2), this.point(this._x3, this._y3), this.point(this._x4, this._y4);
          break;
        }
    }
  },
  point: function (i, t) {
    switch (i = +i, t = +t, this._point) {
      case 0:
        this._point = 1, this._x2 = i, this._y2 = t;
        break;
      case 1:
        this._point = 2, this._x3 = i, this._y3 = t;
        break;
      case 2:
        this._point = 3, this._x4 = i, this._y4 = t, this._context.moveTo((this._x0 + 4 * this._x1 + i) / 6, (this._y0 + 4 * this._y1 + t) / 6);
        break;
      default:
        w(this, i, t);
        break;
    }
    this._x0 = this._x1, this._x1 = i, this._y0 = this._y1, this._y1 = t;
  }
};
function Ai(i) {
  return new yt(i);
}
function vt(i) {
  this._context = i;
}
vt.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x0 = this._x1 = this._y0 = this._y1 = NaN, this._point = 0;
  },
  lineEnd: function () {
    (this._line || this._line !== 0 && this._point === 3) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (i, t) {
    switch (i = +i, t = +t, this._point) {
      case 0:
        this._point = 1;
        break;
      case 1:
        this._point = 2;
        break;
      case 2:
        this._point = 3;
        var n = (this._x0 + 4 * this._x1 + i) / 6,
          e = (this._y0 + 4 * this._y1 + t) / 6;
        this._line ? this._context.lineTo(n, e) : this._context.moveTo(n, e);
        break;
      case 3:
        this._point = 4;
      default:
        w(this, i, t);
        break;
    }
    this._x0 = this._x1, this._x1 = i, this._y0 = this._y1, this._y1 = t;
  }
};
function Oi(i) {
  return new vt(i);
}
function Tt(i, t) {
  this._basis = new B(i), this._beta = t;
}
Tt.prototype = {
  lineStart: function () {
    this._x = [], this._y = [], this._basis.lineStart();
  },
  lineEnd: function () {
    var i = this._x,
      t = this._y,
      n = i.length - 1;
    if (n > 0) for (var e = i[0], s = t[0], o = i[n] - e, a = t[n] - s, r = -1, h; ++r <= n;) h = r / n, this._basis.point(this._beta * i[r] + (1 - this._beta) * (e + h * o), this._beta * t[r] + (1 - this._beta) * (s + h * a));
    this._x = this._y = null, this._basis.lineEnd();
  },
  point: function (i, t) {
    this._x.push(+i), this._y.push(+t);
  }
};
const Mi = function i(t) {
  function n(e) {
    return t === 1 ? new B(e) : new Tt(e, t);
  }
  return n.beta = function (e) {
    return i(+e);
  }, n;
}(0.85);
function k(i, t, n) {
  i._context.bezierCurveTo(i._x1 + i._k * (i._x2 - i._x0), i._y1 + i._k * (i._y2 - i._y0), i._x2 + i._k * (i._x1 - t), i._y2 + i._k * (i._y1 - n), i._x2, i._y2);
}
function F(i, t) {
  this._context = i, this._k = (1 - t) / 6;
}
F.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x0 = this._x1 = this._x2 = this._y0 = this._y1 = this._y2 = NaN, this._point = 0;
  },
  lineEnd: function () {
    switch (this._point) {
      case 2:
        this._context.lineTo(this._x2, this._y2);
        break;
      case 3:
        k(this, this._x1, this._y1);
        break;
    }
    (this._line || this._line !== 0 && this._point === 1) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (i, t) {
    switch (i = +i, t = +t, this._point) {
      case 0:
        this._point = 1, this._line ? this._context.lineTo(i, t) : this._context.moveTo(i, t);
        break;
      case 1:
        this._point = 2, this._x1 = i, this._y1 = t;
        break;
      case 2:
        this._point = 3;
      default:
        k(this, i, t);
        break;
    }
    this._x0 = this._x1, this._x1 = this._x2, this._x2 = i, this._y0 = this._y1, this._y1 = this._y2, this._y2 = t;
  }
};
const bi = function i(t) {
  function n(e) {
    return new F(e, t);
  }
  return n.tension = function (e) {
    return i(+e);
  }, n;
}(0);
function z(i, t) {
  this._context = i, this._k = (1 - t) / 6;
}
z.prototype = {
  areaStart: O,
  areaEnd: O,
  lineStart: function () {
    this._x0 = this._x1 = this._x2 = this._x3 = this._x4 = this._x5 = this._y0 = this._y1 = this._y2 = this._y3 = this._y4 = this._y5 = NaN, this._point = 0;
  },
  lineEnd: function () {
    switch (this._point) {
      case 1:
        {
          this._context.moveTo(this._x3, this._y3), this._context.closePath();
          break;
        }
      case 2:
        {
          this._context.lineTo(this._x3, this._y3), this._context.closePath();
          break;
        }
      case 3:
        {
          this.point(this._x3, this._y3), this.point(this._x4, this._y4), this.point(this._x5, this._y5);
          break;
        }
    }
  },
  point: function (i, t) {
    switch (i = +i, t = +t, this._point) {
      case 0:
        this._point = 1, this._x3 = i, this._y3 = t;
        break;
      case 1:
        this._point = 2, this._context.moveTo(this._x4 = i, this._y4 = t);
        break;
      case 2:
        this._point = 3, this._x5 = i, this._y5 = t;
        break;
      default:
        k(this, i, t);
        break;
    }
    this._x0 = this._x1, this._x1 = this._x2, this._x2 = i, this._y0 = this._y1, this._y1 = this._y2, this._y2 = t;
  }
};
const Si = function i(t) {
  function n(e) {
    return new z(e, t);
  }
  return n.tension = function (e) {
    return i(+e);
  }, n;
}(0);
function V(i, t) {
  this._context = i, this._k = (1 - t) / 6;
}
V.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x0 = this._x1 = this._x2 = this._y0 = this._y1 = this._y2 = NaN, this._point = 0;
  },
  lineEnd: function () {
    (this._line || this._line !== 0 && this._point === 3) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (i, t) {
    switch (i = +i, t = +t, this._point) {
      case 0:
        this._point = 1;
        break;
      case 1:
        this._point = 2;
        break;
      case 2:
        this._point = 3, this._line ? this._context.lineTo(this._x2, this._y2) : this._context.moveTo(this._x2, this._y2);
        break;
      case 3:
        this._point = 4;
      default:
        k(this, i, t);
        break;
    }
    this._x0 = this._x1, this._x1 = this._x2, this._x2 = i, this._y0 = this._y1, this._y1 = this._y2, this._y2 = t;
  }
};
const Pi = function i(t) {
  function n(e) {
    return new V(e, t);
  }
  return n.tension = function (e) {
    return i(+e);
  }, n;
}(0);
function Y(i, t, n) {
  var e = i._x1,
    s = i._y1,
    o = i._x2,
    a = i._y2;
  if (i._l01_a > tt) {
    var r = 2 * i._l01_2a + 3 * i._l01_a * i._l12_a + i._l12_2a,
      h = 3 * i._l01_a * (i._l01_a + i._l12_a);
    e = (e * r - i._x0 * i._l12_2a + i._x2 * i._l01_2a) / h, s = (s * r - i._y0 * i._l12_2a + i._y2 * i._l01_2a) / h;
  }
  if (i._l23_a > tt) {
    var l = 2 * i._l23_2a + 3 * i._l23_a * i._l12_a + i._l12_2a,
      f = 3 * i._l23_a * (i._l23_a + i._l12_a);
    o = (o * l + i._x1 * i._l23_2a - t * i._l12_2a) / f, a = (a * l + i._y1 * i._l23_2a - n * i._l12_2a) / f;
  }
  i._context.bezierCurveTo(e, s, o, a, i._x2, i._y2);
}
function Dt(i, t) {
  this._context = i, this._alpha = t;
}
Dt.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x0 = this._x1 = this._x2 = this._y0 = this._y1 = this._y2 = NaN, this._l01_a = this._l12_a = this._l23_a = this._l01_2a = this._l12_2a = this._l23_2a = this._point = 0;
  },
  lineEnd: function () {
    switch (this._point) {
      case 2:
        this._context.lineTo(this._x2, this._y2);
        break;
      case 3:
        this.point(this._x2, this._y2);
        break;
    }
    (this._line || this._line !== 0 && this._point === 1) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (i, t) {
    if (i = +i, t = +t, this._point) {
      var n = this._x2 - i,
        e = this._y2 - t;
      this._l23_a = Math.sqrt(this._l23_2a = Math.pow(n * n + e * e, this._alpha));
    }
    switch (this._point) {
      case 0:
        this._point = 1, this._line ? this._context.lineTo(i, t) : this._context.moveTo(i, t);
        break;
      case 1:
        this._point = 2;
        break;
      case 2:
        this._point = 3;
      default:
        Y(this, i, t);
        break;
    }
    this._l01_a = this._l12_a, this._l12_a = this._l23_a, this._l01_2a = this._l12_2a, this._l12_2a = this._l23_2a, this._x0 = this._x1, this._x1 = this._x2, this._x2 = i, this._y0 = this._y1, this._y1 = this._y2, this._y2 = t;
  }
};
const Ei = function i(t) {
  function n(e) {
    return t ? new Dt(e, t) : new F(e, 0);
  }
  return n.alpha = function (e) {
    return i(+e);
  }, n;
}(0.5);
function At(i, t) {
  this._context = i, this._alpha = t;
}
At.prototype = {
  areaStart: O,
  areaEnd: O,
  lineStart: function () {
    this._x0 = this._x1 = this._x2 = this._x3 = this._x4 = this._x5 = this._y0 = this._y1 = this._y2 = this._y3 = this._y4 = this._y5 = NaN, this._l01_a = this._l12_a = this._l23_a = this._l01_2a = this._l12_2a = this._l23_2a = this._point = 0;
  },
  lineEnd: function () {
    switch (this._point) {
      case 1:
        {
          this._context.moveTo(this._x3, this._y3), this._context.closePath();
          break;
        }
      case 2:
        {
          this._context.lineTo(this._x3, this._y3), this._context.closePath();
          break;
        }
      case 3:
        {
          this.point(this._x3, this._y3), this.point(this._x4, this._y4), this.point(this._x5, this._y5);
          break;
        }
    }
  },
  point: function (i, t) {
    if (i = +i, t = +t, this._point) {
      var n = this._x2 - i,
        e = this._y2 - t;
      this._l23_a = Math.sqrt(this._l23_2a = Math.pow(n * n + e * e, this._alpha));
    }
    switch (this._point) {
      case 0:
        this._point = 1, this._x3 = i, this._y3 = t;
        break;
      case 1:
        this._point = 2, this._context.moveTo(this._x4 = i, this._y4 = t);
        break;
      case 2:
        this._point = 3, this._x5 = i, this._y5 = t;
        break;
      default:
        Y(this, i, t);
        break;
    }
    this._l01_a = this._l12_a, this._l12_a = this._l23_a, this._l01_2a = this._l12_2a, this._l12_2a = this._l23_2a, this._x0 = this._x1, this._x1 = this._x2, this._x2 = i, this._y0 = this._y1, this._y1 = this._y2, this._y2 = t;
  }
};
const wi = function i(t) {
  function n(e) {
    return t ? new At(e, t) : new z(e, 0);
  }
  return n.alpha = function (e) {
    return i(+e);
  }, n;
}(0.5);
function Ot(i, t) {
  this._context = i, this._alpha = t;
}
Ot.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x0 = this._x1 = this._x2 = this._y0 = this._y1 = this._y2 = NaN, this._l01_a = this._l12_a = this._l23_a = this._l01_2a = this._l12_2a = this._l23_2a = this._point = 0;
  },
  lineEnd: function () {
    (this._line || this._line !== 0 && this._point === 3) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (i, t) {
    if (i = +i, t = +t, this._point) {
      var n = this._x2 - i,
        e = this._y2 - t;
      this._l23_a = Math.sqrt(this._l23_2a = Math.pow(n * n + e * e, this._alpha));
    }
    switch (this._point) {
      case 0:
        this._point = 1;
        break;
      case 1:
        this._point = 2;
        break;
      case 2:
        this._point = 3, this._line ? this._context.lineTo(this._x2, this._y2) : this._context.moveTo(this._x2, this._y2);
        break;
      case 3:
        this._point = 4;
      default:
        Y(this, i, t);
        break;
    }
    this._l01_a = this._l12_a, this._l12_a = this._l23_a, this._l01_2a = this._l12_2a, this._l12_2a = this._l23_2a, this._x0 = this._x1, this._x1 = this._x2, this._x2 = i, this._y0 = this._y1, this._y1 = this._y2, this._y2 = t;
  }
};
const ki = function i(t) {
  function n(e) {
    return t ? new Ot(e, t) : new V(e, 0);
  }
  return n.alpha = function (e) {
    return i(+e);
  }, n;
}(0.5);
function st(i) {
  return i < 0 ? -1 : 1;
}
function ot(i, t, n) {
  var e = i._x1 - i._x0,
    s = t - i._x1,
    o = (i._y1 - i._y0) / (e || s < 0 && -0),
    a = (n - i._y1) / (s || e < 0 && -0),
    r = (o * s + a * e) / (e + s);
  return (st(o) + st(a)) * Math.min(Math.abs(o), Math.abs(a), 0.5 * Math.abs(r)) || 0;
}
function at(i, t) {
  var n = i._x1 - i._x0;
  return n ? (3 * (i._y1 - i._y0) / n - t) / 2 : t;
}
function C(i, t, n) {
  var e = i._x0,
    s = i._y0,
    o = i._x1,
    a = i._y1,
    r = (o - e) / 3;
  i._context.bezierCurveTo(e + r, s + r * t, o - r, a - r * n, o, a);
}
function R(i) {
  this._context = i;
}
R.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x0 = this._x1 = this._y0 = this._y1 = this._t0 = NaN, this._point = 0;
  },
  lineEnd: function () {
    switch (this._point) {
      case 2:
        this._context.lineTo(this._x1, this._y1);
        break;
      case 3:
        C(this, this._t0, at(this, this._t0));
        break;
    }
    (this._line || this._line !== 0 && this._point === 1) && this._context.closePath(), this._line = 1 - this._line;
  },
  point: function (i, t) {
    var n = NaN;
    if (i = +i, t = +t, !(i === this._x1 && t === this._y1)) {
      switch (this._point) {
        case 0:
          this._point = 1, this._line ? this._context.lineTo(i, t) : this._context.moveTo(i, t);
          break;
        case 1:
          this._point = 2;
          break;
        case 2:
          this._point = 3, C(this, at(this, n = ot(this, i, t)), n);
          break;
        default:
          C(this, this._t0, n = ot(this, i, t));
          break;
      }
      this._x0 = this._x1, this._x1 = i, this._y0 = this._y1, this._y1 = t, this._t0 = n;
    }
  }
};
function Mt(i) {
  this._context = new bt(i);
}
(Mt.prototype = Object.create(R.prototype)).point = function (i, t) {
  R.prototype.point.call(this, t, i);
};
function bt(i) {
  this._context = i;
}
bt.prototype = {
  moveTo: function (i, t) {
    this._context.moveTo(t, i);
  },
  closePath: function () {
    this._context.closePath();
  },
  lineTo: function (i, t) {
    this._context.lineTo(t, i);
  },
  bezierCurveTo: function (i, t, n, e, s, o) {
    this._context.bezierCurveTo(t, i, e, n, o, s);
  }
};
function Ri(i) {
  return new R(i);
}
function Bi(i) {
  return new Mt(i);
}
function St(i) {
  this._context = i;
}
St.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x = [], this._y = [];
  },
  lineEnd: function () {
    var i = this._x,
      t = this._y,
      n = i.length;
    if (n) if (this._line ? this._context.lineTo(i[0], t[0]) : this._context.moveTo(i[0], t[0]), n === 2) this._context.lineTo(i[1], t[1]);else for (var e = rt(i), s = rt(t), o = 0, a = 1; a < n; ++o, ++a) this._context.bezierCurveTo(e[0][o], s[0][o], e[1][o], s[1][o], i[a], t[a]);
    (this._line || this._line !== 0 && n === 1) && this._context.closePath(), this._line = 1 - this._line, this._x = this._y = null;
  },
  point: function (i, t) {
    this._x.push(+i), this._y.push(+t);
  }
};
function rt(i) {
  var t,
    n = i.length - 1,
    e,
    s = new Array(n),
    o = new Array(n),
    a = new Array(n);
  for (s[0] = 0, o[0] = 2, a[0] = i[0] + 2 * i[1], t = 1; t < n - 1; ++t) s[t] = 1, o[t] = 4, a[t] = 4 * i[t] + 2 * i[t + 1];
  for (s[n - 1] = 2, o[n - 1] = 7, a[n - 1] = 8 * i[n - 1] + i[n], t = 1; t < n; ++t) e = s[t] / o[t - 1], o[t] -= e, a[t] -= e * a[t - 1];
  for (s[n - 1] = a[n - 1] / o[n - 1], t = n - 2; t >= 0; --t) s[t] = (a[t] - s[t + 1]) / o[t];
  for (o[n - 1] = (i[n] + s[n - 1]) / 2, t = 0; t < n - 1; ++t) o[t] = 2 * i[t + 1] - s[t + 1];
  return [s, o];
}
function Li(i) {
  return new St(i);
}
function L(i, t) {
  this._context = i, this._t = t;
}
L.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x = this._y = NaN, this._point = 0;
  },
  lineEnd: function () {
    0 < this._t && this._t < 1 && this._point === 2 && this._context.lineTo(this._x, this._y), (this._line || this._line !== 0 && this._point === 1) && this._context.closePath(), this._line >= 0 && (this._t = 1 - this._t, this._line = 1 - this._line);
  },
  point: function (i, t) {
    switch (i = +i, t = +t, this._point) {
      case 0:
        this._point = 1, this._line ? this._context.lineTo(i, t) : this._context.moveTo(i, t);
        break;
      case 1:
        this._point = 2;
      default:
        {
          if (this._t <= 0) this._context.lineTo(this._x, t), this._context.lineTo(i, t);else {
            var n = this._x * (1 - this._t) + i * this._t;
            this._context.lineTo(n, this._y), this._context.lineTo(n, t);
          }
          break;
        }
    }
    this._x = i, this._y = t;
  }
};
function Ni(i) {
  return new L(i, 0.5);
}
function Ci(i) {
  return new L(i, 0);
}
function Ii(i) {
  return new L(i, 1);
}
class en extends A {
  /**
   * focal:  object to zoom into
   * canvasElements: all the elements to translate and zoom on the chart area
   * zoomSettings: object containing duration, easing and zoomlevel for the zoom behaviours
   *  */
  zoomIn(t, n, e) {
    let s, o, a;
    const r = e || W;
    t && (s = t.x, o = t.y, a = 2);
    const {
      width: h,
      height: l
    } = ii.getSVGElementSize(this.services.domUtils.getHolder(), {
      useClientDimensions: !0
    });
    n.transition().duration(r.duration).ease(r.ease).attr("transform", `translate(${h / 2}, ${l / 2}) scale(${a}) translate(${-s},${-o})`), this.services.events.dispatchEvent(E.CanvasZoom.CANVAS_ZOOM_IN, {
      element: ni(t)
    });
  }
  zoomOut(t, n) {
    const e = n || W;
    t.transition().duration(e.duration).ease(e.ease).attr("transform", ""), this.services.events.dispatchEvent(E.CanvasZoom.CANVAS_ZOOM_OUT);
  }
}
class sn extends A {
  init() {
    this.documentFragment = document.createDocumentFragment();
  }
  addEventListener(t, n) {
    this.documentFragment.addEventListener(t, n);
  }
  removeEventListener(t, n) {
    this.documentFragment.removeEventListener(t, n);
  }
  dispatchEvent(t, n) {
    let e;
    n ? e = new CustomEvent(t, {
      detail: n
    }) : (e = document.createEvent("Event"), e.initEvent(t, !1, !0)), this.documentFragment.dispatchEvent(e);
  }
}
class on extends A {
  constructor(t, n) {
    super(t, n);
  }
  downloadCSV(t, n) {
    const e = document.createElement("a"),
      s = "text/csv;encoding:utf-8";
    if (navigator.msSaveBlob) navigator.msSaveBlob(new Blob([t], {
      type: s
    }), n);else if (URL && "download" in e) {
      const o = URL.createObjectURL(new Blob([t], {
        type: s
      }));
      e.href = o, e.setAttribute("download", n), document.body.appendChild(e), e.click(), document.body.removeChild(e), URL.revokeObjectURL(o);
    } else location.href = `data:application/octet-stream,${encodeURIComponent(t)}`;
  }
  downloadImage(t, n) {
    const e = document.createElement("a");
    e.download = n, e.href = t, document.body.appendChild(e), e.click(), document.body.removeChild(e);
  }
}
class an extends A {
  constructor() {
    super(...arguments), this.pendingTransitions = {};
  }
  init() {
    var t;
    (t = this.services.events) == null || t.addEventListener(E.Model.UPDATE, () => {
      this.pendingTransitions = {};
    });
  }
  setupTransition({
    transition: t,
    name: n,
    animate: e
  }) {
    return this.pendingTransitions[t._id] = t, t.on("end interrupt cancel", () => {
      delete this.pendingTransitions[t._id];
    }), this.model.getOptions().animations === !1 || e === !1 ? t.duration(0) : t.duration(c(J, n, "duration") || J.default.duration);
  }
  getPendingTransitions() {
    return this.pendingTransitions;
  }
}
function b(i, t) {
  const n = x(i),
    e = x(t),
    s = n.getTime() - e.getTime();
  return s < 0 ? -1 : s > 0 ? 1 : s;
}
function Zi(i, t) {
  const n = x(i),
    e = x(t);
  return n.getFullYear() - e.getFullYear();
}
function Fi(i, t) {
  const n = x(i),
    e = x(t),
    s = b(n, e),
    o = Math.abs(Zi(n, e));
  n.setFullYear(1584), e.setFullYear(1584);
  const a = b(n, e) === -s,
    r = s * (o - +a);
  return r === 0 ? 0 : r;
}
function H(i, t) {
  const n = x(i);
  if (isNaN(t)) return P(i, NaN);
  if (!t) return n;
  const e = n.getDate(),
    s = P(i, n.getTime());
  s.setMonth(n.getMonth() + t + 1, 0);
  const o = s.getDate();
  return e >= o ? s : (n.setFullYear(s.getFullYear(), s.getMonth(), e), n);
}
function Pt(i, t) {
  return H(i, t * 12);
}
function zi(i, t) {
  return Pt(i, -t);
}
function Vi(i, t) {
  const n = x(i),
    e = x(t),
    s = n.getFullYear() - e.getFullYear(),
    o = n.getMonth() - e.getMonth();
  return s * 12 + o;
}
function Yi(i) {
  const t = x(i);
  return t.setHours(23, 59, 59, 999), t;
}
function Hi(i) {
  const t = x(i),
    n = t.getMonth();
  return t.setFullYear(t.getFullYear(), n + 1, 0), t.setHours(23, 59, 59, 999), t;
}
function Xi(i) {
  const t = x(i);
  return +Yi(t) == +Hi(t);
}
function Gi(i, t) {
  const n = x(i),
    e = x(t),
    s = b(n, e),
    o = Math.abs(Vi(n, e));
  let a;
  if (o < 1) a = 0;else {
    n.getMonth() === 1 && n.getDate() > 27 && n.setDate(30), n.setMonth(n.getMonth() - s * o);
    let r = b(n, e) === -s;
    Xi(x(i)) && o === 1 && b(i, e) === 1 && (r = !1), a = s * (o - Number(r));
  }
  return a === 0 ? 0 : a;
}
function $i(i, t) {
  return H(i, -t);
}
function Ui(i, t) {
  const n = x(i),
    e = x(t),
    s = ht(n, e),
    o = Math.abs(qt(n, e));
  n.setDate(n.getDate() - s * o);
  const a = +(ht(n, e) === -s),
    r = s * (o - a);
  return r === 0 ? 0 : r;
}
function ht(i, t) {
  const n = i.getFullYear() - t.getFullYear() || i.getMonth() - t.getMonth() || i.getDate() - t.getDate() || i.getHours() - t.getHours() || i.getMinutes() - t.getMinutes() || i.getSeconds() - t.getSeconds() || i.getMilliseconds() - t.getMilliseconds();
  return n < 0 ? -1 : n > 0 ? 1 : n;
}
function Et(i, t) {
  const n = x(i);
  return isNaN(t) ? P(i, NaN) : (t && n.setDate(n.getDate() + t), n);
}
function Ki(i, t) {
  return Et(i, -t);
}
function X(i) {
  return t => {
    const e = (i ? Math[i] : Math.trunc)(t);
    return e === 0 ? 0 : e;
  };
}
function G(i, t) {
  return +x(i) - +x(t);
}
function qi(i, t, n) {
  const e = G(i, t) / mt;
  return X(n == null ? void 0 : n.roundingMethod)(e);
}
function $(i, t) {
  const n = +x(i);
  return P(i, n + t);
}
function wt(i, t) {
  return $(i, t * mt);
}
function ji(i, t) {
  return wt(i, -t);
}
function ct(i, t, n) {
  const e = G(i, t) / dt;
  return X(n == null ? void 0 : n.roundingMethod)(e);
}
function I(i, t) {
  return $(i, t * dt);
}
function lt(i, t) {
  return I(i, -t);
}
function _t(i, t, n) {
  const e = G(i, t) / 1e3;
  return X(n == null ? void 0 : n.roundingMethod)(e);
}
function Z(i, t) {
  return $(i, t * 1e3);
}
function ut(i, t) {
  return Z(i, -t);
}
class rn extends A {
  constructor() {
    super(...arguments), this.scaleTypes = {
      top: null,
      right: null,
      bottom: null,
      left: null
    }, this.scales = {
      // null or function
      top: null,
      right: null,
      bottom: null,
      left: null
    };
  }
  getDomainAxisPosition({
    datum: t = null
  } = {}) {
    if (this.dualAxes && t) {
      const n = this.model.getOptions(),
        {
          groupMapsTo: e
        } = n.data,
        s = c(n, "axes", this.secondaryDomainAxisPosition),
        o = t[e];
      if (s != null && s.correspondingDatasets && s.correspondingDatasets.includes(o)) return this.secondaryDomainAxisPosition;
    }
    return this.domainAxisPosition;
  }
  getRangeAxisPosition({
    datum: t = null,
    groups: n = null
  } = {}) {
    if (this.dualAxes) {
      const e = this.model.getOptions(),
        {
          groupMapsTo: s
        } = e.data,
        o = c(e, "axes", this.secondaryRangeAxisPosition);
      let a;
      if (t !== null ? a = t[s] : n && n.length > 0 && (a = n[0]), o != null && o.correspondingDatasets && o.correspondingDatasets.includes(a)) return this.secondaryRangeAxisPosition;
    }
    return this.rangeAxisPosition;
  }
  getAxisOptions(t) {
    return c(this.model.getOptions(), "axes", t);
  }
  getDomainAxisOptions() {
    const t = this.getDomainAxisPosition();
    return this.getAxisOptions(t);
  }
  getRangeAxisOptions() {
    const t = this.getRangeAxisPosition();
    return this.getAxisOptions(t);
  }
  getScaleLabel(t) {
    const e = this.getAxisOptions(t).title;
    return e || (t === _.BOTTOM || t === _.TOP ? "x-value" : "y-value");
  }
  getDomainLabel() {
    return this.getScaleLabel(this.getDomainAxisPosition());
  }
  getRangeLabel() {
    return this.getScaleLabel(this.getRangeAxisPosition());
  }
  update() {
    this.determineAxisDuality(), this.findDomainAndRangeAxes(), this.determineOrientation(), Object.keys(_).map(n => _[n]).forEach(n => {
      this.scales[n] = this.createScale(n);
    });
  }
  findDomainAndRangeAxes() {
    const t = this.findVerticalAxesPositions(),
      n = this.findHorizontalAxesPositions(),
      e = this.findDomainAndRangeAxesPositions(t, n);
    this.domainAxisPosition = e.primaryDomainAxisPosition, this.rangeAxisPosition = e.primaryRangeAxisPosition, this.isDualAxes() && (this.secondaryDomainAxisPosition = e.secondaryDomainAxisPosition, this.secondaryRangeAxisPosition = e.secondaryRangeAxisPosition);
  }
  determineOrientation() {
    (this.rangeAxisPosition === _.LEFT || this.rangeAxisPosition === _.RIGHT) && (this.domainAxisPosition === _.BOTTOM || this.domainAxisPosition === _.TOP) ? this.orientation = N.VERTICAL : this.orientation = N.HORIZONTAL;
  }
  isDualAxes() {
    return this.dualAxes;
  }
  // if any of the axes objects have correspondingDatasets [] asserted we flag the chart as dual axes
  // it does not count as dual axes if it just has another axis turned on but is not actually using it to map a dataset
  determineAxisDuality() {
    var e, s, o, a;
    const t = this.model.getOptions(),
      n = c(t, "axes");
    ((e = n[_.LEFT]) != null && e.correspondingDatasets && n[_.RIGHT] || (s = n[_.RIGHT]) != null && s.correspondingDatasets && n[_.LEFT] || (o = n[_.TOP]) != null && o.correspondingDatasets && n[_.BOTTOM] || (a = n[_.BOTTOM]) != null && a.correspondingDatasets && n[_.TOP]) && (this.dualAxes = !0);
  }
  getCustomDomainValuesByposition(t) {
    const n = c(this.model.getOptions(), "axes", t, "domain");
    if (n && !Array.isArray(n)) throw new Error(`Domain in ${t} axis is not a valid array`);
    if (Array.isArray(n) && (this.scaleTypes[t] === m.LINEAR || this.scaleTypes[t] === m.TIME) && n.length !== 2) throw new Error(`There can only be 2 elements in domain for scale type: ${this.scaleTypes[t]}`);
    return n;
  }
  getOrientation() {
    return this.orientation;
  }
  getScaleByPosition(t) {
    return this.scales[t];
  }
  getScaleTypeByPosition(t) {
    return this.scaleTypes[t];
  }
  getDomainAxisScaleType() {
    const t = this.getDomainAxisPosition();
    return this.getScaleTypeByPosition(t);
  }
  getRangeAxisScaleType() {
    const t = this.getRangeAxisPosition();
    return this.getScaleTypeByPosition(t);
  }
  getDomainScale() {
    return this.scales[this.domainAxisPosition];
  }
  getRangeScale() {
    return this.scales[this.rangeAxisPosition];
  }
  // Find the main x-axis out of the 2 x-axis on the chart (when 2D axis is used)
  getMainXAxisPosition() {
    const t = [_.BOTTOM, _.TOP];
    return [this.domainAxisPosition, this.rangeAxisPosition].find(n => t.indexOf(n) > -1);
  }
  // Find the main y-axis out of the 2 y-axis on the chart (when 2D axis is used)
  getMainYAxisPosition() {
    const t = [_.LEFT, _.RIGHT];
    return [this.domainAxisPosition, this.rangeAxisPosition].find(n => t.indexOf(n) > -1);
  }
  getMainXScale() {
    return this.scales[this.getMainXAxisPosition()];
  }
  getMainYScale() {
    return this.scales[this.getMainYAxisPosition()];
  }
  getValueFromScale(t, n, e, s) {
    const o = this.model.getOptions(),
      r = c(o, "axes")[e],
      {
        mapsTo: h
      } = r,
      l = c(s, h) !== null ? s[h] : s;
    let f;
    switch (n) {
      case m.LABELS:
        f = t(l) + t.step() / 2;
        break;
      case m.TIME:
        f = t(new Date(l));
        break;
      default:
        f = t(l);
    }
    return f;
  }
  getBoundedScaledValues(t) {
    const {
        bounds: n
      } = this.model.getOptions(),
      e = this.getRangeAxisPosition({
        datum: t
      }),
      s = this.scales[e],
      o = this.model.getOptions(),
      r = c(o, "axes")[e],
      {
        mapsTo: h
      } = r,
      l = t[h] !== void 0 ? t[h] : t;
    return [s(c(t, n.upperBoundMapsTo) !== null ? t[n.upperBoundMapsTo] : l), s(c(t, n.lowerBoundMapsTo) !== null ? t[n.lowerBoundMapsTo] : l)];
  }
  getValueThroughAxisPosition(t, n) {
    const e = this.scaleTypes[t],
      s = this.scales[t];
    return this.getValueFromScale(s, e, t, n);
  }
  getDomainValue(t) {
    const n = this.getDomainAxisPosition({
      datum: t
    });
    return this.getValueThroughAxisPosition(n, t);
  }
  getRangeValue(t) {
    const n = this.getRangeAxisPosition({
      datum: t
    });
    return this.getValueThroughAxisPosition(n, t);
  }
  getMainXScaleType() {
    return this.getScaleTypeByPosition(this.getMainXAxisPosition());
  }
  getMainYScaleType() {
    return this.getScaleTypeByPosition(this.getMainYAxisPosition());
  }
  getDomainIdentifier(t) {
    const n = this.model.getOptions();
    return c(n, "axes", this.getDomainAxisPosition({
      datum: t
    }), "mapsTo");
  }
  getRangeIdentifier(t) {
    const n = this.model.getOptions();
    return c(n, "axes", this.getRangeAxisPosition({
      datum: t
    }), "mapsTo");
  }
  extendsDomain(t, n) {
    const e = this.model.getOptions(),
      s = c(e, "axes", t);
    if (s.scaleType === m.TIME) {
      const o = c(e, "timeScale", "addSpaceOnEdges");
      return Wi(n, o);
    } else return Ji(n, Q.paddingRatio, s.scaleType);
  }
  findVerticalAxesPositions() {
    const t = this.model.getOptions(),
      n = c(t, "axes"),
      e = this.isDualAxes();
    return c(n, _.LEFT) === null && c(n, _.RIGHT) !== null || c(n, _.RIGHT, "main") === !0 || e && c(n, _.LEFT, "correspondingDatasets") ? {
      primary: _.RIGHT,
      secondary: _.LEFT
    } : {
      primary: _.LEFT,
      secondary: _.RIGHT
    };
  }
  findHorizontalAxesPositions() {
    const t = this.model.getOptions(),
      n = c(t, "axes"),
      e = this.isDualAxes();
    return c(n, _.BOTTOM) === null && c(n, _.TOP) !== null || c(n, _.TOP, "main") === !0 || e && c(n, _.BOTTOM, "correspondingDatasets") ? {
      primary: _.TOP,
      secondary: _.BOTTOM
    } : {
      primary: _.BOTTOM,
      secondary: _.TOP
    };
  }
  findDomainAndRangeAxesPositions(t, n) {
    const e = this.model.getOptions(),
      s = c(e, "axes", t.primary),
      o = c(e, "axes", n.primary),
      a = s.scaleType || m.LINEAR,
      r = o.scaleType || m.LINEAR,
      h = {
        primaryDomainAxisPosition: null,
        secondaryDomainAxisPosition: null,
        primaryRangeAxisPosition: null,
        secondaryRangeAxisPosition: null
      };
    return h.primaryDomainAxisPosition = n.primary, h.primaryRangeAxisPosition = t.primary, h.secondaryDomainAxisPosition = n.secondary, h.secondaryRangeAxisPosition = t.secondary, (!(r === m.LABELS || r === m.TIME) && a === m.LABELS || a === m.TIME) && (h.primaryDomainAxisPosition = t.primary, h.primaryRangeAxisPosition = n.primary, h.secondaryDomainAxisPosition = t.secondary, h.secondaryRangeAxisPosition = n.secondary), h;
  }
  getScaleDomain(t) {
    const n = this.model.getOptions(),
      e = c(n, "axes", t),
      s = c(n, "bounds"),
      {
        includeZero: o
      } = e,
      a = c(e, "scaleType") || m.LINEAR;
    if (this.model.isDataEmpty()) return [];
    if (e.binned) {
      const {
        bins: u
      } = this.model.getBinConfigurations();
      return [0, jt(u, g => g.length)];
    } else if (e.limitDomainToBins) {
      const {
          bins: u
        } = this.model.getBinConfigurations(),
        g = this.model.getStackKeys({
          bins: u
        });
      return [g[0].split(":")[0], g[g.length - 1].split(":")[1]];
    }
    const r = this.model.getDisplayData(),
      {
        extendLinearDomainBy: h,
        mapsTo: l,
        percentage: f,
        thresholds: v
      } = e,
      {
        reference: p,
        compareTo: T
      } = Q.ratio;
    if (e.domain) return a === m.LABELS ? e.domain : (a === m.TIME && (e.domain = e.domain.map(u => u.getTime === void 0 ? new Date(u) : u)), this.extendsDomain(t, e.domain));
    if (f) return [0, 100];
    if (e && a === m.LABELS) return Wt(r.map(u => u[l]));
    let y, d;
    const D = this.model.getDataGroupNames();
    if (a === m.LABELS_RATIO) return r.map(u => `${u[p]}/${u[T]}`);
    if (a === m.TIME) d = r.map(u => +new Date(u[l]));else if (s && n.axes) d = [], r.forEach(u => {
      d.push(u[l]), u[s.upperBoundMapsTo] && d.push(u[s.upperBoundMapsTo]), u[s.lowerBoundMapsTo] && d.push(u[s.lowerBoundMapsTo]);
    });else if (e.stacked === !0 && D && t === this.getRangeAxisPosition()) {
      const {
          groupMapsTo: u
        } = n.data,
        g = this.model.getDataValuesGroupedByKeys({
          groups: D
        }),
        kt = r.filter(M => !D.includes(M[u])),
        U = [];
      g.forEach(M => {
        const {
          ...Rt
        } = M;
        let K = 0,
          q = 0;
        Object.values(di(Rt, "sharedStackKey")).forEach(S => {
          isNaN(S) || (S < 0 ? q += S : K += S);
        }), U.push([q, K]);
      }), d = [...xt(U), ...kt.map(M => M[l])];
    } else d = [], r.forEach(u => {
      const g = u[l];
      Array.isArray(g) && g.length === 2 ? (d.push(g[0]), d.push(g[1])) : (h && d.push(Math.max(u[l], u[h])), d.push(g));
    });
    return a !== m.TIME && a !== m.LOG && o && d.push(0), v && v.length > 0 && v.forEach(u => {
      const g = c(u, "value");
      g !== null && d.push(g);
    }), y = pt(d), y = this.extendsDomain(t, y), y;
  }
  createScale(t) {
    const n = this.model.getOptions(),
      e = c(n, "axes", t);
    if (!e) return null;
    const s = c(e, "scaleType") || m.LINEAR;
    this.scaleTypes[t] = s;
    let o;
    return s === m.TIME ? o = ei() : s === m.LOG ? o = gt().base(e.base || 10) : s === m.LABELS || s === m.LABELS_RATIO ? o = si() : o = Jt(), o.domain(this.getScaleDomain(t)), o;
  }
  getDomainLowerBound(t) {
    let n,
      e = 0;
    return this.getOrientation() === N.VERTICAL ? n = this.getMainYScale().domain() : n = this.getMainXScale().domain(), c(this.model.getOptions(), "axes", t, "includeZero") === !1 && n[0] > 0 && n[1] > 0 && (e = n[0]), e;
  }
  getHighestDomainThreshold() {
    const t = c(this.model.getOptions(), "axes"),
      n = this.getDomainAxisPosition(),
      {
        thresholds: e
      } = t[n];
    if (!Array.isArray(e) || Array.isArray(e) && !e.length) return null;
    const s = this.getDomainScale(),
      o = e.sort((r, h) => h.value - r.value)[0];
    return this.getScaleTypeByPosition(n) === m.TIME && (typeof o.value == "string" || o.value.getTime === void 0) && (o.value = new Date(o.value)), {
      threshold: o,
      scaleValue: s(o.value)
    };
  }
  getHighestRangeThreshold() {
    const t = c(this.model.getOptions(), "axes"),
      n = this.getRangeAxisPosition(),
      {
        thresholds: e
      } = t[n];
    if (!Array.isArray(e) || Array.isArray(e) && !e.length) return null;
    const s = this.getRangeScale(),
      o = e.sort((a, r) => r.value - a.value)[0];
    return {
      threshold: o,
      scaleValue: s(o.value)
    };
  }
}
function Wi(i, t) {
  const n = new Date(i[0]),
    e = new Date(i[1]);
  return Fi(e, n) > 1 ? [zi(n, t), Pt(e, t)] : Gi(e, n) > 1 ? [$i(n, t), H(e, t)] : Ui(e, n) > 1 ? [Ki(n, t), Et(e, t)] : qi(e, n) > 1 ? [ji(n, t), wt(e, t)] : ct(e, n) > 30 ? [lt(n, t * 30), I(e, t * 30)] : ct(e, n) > 1 ? [lt(n, t), I(e, t)] : _t(e, n) > 15 ? [ut(n, t * 15), Z(e, t * 15)] : _t(e, n) > 1 ? [ut(n, t), Z(e, t)] : [n, e];
}
function Ji([i, t], n, e) {
  const o = (t - i) * n,
    a = t <= 0 && t + o > 0 ? 0 : t + o;
  let r = i >= 0 && i - o < 0 ? 0 : i - o;
  if (e === m.LOG && r <= 0) {
    if (i <= 0) throw Error("Data must have values greater than 0 if log scale type is used.");
    r = i;
  }
  return [r, a];
}
class hn extends A {
  constructor() {
    super(...arguments), this.curveTypes = {
      curveLinear: oi,
      curveLinearClosed: ai,
      curveBasis: Di,
      curveBasisClosed: Ai,
      curveBasisOpen: Oi,
      curveBundle: Mi,
      curveCardinal: bi,
      curveCardinalClosed: Si,
      curveCardinalOpen: Pi,
      curveCatmullRom: Ei,
      curveCatmullRomClosed: wi,
      curveCatmullRomOpen: ki,
      curveMonotoneX: Ri,
      curveMonotoneY: Bi,
      curveNatural: Li,
      curveStep: Ni,
      curveStepAfter: Ii,
      curveStepBefore: Ci
    };
  }
  getD3Curve() {
    let t = "curveLinear";
    const n = this.model.getOptions().curve;
    if (n && (typeof n == "string" ? t = n : t = n.name), this.curveTypes[t]) {
      let e = this.curveTypes[t];
      return n && Object.keys(n).forEach(s => {
        e[s] && (e = e[s](n[s]));
      }), e;
    }
    return console.warn(`The curve type '${t}' is invalid, using 'curveLinear' instead`), this.curveTypes.curveLinear;
  }
}
class cn extends A {
  isZoomBarEnabled() {
    if (!this.services.cartesianScales || !c(this.model.getOptions(), "zoomBar", "top", "enabled")) return !1;
    this.services.cartesianScales.findDomainAndRangeAxes();
    const t = this.services.cartesianScales.getMainXAxisPosition(),
      n = c(this.model.getOptions(), "axes", t, "scaleType");
    return t === _.BOTTOM && n === m.TIME;
  }
  // get display data for zoom bar
  // basically it's sum of value grouped by time
  getZoomBarData() {
    const t = this.model.getZoomBarData();
    return t && t.length > 1 ? t : this.model.getDisplayData();
  }
  getDefaultZoomBarDomain(t) {
    if (!this.services.zoom) throw new Error("Services zoom not defined");
    const n = t || this.services.zoom.getZoomBarData(),
      {
        cartesianScales: e
      } = this.services;
    if (!e) throw new Error("Services cartesianScales undefined");
    const s = e.getMainXAxisPosition(),
      o = e.getDomainIdentifier(),
      a = c(this.model.getOptions(), "axes", s, "domain");
    if (Array.isArray(a) && a.length === 2) return a;
    if (!s) throw new Error("Not defined: mainXAxisPosition");
    return e.extendsDomain(s, pt(n, r => r[o]));
  }
  handleDomainChange(t, n = {
    dispatchEvent: !0
  }) {
    var e;
    this.model.set({
      zoomDomain: t
    }, {
      animate: !1
    }), n.dispatchEvent && ((e = this.services.events) == null || e.dispatchEvent(E.ZoomDomain.CHANGE, {
      newDomain: t
    }));
  }
  getZoomRatio() {
    return c(this.model.getOptions(), "zoomBar", "zoomRatio");
  }
  // filter out data not inside zoom domain
  // to get better range value for axis label
  filterDataForRangeAxis(t, n) {
    var a;
    const e = this.model.get("zoomDomain"),
      s = Object.assign({
        stacked: !1
      },
      // default configs
      n),
      o = c(this.model.getOptions(), "zoomBar", "updateRangeAxis");
    if (this.isZoomBarEnabled() && o && e) {
      const r = s.stacked ? "sharedStackKey" : (a = this.services.cartesianScales) == null ? void 0 : a.getDomainIdentifier(),
        h = t.filter(l => new Date(l[r]) >= e[0] && new Date(l[r]) <= e[1]);
      if (h.length > 0) return h;
    }
    return t;
  }
  zoomIn(t = this.getZoomRatio()) {
    var T;
    const n = this.model.get("zoomDomain"),
      e = Qt.handleWidth,
      s = (T = this.services.cartesianScales) == null ? void 0 : T.getMainXScale().copy();
    s.domain(this.getDefaultZoomBarDomain());
    const o = s(n[0]),
      a = s(n[1]);
    if (a - o < e + 1) return;
    const r = s.range(),
      h = a - o,
      l = Math.min((r[1] - r[0]) / 2 * (t / 2), h / 2);
    let f = o + l,
      v = a - l;
    f >= v && (f = o + h / 2 - e / 2, v = a - h / 2 + e / 2);
    const p = [s.invert(f), s.invert(v)];
    (n[0].valueOf() !== p[0].valueOf() || n[1].valueOf() !== p[1].valueOf()) && this.handleDomainChange(p);
  }
  zoomOut(t = this.getZoomRatio()) {
    const n = this.model.get("zoomDomain");
    if (!this.services.cartesianScales) throw new Error("Services cartesianScales undefined");
    const e = this.services.cartesianScales.getMainXScale().copy();
    e.domain(this.getDefaultZoomBarDomain());
    const s = e(n[0]),
      o = e(n[1]),
      a = e.range(),
      r = (a[1] - a[0]) / 2 * (t / 2),
      h = Math.max(s - r, a[0]),
      l = Math.min(o + r, a[1]),
      f = [e.invert(h), e.invert(l)];
    (n[0].valueOf() !== f[0].valueOf() || n[1].valueOf() !== f[1].valueOf()) && this.handleDomainChange(f);
  }
  resetZoomDomain() {
    const t = this.model.get("zoomDomain"),
      n = this.getDefaultZoomBarDomain();
    (t[0].valueOf() !== n[0].valueOf() || t[1].valueOf() !== n[1].valueOf()) && this.handleDomainChange(n);
  }
  // check if current zoom domain is already the min zoom domain
  // when toolbar is rendered, we don't render chart yet
  // don't depend on scale range
  isMinZoomDomain() {
    const t = this.model.get("zoomDomain"),
      n = this.getDefaultZoomBarDomain();
    if (!t || !n) return !1;
    const e = t[1].valueOf() - t[0].valueOf(),
      s = n[1].valueOf() - n[0].valueOf(),
      o = c(this.model.getOptions(), "zoomBar", "minZoomRatio");
    return e / s < o;
  }
  // check if current zoom domain is already the max zoom domain
  isMaxZoomDomain() {
    const t = this.model.get("zoomDomain"),
      n = this.getDefaultZoomBarDomain();
    return !!(t && n && t[0].valueOf() === n[0].valueOf() && t[1].valueOf() === n[1].valueOf());
  }
  isEmptyState() {
    return this.getZoomBarData().length === 0;
  }
  isZoomBarLoading(t) {
    return c(this.model.getOptions(), "zoomBar", t, "loading");
  }
  isZoomBarLocked(t) {
    return c(this.model.getOptions(), "zoomBar", t, "locked");
  }
}
export { en as C, sn as E, on as F, an as T, cn as Z, rn as a, hi as b, hn as c, xt as f };
