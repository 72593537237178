import { b as f, k as i, i as b } from "./color-scale-utils-f3PWy_Op.mjs";
function h(r, n) {
  return r && f(r, n, i);
}
function y(r, n) {
  return function (a, t) {
    if (a == null) return a;
    if (!b(a)) return r(a, t);
    for (var u = a.length, e = -1, s = Object(a); ++e < u && t(s[e], e, s) !== !1;);
    return a;
  };
}
var p = y(h);
function g(r) {
  return function () {
    return r;
  };
}
var k = Array.prototype.slice;
function m(r) {
  return typeof r == "object" && "length" in r ? r : Array.from(r);
}
export { m as a, p as b, g as c, k as s };
