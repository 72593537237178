import { Z as Ge, s as W, W as ae, u as ba, A as Tt, k as _a, N as Mt, x as va, p as xa, U as Ta, D as Ma, S as Ln, i as Gr, K as Sa } from "./axis-scales-CPuwbFQf.mjs";
function Re(t) {
  return (e = {}) => {
    const n = e.width ? String(e.width) : t.defaultWidth;
    return t.formats[n] || t.formats[t.defaultWidth];
  };
}
function St(t) {
  return (e, n) => {
    const r = n != null && n.context ? String(n.context) : "standalone";
    let i;
    if (r === "formatting" && t.formattingValues) {
      const o = t.defaultFormattingWidth || t.defaultWidth,
        s = n != null && n.width ? String(n.width) : o;
      i = t.formattingValues[s] || t.formattingValues[o];
    } else {
      const o = t.defaultWidth,
        s = n != null && n.width ? String(n.width) : t.defaultWidth;
      i = t.values[s] || t.values[o];
    }
    const a = t.argumentCallback ? t.argumentCallback(e) : e;
    return i[a];
  };
}
function At(t) {
  return (e, n = {}) => {
    const r = n.width,
      i = r && t.matchPatterns[r] || t.matchPatterns[t.defaultMatchWidth],
      a = e.match(i);
    if (!a) return null;
    const o = a[0],
      s = r && t.parsePatterns[r] || t.parsePatterns[t.defaultParseWidth],
      u = Array.isArray(s) ? Oa(s, l => l.test(o)) :
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      Aa(s, l => l.test(o));
    let c;
    c = t.valueCallback ? t.valueCallback(u) : u, c = n.valueCallback ?
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
    n.valueCallback(c) : c;
    const f = e.slice(o.length);
    return {
      value: c,
      rest: f
    };
  };
}
function Aa(t, e) {
  for (const n in t) if (Object.prototype.hasOwnProperty.call(t, n) && e(t[n])) return n;
}
function Oa(t, e) {
  for (let n = 0; n < t.length; n++) if (e(t[n])) return n;
}
function Pa(t) {
  return (e, n = {}) => {
    const r = e.match(t.matchPattern);
    if (!r) return null;
    const i = r[0],
      a = e.match(t.parsePattern);
    if (!a) return null;
    let o = t.valueCallback ? t.valueCallback(a[0]) : a[0];
    o = n.valueCallback ? n.valueCallback(o) : o;
    const s = e.slice(i.length);
    return {
      value: o,
      rest: s
    };
  };
}
function L(t) {
  const e = Object.prototype.toString.call(t);
  return t instanceof Date || typeof t == "object" && e === "[object Date]" ? new t.constructor(+t) : typeof t == "number" || e === "[object Number]" || typeof t == "string" || e === "[object String]" ? new Date(t) : /* @__PURE__ */new Date(NaN);
}
let $a = {};
function Se() {
  return $a;
}
function It(t, e) {
  var s, u, c, f;
  const n = Se(),
    r = (e == null ? void 0 : e.weekStartsOn) ?? ((u = (s = e == null ? void 0 : e.locale) == null ? void 0 : s.options) == null ? void 0 : u.weekStartsOn) ?? n.weekStartsOn ?? ((f = (c = n.locale) == null ? void 0 : c.options) == null ? void 0 : f.weekStartsOn) ?? 0,
    i = L(t),
    a = i.getDay(),
    o = (a < r ? 7 : 0) + a - r;
  return i.setDate(i.getDate() - o), i.setHours(0, 0, 0, 0), i;
}
const Ea = {
    lessThanXSeconds: {
      one: "less than a second",
      other: "less than {{count}} seconds"
    },
    xSeconds: {
      one: "1 second",
      other: "{{count}} seconds"
    },
    halfAMinute: "half a minute",
    lessThanXMinutes: {
      one: "less than a minute",
      other: "less than {{count}} minutes"
    },
    xMinutes: {
      one: "1 minute",
      other: "{{count}} minutes"
    },
    aboutXHours: {
      one: "about 1 hour",
      other: "about {{count}} hours"
    },
    xHours: {
      one: "1 hour",
      other: "{{count}} hours"
    },
    xDays: {
      one: "1 day",
      other: "{{count}} days"
    },
    aboutXWeeks: {
      one: "about 1 week",
      other: "about {{count}} weeks"
    },
    xWeeks: {
      one: "1 week",
      other: "{{count}} weeks"
    },
    aboutXMonths: {
      one: "about 1 month",
      other: "about {{count}} months"
    },
    xMonths: {
      one: "1 month",
      other: "{{count}} months"
    },
    aboutXYears: {
      one: "about 1 year",
      other: "about {{count}} years"
    },
    xYears: {
      one: "1 year",
      other: "{{count}} years"
    },
    overXYears: {
      one: "over 1 year",
      other: "over {{count}} years"
    },
    almostXYears: {
      one: "almost 1 year",
      other: "almost {{count}} years"
    }
  },
  Na = (t, e, n) => {
    let r;
    const i = Ea[t];
    return typeof i == "string" ? r = i : e === 1 ? r = i.one : r = i.other.replace("{{count}}", e.toString()), n != null && n.addSuffix ? n.comparison && n.comparison > 0 ? "in " + r : r + " ago" : r;
  },
  Ca = {
    lastWeek: "'last' eeee 'at' p",
    yesterday: "'yesterday at' p",
    today: "'today at' p",
    tomorrow: "'tomorrow at' p",
    nextWeek: "eeee 'at' p",
    other: "P"
  },
  Da = (t, e, n, r) => Ca[t],
  Ia = {
    narrow: ["B", "A"],
    abbreviated: ["BC", "AD"],
    wide: ["Before Christ", "Anno Domini"]
  },
  Ra = {
    narrow: ["1", "2", "3", "4"],
    abbreviated: ["Q1", "Q2", "Q3", "Q4"],
    wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
  },
  Fa = {
    narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
    abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  },
  La = {
    narrow: ["S", "M", "T", "W", "T", "F", "S"],
    short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  },
  ka = {
    narrow: {
      am: "a",
      pm: "p",
      midnight: "mi",
      noon: "n",
      morning: "morning",
      afternoon: "afternoon",
      evening: "evening",
      night: "night"
    },
    abbreviated: {
      am: "AM",
      pm: "PM",
      midnight: "midnight",
      noon: "noon",
      morning: "morning",
      afternoon: "afternoon",
      evening: "evening",
      night: "night"
    },
    wide: {
      am: "a.m.",
      pm: "p.m.",
      midnight: "midnight",
      noon: "noon",
      morning: "morning",
      afternoon: "afternoon",
      evening: "evening",
      night: "night"
    }
  },
  Wa = {
    narrow: {
      am: "a",
      pm: "p",
      midnight: "mi",
      noon: "n",
      morning: "in the morning",
      afternoon: "in the afternoon",
      evening: "in the evening",
      night: "at night"
    },
    abbreviated: {
      am: "AM",
      pm: "PM",
      midnight: "midnight",
      noon: "noon",
      morning: "in the morning",
      afternoon: "in the afternoon",
      evening: "in the evening",
      night: "at night"
    },
    wide: {
      am: "a.m.",
      pm: "p.m.",
      midnight: "midnight",
      noon: "noon",
      morning: "in the morning",
      afternoon: "in the afternoon",
      evening: "in the evening",
      night: "at night"
    }
  },
  Ya = (t, e) => {
    const n = Number(t),
      r = n % 100;
    if (r > 20 || r < 10) switch (r % 10) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
    }
    return n + "th";
  },
  Ha = {
    ordinalNumber: Ya,
    era: St({
      values: Ia,
      defaultWidth: "wide"
    }),
    quarter: St({
      values: Ra,
      defaultWidth: "wide",
      argumentCallback: t => t - 1
    }),
    month: St({
      values: Fa,
      defaultWidth: "wide"
    }),
    day: St({
      values: La,
      defaultWidth: "wide"
    }),
    dayPeriod: St({
      values: ka,
      defaultWidth: "wide",
      formattingValues: Wa,
      defaultFormattingWidth: "wide"
    })
  },
  Ga = /^(\d+)(th|st|nd|rd)?/i,
  Ba = /\d+/i,
  ja = {
    narrow: /^(b|a)/i,
    abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
    wide: /^(before christ|before common era|anno domini|common era)/i
  },
  qa = {
    any: [/^b/i, /^(a|c)/i]
  },
  za = {
    narrow: /^[1234]/i,
    abbreviated: /^q[1234]/i,
    wide: /^[1234](th|st|nd|rd)? quarter/i
  },
  Xa = {
    any: [/1/i, /2/i, /3/i, /4/i]
  },
  Ua = {
    narrow: /^[jfmasond]/i,
    abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
    wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
  },
  Va = {
    narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
    any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
  },
  Qa = {
    narrow: /^[smtwf]/i,
    short: /^(su|mo|tu|we|th|fr|sa)/i,
    abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
    wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
  },
  Ka = {
    narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
    any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
  },
  Za = {
    narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
    any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
  },
  Ja = {
    any: {
      am: /^a/i,
      pm: /^p/i,
      midnight: /^mi/i,
      noon: /^no/i,
      morning: /morning/i,
      afternoon: /afternoon/i,
      evening: /evening/i,
      night: /night/i
    }
  },
  to = {
    ordinalNumber: Pa({
      matchPattern: Ga,
      parsePattern: Ba,
      valueCallback: t => parseInt(t, 10)
    }),
    era: At({
      matchPatterns: ja,
      defaultMatchWidth: "wide",
      parsePatterns: qa,
      defaultParseWidth: "any"
    }),
    quarter: At({
      matchPatterns: za,
      defaultMatchWidth: "wide",
      parsePatterns: Xa,
      defaultParseWidth: "any",
      valueCallback: t => t + 1
    }),
    month: At({
      matchPatterns: Ua,
      defaultMatchWidth: "wide",
      parsePatterns: Va,
      defaultParseWidth: "any"
    }),
    day: At({
      matchPatterns: Qa,
      defaultMatchWidth: "wide",
      parsePatterns: Ka,
      defaultParseWidth: "any"
    }),
    dayPeriod: At({
      matchPatterns: Za,
      defaultMatchWidth: "any",
      parsePatterns: Ja,
      defaultParseWidth: "any"
    })
  },
  eo = {
    full: "EEEE, MMMM do, y",
    long: "MMMM do, y",
    medium: "MMM d, y",
    short: "MM/dd/yyyy"
  },
  no = {
    full: "h:mm:ss a zzzz",
    long: "h:mm:ss a z",
    medium: "h:mm:ss a",
    short: "h:mm a"
  },
  ro = {
    full: "{{date}} 'at' {{time}}",
    long: "{{date}} 'at' {{time}}",
    medium: "{{date}}, {{time}}",
    short: "{{date}}, {{time}}"
  },
  io = {
    date: Re({
      formats: eo,
      defaultWidth: "full"
    }),
    time: Re({
      formats: no,
      defaultWidth: "full"
    }),
    dateTime: Re({
      formats: ro,
      defaultWidth: "full"
    })
  },
  Br = {
    code: "en-US",
    formatDistance: Na,
    formatLong: io,
    formatRelative: Da,
    localize: Ha,
    match: to,
    options: {
      weekStartsOn: 0,
      firstWeekContainsDate: 1
    }
  };
var jr = typeof global == "object" && global && global.Object === Object && global,
  ao = typeof self == "object" && self && self.Object === Object && self,
  G = jr || ao || Function("return this")(),
  C = G.Symbol,
  qr = Object.prototype,
  oo = qr.hasOwnProperty,
  so = qr.toString,
  Ot = C ? C.toStringTag : void 0;
function uo(t) {
  var e = oo.call(t, Ot),
    n = t[Ot];
  try {
    t[Ot] = void 0;
    var r = !0;
  } catch {}
  var i = so.call(t);
  return r && (e ? t[Ot] = n : delete t[Ot]), i;
}
var co = Object.prototype,
  fo = co.toString;
function lo(t) {
  return fo.call(t);
}
var ho = "[object Null]",
  go = "[object Undefined]",
  kn = C ? C.toStringTag : void 0;
function ut(t) {
  return t == null ? t === void 0 ? go : ho : kn && kn in Object(t) ? uo(t) : lo(t);
}
function H(t) {
  return t != null && typeof t == "object";
}
var mo = "[object Symbol]";
function sn(t) {
  return typeof t == "symbol" || H(t) && ut(t) == mo;
}
function po(t, e) {
  for (var n = -1, r = t == null ? 0 : t.length, i = Array(r); ++n < r;) i[n] = e(t[n], n, t);
  return i;
}
var $ = Array.isArray,
  yo = 1 / 0,
  Wn = C ? C.prototype : void 0,
  Yn = Wn ? Wn.toString : void 0;
function zr(t) {
  if (typeof t == "string") return t;
  if ($(t)) return po(t, zr) + "";
  if (sn(t)) return Yn ? Yn.call(t) : "";
  var e = t + "";
  return e == "0" && 1 / t == -yo ? "-0" : e;
}
function X(t) {
  var e = typeof t;
  return t != null && (e == "object" || e == "function");
}
function un(t) {
  return t;
}
var wo = "[object AsyncFunction]",
  bo = "[object Function]",
  _o = "[object GeneratorFunction]",
  vo = "[object Proxy]";
function cn(t) {
  if (!X(t)) return !1;
  var e = ut(t);
  return e == bo || e == _o || e == wo || e == vo;
}
var Fe = G["__core-js_shared__"],
  Hn = function () {
    var t = /[^.]+$/.exec(Fe && Fe.keys && Fe.keys.IE_PROTO || "");
    return t ? "Symbol(src)_1." + t : "";
  }();
function xo(t) {
  return !!Hn && Hn in t;
}
var To = Function.prototype,
  Mo = To.toString;
function ct(t) {
  if (t != null) {
    try {
      return Mo.call(t);
    } catch {}
    try {
      return t + "";
    } catch {}
  }
  return "";
}
var So = /[\\^$.*+?()[\]{}|]/g,
  Ao = /^\[object .+?Constructor\]$/,
  Oo = Function.prototype,
  Po = Object.prototype,
  $o = Oo.toString,
  Eo = Po.hasOwnProperty,
  No = RegExp("^" + $o.call(Eo).replace(So, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$");
function Co(t) {
  if (!X(t) || xo(t)) return !1;
  var e = cn(t) ? No : Ao;
  return e.test(ct(t));
}
function Do(t, e) {
  return t == null ? void 0 : t[e];
}
function ft(t, e) {
  var n = Do(t, e);
  return Co(n) ? n : void 0;
}
var Be = ft(G, "WeakMap"),
  Gn = Object.create,
  Io = /* @__PURE__ */function () {
    function t() {}
    return function (e) {
      if (!X(e)) return {};
      if (Gn) return Gn(e);
      t.prototype = e;
      var n = new t();
      return t.prototype = void 0, n;
    };
  }();
function Ro(t, e, n) {
  switch (n.length) {
    case 0:
      return t.call(e);
    case 1:
      return t.call(e, n[0]);
    case 2:
      return t.call(e, n[0], n[1]);
    case 3:
      return t.call(e, n[0], n[1], n[2]);
  }
  return t.apply(e, n);
}
function Fo() {}
function Xr(t, e) {
  var n = -1,
    r = t.length;
  for (e || (e = Array(r)); ++n < r;) e[n] = t[n];
  return e;
}
var Lo = 800,
  ko = 16,
  Wo = Date.now;
function Yo(t) {
  var e = 0,
    n = 0;
  return function () {
    var r = Wo(),
      i = ko - (r - n);
    if (n = r, i > 0) {
      if (++e >= Lo) return arguments[0];
    } else e = 0;
    return t.apply(void 0, arguments);
  };
}
function Ho(t) {
  return function () {
    return t;
  };
}
var le = function () {
    try {
      var t = ft(Object, "defineProperty");
      return t({}, "", {}), t;
    } catch {}
  }(),
  Go = le ? function (t, e) {
    return le(t, "toString", {
      configurable: !0,
      enumerable: !1,
      value: Ho(e),
      writable: !0
    });
  } : un,
  Bo = Yo(Go);
function jo(t, e) {
  for (var n = -1, r = t == null ? 0 : t.length; ++n < r && e(t[n], n, t) !== !1;);
  return t;
}
function qo(t, e, n, r) {
  for (var i = t.length, a = n + -1; ++a < i;) if (e(t[a], a, t)) return a;
  return -1;
}
function zo(t) {
  return t !== t;
}
function Xo(t, e, n) {
  for (var r = n - 1, i = t.length; ++r < i;) if (t[r] === e) return r;
  return -1;
}
function Uo(t, e, n) {
  return e === e ? Xo(t, e, n) : qo(t, zo, n);
}
function Vo(t, e) {
  var n = t == null ? 0 : t.length;
  return !!n && Uo(t, e, 0) > -1;
}
var Qo = 9007199254740991,
  Ko = /^(?:0|[1-9]\d*)$/;
function fn(t, e) {
  var n = typeof t;
  return e = e ?? Qo, !!e && (n == "number" || n != "symbol" && Ko.test(t)) && t > -1 && t % 1 == 0 && t < e;
}
function ln(t, e, n) {
  e == "__proto__" && le ? le(t, e, {
    configurable: !0,
    enumerable: !0,
    value: n,
    writable: !0
  }) : t[e] = n;
}
function Ht(t, e) {
  return t === e || t !== t && e !== e;
}
var Zo = Object.prototype,
  Jo = Zo.hasOwnProperty;
function Ur(t, e, n) {
  var r = t[e];
  (!(Jo.call(t, e) && Ht(r, n)) || n === void 0 && !(e in t)) && ln(t, e, n);
}
function Gt(t, e, n, r) {
  var i = !n;
  n || (n = {});
  for (var a = -1, o = e.length; ++a < o;) {
    var s = e[a],
      u = void 0;
    u === void 0 && (u = t[s]), i ? ln(n, s, u) : Ur(n, s, u);
  }
  return n;
}
var Bn = Math.max;
function ts(t, e, n) {
  return e = Bn(e === void 0 ? t.length - 1 : e, 0), function () {
    for (var r = arguments, i = -1, a = Bn(r.length - e, 0), o = Array(a); ++i < a;) o[i] = r[e + i];
    i = -1;
    for (var s = Array(e + 1); ++i < e;) s[i] = r[i];
    return s[e] = n(o), Ro(t, this, s);
  };
}
function Vr(t, e) {
  return Bo(ts(t, e, un), t + "");
}
var es = 9007199254740991;
function hn(t) {
  return typeof t == "number" && t > -1 && t % 1 == 0 && t <= es;
}
function Bt(t) {
  return t != null && hn(t.length) && !cn(t);
}
function ns(t, e, n) {
  if (!X(n)) return !1;
  var r = typeof e;
  return (r == "number" ? Bt(n) && fn(e, n.length) : r == "string" && e in n) ? Ht(n[e], t) : !1;
}
function rs(t) {
  return Vr(function (e, n) {
    var r = -1,
      i = n.length,
      a = i > 1 ? n[i - 1] : void 0,
      o = i > 2 ? n[2] : void 0;
    for (a = t.length > 3 && typeof a == "function" ? (i--, a) : void 0, o && ns(n[0], n[1], o) && (a = i < 3 ? void 0 : a, i = 1), e = Object(e); ++r < i;) {
      var s = n[r];
      s && t(e, s, r, a);
    }
    return e;
  });
}
var is = Object.prototype;
function Ae(t) {
  var e = t && t.constructor,
    n = typeof e == "function" && e.prototype || is;
  return t === n;
}
function as(t, e) {
  for (var n = -1, r = Array(t); ++n < t;) r[n] = e(n);
  return r;
}
var os = "[object Arguments]";
function jn(t) {
  return H(t) && ut(t) == os;
}
var Qr = Object.prototype,
  ss = Qr.hasOwnProperty,
  us = Qr.propertyIsEnumerable,
  yt = jn( /* @__PURE__ */function () {
    return arguments;
  }()) ? jn : function (t) {
    return H(t) && ss.call(t, "callee") && !us.call(t, "callee");
  };
function cs() {
  return !1;
}
var Kr = typeof exports == "object" && exports && !exports.nodeType && exports,
  qn = Kr && typeof module == "object" && module && !module.nodeType && module,
  fs = qn && qn.exports === Kr,
  zn = fs ? G.Buffer : void 0,
  ls = zn ? zn.isBuffer : void 0,
  wt = ls || cs,
  hs = "[object Arguments]",
  ds = "[object Array]",
  gs = "[object Boolean]",
  ms = "[object Date]",
  ps = "[object Error]",
  ys = "[object Function]",
  ws = "[object Map]",
  bs = "[object Number]",
  _s = "[object Object]",
  vs = "[object RegExp]",
  xs = "[object Set]",
  Ts = "[object String]",
  Ms = "[object WeakMap]",
  Ss = "[object ArrayBuffer]",
  As = "[object DataView]",
  Os = "[object Float32Array]",
  Ps = "[object Float64Array]",
  $s = "[object Int8Array]",
  Es = "[object Int16Array]",
  Ns = "[object Int32Array]",
  Cs = "[object Uint8Array]",
  Ds = "[object Uint8ClampedArray]",
  Is = "[object Uint16Array]",
  Rs = "[object Uint32Array]",
  M = {};
M[Os] = M[Ps] = M[$s] = M[Es] = M[Ns] = M[Cs] = M[Ds] = M[Is] = M[Rs] = !0;
M[hs] = M[ds] = M[Ss] = M[gs] = M[As] = M[ms] = M[ps] = M[ys] = M[ws] = M[bs] = M[_s] = M[vs] = M[xs] = M[Ts] = M[Ms] = !1;
function Fs(t) {
  return H(t) && hn(t.length) && !!M[ut(t)];
}
function dn(t) {
  return function (e) {
    return t(e);
  };
}
var Zr = typeof exports == "object" && exports && !exports.nodeType && exports,
  Dt = Zr && typeof module == "object" && module && !module.nodeType && module,
  Ls = Dt && Dt.exports === Zr,
  Le = Ls && jr.process,
  bt = function () {
    try {
      var t = Dt && Dt.require && Dt.require("util").types;
      return t || Le && Le.binding && Le.binding("util");
    } catch {}
  }(),
  Xn = bt && bt.isTypedArray,
  Oe = Xn ? dn(Xn) : Fs,
  ks = Object.prototype,
  Ws = ks.hasOwnProperty;
function Jr(t, e) {
  var n = $(t),
    r = !n && yt(t),
    i = !n && !r && wt(t),
    a = !n && !r && !i && Oe(t),
    o = n || r || i || a,
    s = o ? as(t.length, String) : [],
    u = s.length;
  for (var c in t) (e || Ws.call(t, c)) && !(o && (
  // Safari 9 has enumerable `arguments.length` in strict mode.
  c == "length" ||
  // Node.js 0.10 has enumerable non-index properties on buffers.
  i && (c == "offset" || c == "parent") ||
  // PhantomJS 2 has enumerable non-index properties on typed arrays.
  a && (c == "buffer" || c == "byteLength" || c == "byteOffset") ||
  // Skip index properties.
  fn(c, u))) && s.push(c);
  return s;
}
function ti(t, e) {
  return function (n) {
    return t(e(n));
  };
}
var Ys = ti(Object.keys, Object),
  Hs = Object.prototype,
  Gs = Hs.hasOwnProperty;
function ei(t) {
  if (!Ae(t)) return Ys(t);
  var e = [];
  for (var n in Object(t)) Gs.call(t, n) && n != "constructor" && e.push(n);
  return e;
}
function Pe(t) {
  return Bt(t) ? Jr(t) : ei(t);
}
function Bs(t) {
  var e = [];
  if (t != null) for (var n in Object(t)) e.push(n);
  return e;
}
var js = Object.prototype,
  qs = js.hasOwnProperty;
function zs(t) {
  if (!X(t)) return Bs(t);
  var e = Ae(t),
    n = [];
  for (var r in t) r == "constructor" && (e || !qs.call(t, r)) || n.push(r);
  return n;
}
function jt(t) {
  return Bt(t) ? Jr(t, !0) : zs(t);
}
var Xs = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/,
  Us = /^\w*$/;
function gn(t, e) {
  if ($(t)) return !1;
  var n = typeof t;
  return n == "number" || n == "symbol" || n == "boolean" || t == null || sn(t) ? !0 : Us.test(t) || !Xs.test(t) || e != null && t in Object(e);
}
var Rt = ft(Object, "create");
function Vs() {
  this.__data__ = Rt ? Rt(null) : {}, this.size = 0;
}
function Qs(t) {
  var e = this.has(t) && delete this.__data__[t];
  return this.size -= e ? 1 : 0, e;
}
var Ks = "__lodash_hash_undefined__",
  Zs = Object.prototype,
  Js = Zs.hasOwnProperty;
function tu(t) {
  var e = this.__data__;
  if (Rt) {
    var n = e[t];
    return n === Ks ? void 0 : n;
  }
  return Js.call(e, t) ? e[t] : void 0;
}
var eu = Object.prototype,
  nu = eu.hasOwnProperty;
function ru(t) {
  var e = this.__data__;
  return Rt ? e[t] !== void 0 : nu.call(e, t);
}
var iu = "__lodash_hash_undefined__";
function au(t, e) {
  var n = this.__data__;
  return this.size += this.has(t) ? 0 : 1, n[t] = Rt && e === void 0 ? iu : e, this;
}
function rt(t) {
  var e = -1,
    n = t == null ? 0 : t.length;
  for (this.clear(); ++e < n;) {
    var r = t[e];
    this.set(r[0], r[1]);
  }
}
rt.prototype.clear = Vs;
rt.prototype.delete = Qs;
rt.prototype.get = tu;
rt.prototype.has = ru;
rt.prototype.set = au;
function ou() {
  this.__data__ = [], this.size = 0;
}
function $e(t, e) {
  for (var n = t.length; n--;) if (Ht(t[n][0], e)) return n;
  return -1;
}
var su = Array.prototype,
  uu = su.splice;
function cu(t) {
  var e = this.__data__,
    n = $e(e, t);
  if (n < 0) return !1;
  var r = e.length - 1;
  return n == r ? e.pop() : uu.call(e, n, 1), --this.size, !0;
}
function fu(t) {
  var e = this.__data__,
    n = $e(e, t);
  return n < 0 ? void 0 : e[n][1];
}
function lu(t) {
  return $e(this.__data__, t) > -1;
}
function hu(t, e) {
  var n = this.__data__,
    r = $e(n, t);
  return r < 0 ? (++this.size, n.push([t, e])) : n[r][1] = e, this;
}
function U(t) {
  var e = -1,
    n = t == null ? 0 : t.length;
  for (this.clear(); ++e < n;) {
    var r = t[e];
    this.set(r[0], r[1]);
  }
}
U.prototype.clear = ou;
U.prototype.delete = cu;
U.prototype.get = fu;
U.prototype.has = lu;
U.prototype.set = hu;
var Ft = ft(G, "Map");
function du() {
  this.size = 0, this.__data__ = {
    hash: new rt(),
    map: new (Ft || U)(),
    string: new rt()
  };
}
function gu(t) {
  var e = typeof t;
  return e == "string" || e == "number" || e == "symbol" || e == "boolean" ? t !== "__proto__" : t === null;
}
function Ee(t, e) {
  var n = t.__data__;
  return gu(e) ? n[typeof e == "string" ? "string" : "hash"] : n.map;
}
function mu(t) {
  var e = Ee(this, t).delete(t);
  return this.size -= e ? 1 : 0, e;
}
function pu(t) {
  return Ee(this, t).get(t);
}
function yu(t) {
  return Ee(this, t).has(t);
}
function wu(t, e) {
  var n = Ee(this, t),
    r = n.size;
  return n.set(t, e), this.size += n.size == r ? 0 : 1, this;
}
function V(t) {
  var e = -1,
    n = t == null ? 0 : t.length;
  for (this.clear(); ++e < n;) {
    var r = t[e];
    this.set(r[0], r[1]);
  }
}
V.prototype.clear = du;
V.prototype.delete = mu;
V.prototype.get = pu;
V.prototype.has = yu;
V.prototype.set = wu;
var bu = "Expected a function";
function mn(t, e) {
  if (typeof t != "function" || e != null && typeof e != "function") throw new TypeError(bu);
  var n = function () {
    var r = arguments,
      i = e ? e.apply(this, r) : r[0],
      a = n.cache;
    if (a.has(i)) return a.get(i);
    var o = t.apply(this, r);
    return n.cache = a.set(i, o) || a, o;
  };
  return n.cache = new (mn.Cache || V)(), n;
}
mn.Cache = V;
var _u = 500;
function vu(t) {
  var e = mn(t, function (r) {
      return n.size === _u && n.clear(), r;
    }),
    n = e.cache;
  return e;
}
var xu = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g,
  Tu = /\\(\\)?/g,
  Mu = vu(function (t) {
    var e = [];
    return t.charCodeAt(0) === 46 && e.push(""), t.replace(xu, function (n, r, i, a) {
      e.push(i ? a.replace(Tu, "$1") : r || n);
    }), e;
  });
function Su(t) {
  return t == null ? "" : zr(t);
}
function ni(t, e) {
  return $(t) ? t : gn(t, e) ? [t] : Mu(Su(t));
}
var Au = 1 / 0;
function Ne(t) {
  if (typeof t == "string" || sn(t)) return t;
  var e = t + "";
  return e == "0" && 1 / t == -Au ? "-0" : e;
}
function ri(t, e) {
  e = ni(e, t);
  for (var n = 0, r = e.length; t != null && n < r;) t = t[Ne(e[n++])];
  return n && n == r ? t : void 0;
}
function Ou(t, e, n) {
  var r = t == null ? void 0 : ri(t, e);
  return r === void 0 ? n : r;
}
function pn(t, e) {
  for (var n = -1, r = e.length, i = t.length; ++n < r;) t[i + n] = e[n];
  return t;
}
var Un = C ? C.isConcatSpreadable : void 0;
function Pu(t) {
  return $(t) || yt(t) || !!(Un && t && t[Un]);
}
function ii(t, e, n, r, i) {
  var a = -1,
    o = t.length;
  for (n || (n = Pu), i || (i = []); ++a < o;) {
    var s = t[a];
    e > 0 && n(s) ? e > 1 ? ii(s, e - 1, n, r, i) : pn(i, s) : r || (i[i.length] = s);
  }
  return i;
}
var yn = ti(Object.getPrototypeOf, Object),
  $u = "[object Object]",
  Eu = Function.prototype,
  Nu = Object.prototype,
  ai = Eu.toString,
  Cu = Nu.hasOwnProperty,
  Du = ai.call(Object);
function Iu(t) {
  if (!H(t) || ut(t) != $u) return !1;
  var e = yn(t);
  if (e === null) return !0;
  var n = Cu.call(e, "constructor") && e.constructor;
  return typeof n == "function" && n instanceof n && ai.call(n) == Du;
}
function Ru() {
  this.__data__ = new U(), this.size = 0;
}
function Fu(t) {
  var e = this.__data__,
    n = e.delete(t);
  return this.size = e.size, n;
}
function Lu(t) {
  return this.__data__.get(t);
}
function ku(t) {
  return this.__data__.has(t);
}
var Wu = 200;
function Yu(t, e) {
  var n = this.__data__;
  if (n instanceof U) {
    var r = n.__data__;
    if (!Ft || r.length < Wu - 1) return r.push([t, e]), this.size = ++n.size, this;
    n = this.__data__ = new V(r);
  }
  return n.set(t, e), this.size = n.size, this;
}
function R(t) {
  var e = this.__data__ = new U(t);
  this.size = e.size;
}
R.prototype.clear = Ru;
R.prototype.delete = Fu;
R.prototype.get = Lu;
R.prototype.has = ku;
R.prototype.set = Yu;
function Hu(t, e) {
  return t && Gt(e, Pe(e), t);
}
function Gu(t, e) {
  return t && Gt(e, jt(e), t);
}
var oi = typeof exports == "object" && exports && !exports.nodeType && exports,
  Vn = oi && typeof module == "object" && module && !module.nodeType && module,
  Bu = Vn && Vn.exports === oi,
  Qn = Bu ? G.Buffer : void 0,
  Kn = Qn ? Qn.allocUnsafe : void 0;
function si(t, e) {
  if (e) return t.slice();
  var n = t.length,
    r = Kn ? Kn(n) : new t.constructor(n);
  return t.copy(r), r;
}
function ju(t, e) {
  for (var n = -1, r = t == null ? 0 : t.length, i = 0, a = []; ++n < r;) {
    var o = t[n];
    e(o, n, t) && (a[i++] = o);
  }
  return a;
}
function ui() {
  return [];
}
var qu = Object.prototype,
  zu = qu.propertyIsEnumerable,
  Zn = Object.getOwnPropertySymbols,
  wn = Zn ? function (t) {
    return t == null ? [] : (t = Object(t), ju(Zn(t), function (e) {
      return zu.call(t, e);
    }));
  } : ui;
function Xu(t, e) {
  return Gt(t, wn(t), e);
}
var Uu = Object.getOwnPropertySymbols,
  ci = Uu ? function (t) {
    for (var e = []; t;) pn(e, wn(t)), t = yn(t);
    return e;
  } : ui;
function Vu(t, e) {
  return Gt(t, ci(t), e);
}
function fi(t, e, n) {
  var r = e(t);
  return $(t) ? r : pn(r, n(t));
}
function je(t) {
  return fi(t, Pe, wn);
}
function Qu(t) {
  return fi(t, jt, ci);
}
var qe = ft(G, "DataView"),
  ze = ft(G, "Promise"),
  mt = ft(G, "Set"),
  Jn = "[object Map]",
  Ku = "[object Object]",
  tr = "[object Promise]",
  er = "[object Set]",
  nr = "[object WeakMap]",
  rr = "[object DataView]",
  Zu = ct(qe),
  Ju = ct(Ft),
  tc = ct(ze),
  ec = ct(mt),
  nc = ct(Be),
  N = ut;
(qe && N(new qe(new ArrayBuffer(1))) != rr || Ft && N(new Ft()) != Jn || ze && N(ze.resolve()) != tr || mt && N(new mt()) != er || Be && N(new Be()) != nr) && (N = function (t) {
  var e = ut(t),
    n = e == Ku ? t.constructor : void 0,
    r = n ? ct(n) : "";
  if (r) switch (r) {
    case Zu:
      return rr;
    case Ju:
      return Jn;
    case tc:
      return tr;
    case ec:
      return er;
    case nc:
      return nr;
  }
  return e;
});
var rc = Object.prototype,
  ic = rc.hasOwnProperty;
function ac(t) {
  var e = t.length,
    n = new t.constructor(e);
  return e && typeof t[0] == "string" && ic.call(t, "index") && (n.index = t.index, n.input = t.input), n;
}
var he = G.Uint8Array;
function bn(t) {
  var e = new t.constructor(t.byteLength);
  return new he(e).set(new he(t)), e;
}
function oc(t, e) {
  var n = e ? bn(t.buffer) : t.buffer;
  return new t.constructor(n, t.byteOffset, t.byteLength);
}
var sc = /\w*$/;
function uc(t) {
  var e = new t.constructor(t.source, sc.exec(t));
  return e.lastIndex = t.lastIndex, e;
}
var ir = C ? C.prototype : void 0,
  ar = ir ? ir.valueOf : void 0;
function cc(t) {
  return ar ? Object(ar.call(t)) : {};
}
function li(t, e) {
  var n = e ? bn(t.buffer) : t.buffer;
  return new t.constructor(n, t.byteOffset, t.length);
}
var fc = "[object Boolean]",
  lc = "[object Date]",
  hc = "[object Map]",
  dc = "[object Number]",
  gc = "[object RegExp]",
  mc = "[object Set]",
  pc = "[object String]",
  yc = "[object Symbol]",
  wc = "[object ArrayBuffer]",
  bc = "[object DataView]",
  _c = "[object Float32Array]",
  vc = "[object Float64Array]",
  xc = "[object Int8Array]",
  Tc = "[object Int16Array]",
  Mc = "[object Int32Array]",
  Sc = "[object Uint8Array]",
  Ac = "[object Uint8ClampedArray]",
  Oc = "[object Uint16Array]",
  Pc = "[object Uint32Array]";
function $c(t, e, n) {
  var r = t.constructor;
  switch (e) {
    case wc:
      return bn(t);
    case fc:
    case lc:
      return new r(+t);
    case bc:
      return oc(t, n);
    case _c:
    case vc:
    case xc:
    case Tc:
    case Mc:
    case Sc:
    case Ac:
    case Oc:
    case Pc:
      return li(t, n);
    case hc:
      return new r();
    case dc:
    case pc:
      return new r(t);
    case gc:
      return uc(t);
    case mc:
      return new r();
    case yc:
      return cc(t);
  }
}
function hi(t) {
  return typeof t.constructor == "function" && !Ae(t) ? Io(yn(t)) : {};
}
var Ec = "[object Map]";
function Nc(t) {
  return H(t) && N(t) == Ec;
}
var or = bt && bt.isMap,
  Cc = or ? dn(or) : Nc,
  Dc = "[object Set]";
function Ic(t) {
  return H(t) && N(t) == Dc;
}
var sr = bt && bt.isSet,
  Rc = sr ? dn(sr) : Ic,
  Fc = 1,
  Lc = 2,
  kc = 4,
  di = "[object Arguments]",
  Wc = "[object Array]",
  Yc = "[object Boolean]",
  Hc = "[object Date]",
  Gc = "[object Error]",
  gi = "[object Function]",
  Bc = "[object GeneratorFunction]",
  jc = "[object Map]",
  qc = "[object Number]",
  mi = "[object Object]",
  zc = "[object RegExp]",
  Xc = "[object Set]",
  Uc = "[object String]",
  Vc = "[object Symbol]",
  Qc = "[object WeakMap]",
  Kc = "[object ArrayBuffer]",
  Zc = "[object DataView]",
  Jc = "[object Float32Array]",
  tf = "[object Float64Array]",
  ef = "[object Int8Array]",
  nf = "[object Int16Array]",
  rf = "[object Int32Array]",
  af = "[object Uint8Array]",
  of = "[object Uint8ClampedArray]",
  sf = "[object Uint16Array]",
  uf = "[object Uint32Array]",
  T = {};
T[di] = T[Wc] = T[Kc] = T[Zc] = T[Yc] = T[Hc] = T[Jc] = T[tf] = T[ef] = T[nf] = T[rf] = T[jc] = T[qc] = T[mi] = T[zc] = T[Xc] = T[Uc] = T[Vc] = T[af] = T[of] = T[sf] = T[uf] = !0;
T[Gc] = T[gi] = T[Qc] = !1;
function oe(t, e, n, r, i, a) {
  var o,
    s = e & Fc,
    u = e & Lc,
    c = e & kc;
  if (n && (o = i ? n(t, r, i, a) : n(t)), o !== void 0) return o;
  if (!X(t)) return t;
  var f = $(t);
  if (f) {
    if (o = ac(t), !s) return Xr(t, o);
  } else {
    var l = N(t),
      h = l == gi || l == Bc;
    if (wt(t)) return si(t, s);
    if (l == mi || l == di || h && !i) {
      if (o = u || h ? {} : hi(t), !s) return u ? Vu(t, Gu(o, t)) : Xu(t, Hu(o, t));
    } else {
      if (!T[l]) return i ? t : {};
      o = $c(t, l, s);
    }
  }
  a || (a = new R());
  var d = a.get(t);
  if (d) return d;
  a.set(t, o), Rc(t) ? t.forEach(function (p) {
    o.add(oe(p, e, n, p, t, a));
  }) : Cc(t) && t.forEach(function (p, v) {
    o.set(v, oe(p, e, n, v, t, a));
  });
  var g = c ? u ? Qu : je : u ? jt : Pe,
    m = f ? void 0 : g(t);
  return jo(m || t, function (p, v) {
    m && (v = p, p = t[v]), Ur(o, v, oe(p, e, n, v, t, a));
  }), o;
}
var cf = 1,
  ff = 4;
function lf(t) {
  return oe(t, cf | ff);
}
var hf = "__lodash_hash_undefined__";
function df(t) {
  return this.__data__.set(t, hf), this;
}
function gf(t) {
  return this.__data__.has(t);
}
function Lt(t) {
  var e = -1,
    n = t == null ? 0 : t.length;
  for (this.__data__ = new V(); ++e < n;) this.add(t[e]);
}
Lt.prototype.add = Lt.prototype.push = df;
Lt.prototype.has = gf;
function mf(t, e) {
  for (var n = -1, r = t == null ? 0 : t.length; ++n < r;) if (e(t[n], n, t)) return !0;
  return !1;
}
function pi(t, e) {
  return t.has(e);
}
var pf = 1,
  yf = 2;
function yi(t, e, n, r, i, a) {
  var o = n & pf,
    s = t.length,
    u = e.length;
  if (s != u && !(o && u > s)) return !1;
  var c = a.get(t),
    f = a.get(e);
  if (c && f) return c == e && f == t;
  var l = -1,
    h = !0,
    d = n & yf ? new Lt() : void 0;
  for (a.set(t, e), a.set(e, t); ++l < s;) {
    var g = t[l],
      m = e[l];
    if (r) var p = o ? r(m, g, l, e, t, a) : r(g, m, l, t, e, a);
    if (p !== void 0) {
      if (p) continue;
      h = !1;
      break;
    }
    if (d) {
      if (!mf(e, function (v, w) {
        if (!pi(d, w) && (g === v || i(g, v, n, r, a))) return d.push(w);
      })) {
        h = !1;
        break;
      }
    } else if (!(g === m || i(g, m, n, r, a))) {
      h = !1;
      break;
    }
  }
  return a.delete(t), a.delete(e), h;
}
function wf(t) {
  var e = -1,
    n = Array(t.size);
  return t.forEach(function (r, i) {
    n[++e] = [i, r];
  }), n;
}
function _n(t) {
  var e = -1,
    n = Array(t.size);
  return t.forEach(function (r) {
    n[++e] = r;
  }), n;
}
var bf = 1,
  _f = 2,
  vf = "[object Boolean]",
  xf = "[object Date]",
  Tf = "[object Error]",
  Mf = "[object Map]",
  Sf = "[object Number]",
  Af = "[object RegExp]",
  Of = "[object Set]",
  Pf = "[object String]",
  $f = "[object Symbol]",
  Ef = "[object ArrayBuffer]",
  Nf = "[object DataView]",
  ur = C ? C.prototype : void 0,
  ke = ur ? ur.valueOf : void 0;
function Cf(t, e, n, r, i, a, o) {
  switch (n) {
    case Nf:
      if (t.byteLength != e.byteLength || t.byteOffset != e.byteOffset) return !1;
      t = t.buffer, e = e.buffer;
    case Ef:
      return !(t.byteLength != e.byteLength || !a(new he(t), new he(e)));
    case vf:
    case xf:
    case Sf:
      return Ht(+t, +e);
    case Tf:
      return t.name == e.name && t.message == e.message;
    case Af:
    case Pf:
      return t == e + "";
    case Mf:
      var s = wf;
    case Of:
      var u = r & bf;
      if (s || (s = _n), t.size != e.size && !u) return !1;
      var c = o.get(t);
      if (c) return c == e;
      r |= _f, o.set(t, e);
      var f = yi(s(t), s(e), r, i, a, o);
      return o.delete(t), f;
    case $f:
      if (ke) return ke.call(t) == ke.call(e);
  }
  return !1;
}
var Df = 1,
  If = Object.prototype,
  Rf = If.hasOwnProperty;
function Ff(t, e, n, r, i, a) {
  var o = n & Df,
    s = je(t),
    u = s.length,
    c = je(e),
    f = c.length;
  if (u != f && !o) return !1;
  for (var l = u; l--;) {
    var h = s[l];
    if (!(o ? h in e : Rf.call(e, h))) return !1;
  }
  var d = a.get(t),
    g = a.get(e);
  if (d && g) return d == e && g == t;
  var m = !0;
  a.set(t, e), a.set(e, t);
  for (var p = o; ++l < u;) {
    h = s[l];
    var v = t[h],
      w = e[h];
    if (r) var _ = o ? r(w, v, h, e, t, a) : r(v, w, h, t, e, a);
    if (!(_ === void 0 ? v === w || i(v, w, n, r, a) : _)) {
      m = !1;
      break;
    }
    p || (p = h == "constructor");
  }
  if (m && !p) {
    var A = t.constructor,
      S = e.constructor;
    A != S && "constructor" in t && "constructor" in e && !(typeof A == "function" && A instanceof A && typeof S == "function" && S instanceof S) && (m = !1);
  }
  return a.delete(t), a.delete(e), m;
}
var Lf = 1,
  cr = "[object Arguments]",
  fr = "[object Array]",
  Jt = "[object Object]",
  kf = Object.prototype,
  lr = kf.hasOwnProperty;
function Wf(t, e, n, r, i, a) {
  var o = $(t),
    s = $(e),
    u = o ? fr : N(t),
    c = s ? fr : N(e);
  u = u == cr ? Jt : u, c = c == cr ? Jt : c;
  var f = u == Jt,
    l = c == Jt,
    h = u == c;
  if (h && wt(t)) {
    if (!wt(e)) return !1;
    o = !0, f = !1;
  }
  if (h && !f) return a || (a = new R()), o || Oe(t) ? yi(t, e, n, r, i, a) : Cf(t, e, u, n, r, i, a);
  if (!(n & Lf)) {
    var d = f && lr.call(t, "__wrapped__"),
      g = l && lr.call(e, "__wrapped__");
    if (d || g) {
      var m = d ? t.value() : t,
        p = g ? e.value() : e;
      return a || (a = new R()), i(m, p, n, r, a);
    }
  }
  return h ? (a || (a = new R()), Ff(t, e, n, r, i, a)) : !1;
}
function vn(t, e, n, r, i) {
  return t === e ? !0 : t == null || e == null || !H(t) && !H(e) ? t !== t && e !== e : Wf(t, e, n, r, vn, i);
}
var Yf = 1,
  Hf = 2;
function Gf(t, e, n, r) {
  var i = n.length,
    a = i;
  if (t == null) return !a;
  for (t = Object(t); i--;) {
    var o = n[i];
    if (o[2] ? o[1] !== t[o[0]] : !(o[0] in t)) return !1;
  }
  for (; ++i < a;) {
    o = n[i];
    var s = o[0],
      u = t[s],
      c = o[1];
    if (o[2]) {
      if (u === void 0 && !(s in t)) return !1;
    } else {
      var f = new R(),
        l;
      if (!(l === void 0 ? vn(c, u, Yf | Hf, r, f) : l)) return !1;
    }
  }
  return !0;
}
function wi(t) {
  return t === t && !X(t);
}
function Bf(t) {
  for (var e = Pe(t), n = e.length; n--;) {
    var r = e[n],
      i = t[r];
    e[n] = [r, i, wi(i)];
  }
  return e;
}
function bi(t, e) {
  return function (n) {
    return n == null ? !1 : n[t] === e && (e !== void 0 || t in Object(n));
  };
}
function jf(t) {
  var e = Bf(t);
  return e.length == 1 && e[0][2] ? bi(e[0][0], e[0][1]) : function (n) {
    return n === t || Gf(n, t, e);
  };
}
function qf(t, e) {
  return t != null && e in Object(t);
}
function zf(t, e, n) {
  e = ni(e, t);
  for (var r = -1, i = e.length, a = !1; ++r < i;) {
    var o = Ne(e[r]);
    if (!(a = t != null && n(t, o))) break;
    t = t[o];
  }
  return a || ++r != i ? a : (i = t == null ? 0 : t.length, !!i && hn(i) && fn(o, i) && ($(t) || yt(t)));
}
function Xf(t, e) {
  return t != null && zf(t, e, qf);
}
var Uf = 1,
  Vf = 2;
function Qf(t, e) {
  return gn(t) && wi(e) ? bi(Ne(t), e) : function (n) {
    var r = Ou(n, t);
    return r === void 0 && r === e ? Xf(n, t) : vn(e, r, Uf | Vf);
  };
}
function Kf(t) {
  return function (e) {
    return e == null ? void 0 : e[t];
  };
}
function Zf(t) {
  return function (e) {
    return ri(e, t);
  };
}
function Jf(t) {
  return gn(t) ? Kf(Ne(t)) : Zf(t);
}
function tl(t) {
  return typeof t == "function" ? t : t == null ? un : typeof t == "object" ? $(t) ? Qf(t[0], t[1]) : jf(t) : Jf(t);
}
function el(t) {
  return function (e, n, r) {
    for (var i = -1, a = Object(e), o = r(e), s = o.length; s--;) {
      var u = o[++i];
      if (n(a[u], u, a) === !1) break;
    }
    return e;
  };
}
var nl = el();
function Xe(t, e, n) {
  (n !== void 0 && !Ht(t[e], n) || n === void 0 && !(e in t)) && ln(t, e, n);
}
function Ue(t) {
  return H(t) && Bt(t);
}
function Ve(t, e) {
  if (!(e === "constructor" && typeof t[e] == "function") && e != "__proto__") return t[e];
}
function rl(t) {
  return Gt(t, jt(t));
}
function il(t, e, n, r, i, a, o) {
  var s = Ve(t, n),
    u = Ve(e, n),
    c = o.get(u);
  if (c) {
    Xe(t, n, c);
    return;
  }
  var f = a ? a(s, u, n + "", t, e, o) : void 0,
    l = f === void 0;
  if (l) {
    var h = $(u),
      d = !h && wt(u),
      g = !h && !d && Oe(u);
    f = u, h || d || g ? $(s) ? f = s : Ue(s) ? f = Xr(s) : d ? (l = !1, f = si(u, !0)) : g ? (l = !1, f = li(u, !0)) : f = [] : Iu(u) || yt(u) ? (f = s, yt(s) ? f = rl(s) : (!X(s) || cn(s)) && (f = hi(u))) : l = !1;
  }
  l && (o.set(u, f), i(f, u, r, a, o), o.delete(u)), Xe(t, n, f);
}
function _i(t, e, n, r, i) {
  t !== e && nl(e, function (a, o) {
    if (i || (i = new R()), X(a)) il(t, e, o, n, _i, r, i);else {
      var s = r ? r(Ve(t, o), a, o + "", t, e, i) : void 0;
      s === void 0 && (s = a), Xe(t, o, s);
    }
  }, jt);
}
function al(t) {
  var e = t == null ? 0 : t.length;
  return e ? t[e - 1] : void 0;
}
var ol = "[object Map]",
  sl = "[object Set]",
  ul = Object.prototype,
  cl = ul.hasOwnProperty;
function fl(t) {
  if (t == null) return !0;
  if (Bt(t) && ($(t) || typeof t == "string" || typeof t.splice == "function" || wt(t) || Oe(t) || yt(t))) return !t.length;
  var e = N(t);
  if (e == ol || e == sl) return !t.size;
  if (Ae(t)) return !ei(t).length;
  for (var n in t) if (cl.call(t, n)) return !1;
  return !0;
}
var y = rs(function (t, e, n) {
    _i(t, e, n);
  }),
  ll = 1 / 0,
  hl = mt && 1 / _n(new mt([, -0]))[1] == ll ? function (t) {
    return new mt(t);
  } : Fo,
  dl = 200;
function vi(t, e, n) {
  var r = -1,
    i = Vo,
    a = t.length,
    o = !0,
    s = [],
    u = s;
  if (a >= dl) {
    var c = e ? null : hl(t);
    if (c) return _n(c);
    o = !1, i = pi, u = new Lt();
  } else u = e ? [] : s;
  t: for (; ++r < a;) {
    var f = t[r],
      l = e ? e(f) : f;
    if (f = f !== 0 ? f : 0, o && l === l) {
      for (var h = u.length; h--;) if (u[h] === l) continue t;
      e && u.push(l), s.push(f);
    } else i(u, l, n) || (u !== s && u.push(l), s.push(f));
  }
  return s;
}
var gl = Vr(function (t) {
  var e = al(t);
  return Ue(e) && (e = void 0), vi(ii(t, 1, Ue, !0), tl(e));
});
function Wp(t) {
  return t && t.length ? vi(t) : [];
}
function se(t, e) {
  return t == null || e == null ? NaN : t < e ? -1 : t > e ? 1 : t >= e ? 0 : NaN;
}
function ml(t, e) {
  return t == null || e == null ? NaN : e < t ? -1 : e > t ? 1 : e >= t ? 0 : NaN;
}
function xi(t) {
  let e, n, r;
  t.length !== 2 ? (e = se, n = (s, u) => se(t(s), u), r = (s, u) => t(s) - u) : (e = t === se || t === ml ? t : pl, n = t, r = t);
  function i(s, u, c = 0, f = s.length) {
    if (c < f) {
      if (e(u, u) !== 0) return f;
      do {
        const l = c + f >>> 1;
        n(s[l], u) < 0 ? c = l + 1 : f = l;
      } while (c < f);
    }
    return c;
  }
  function a(s, u, c = 0, f = s.length) {
    if (c < f) {
      if (e(u, u) !== 0) return f;
      do {
        const l = c + f >>> 1;
        n(s[l], u) <= 0 ? c = l + 1 : f = l;
      } while (c < f);
    }
    return c;
  }
  function o(s, u, c = 0, f = s.length) {
    const l = i(s, u, c, f - 1);
    return l > c && r(s[l - 1], u) > -r(s[l], u) ? l - 1 : l;
  }
  return {
    left: i,
    center: o,
    right: a
  };
}
function pl() {
  return 0;
}
function yl(t) {
  return t === null ? NaN : +t;
}
function* Yp(t, e) {
  for (let n of t) n != null && (n = +n) >= n && (yield n);
}
const wl = xi(se),
  Ti = wl.right;
xi(yl).center;
function bl(t, e) {
  let n, r;
  if (e === void 0) for (const i of t) i != null && (n === void 0 ? i >= i && (n = r = i) : (n > i && (n = i), r < i && (r = i)));else {
    let i = -1;
    for (let a of t) (a = e(a, ++i, t)) != null && (n === void 0 ? a >= a && (n = r = a) : (n > a && (n = a), r < a && (r = a)));
  }
  return [n, r];
}
class hr extends Map {
  constructor(e, n = xl) {
    if (super(), Object.defineProperties(this, {
      _intern: {
        value: /* @__PURE__ */new Map()
      },
      _key: {
        value: n
      }
    }), e != null) for (const [r, i] of e) this.set(r, i);
  }
  get(e) {
    return super.get(dr(this, e));
  }
  has(e) {
    return super.has(dr(this, e));
  }
  set(e, n) {
    return super.set(_l(this, e), n);
  }
  delete(e) {
    return super.delete(vl(this, e));
  }
}
function dr({
  _intern: t,
  _key: e
}, n) {
  const r = e(n);
  return t.has(r) ? t.get(r) : n;
}
function _l({
  _intern: t,
  _key: e
}, n) {
  const r = e(n);
  return t.has(r) ? t.get(r) : (t.set(r, n), n);
}
function vl({
  _intern: t,
  _key: e
}, n) {
  const r = e(n);
  return t.has(r) && (n = t.get(r), t.delete(r)), n;
}
function xl(t) {
  return t !== null && typeof t == "object" ? t.valueOf() : t;
}
const Tl = Math.sqrt(50),
  Ml = Math.sqrt(10),
  Sl = Math.sqrt(2);
function de(t, e, n) {
  const r = (e - t) / Math.max(0, n),
    i = Math.floor(Math.log10(r)),
    a = r / Math.pow(10, i),
    o = a >= Tl ? 10 : a >= Ml ? 5 : a >= Sl ? 2 : 1;
  let s, u, c;
  return i < 0 ? (c = Math.pow(10, -i) / o, s = Math.round(t * c), u = Math.round(e * c), s / c < t && ++s, u / c > e && --u, c = -c) : (c = Math.pow(10, i) * o, s = Math.round(t / c), u = Math.round(e / c), s * c < t && ++s, u * c > e && --u), u < s && 0.5 <= n && n < 2 ? de(t, e, n * 2) : [s, u, c];
}
function Al(t, e, n) {
  if (e = +e, t = +t, n = +n, !(n > 0)) return [];
  if (t === e) return [t];
  const r = e < t,
    [i, a, o] = r ? de(e, t, n) : de(t, e, n);
  if (!(a >= i)) return [];
  const s = a - i + 1,
    u = new Array(s);
  if (r) {
    if (o < 0) for (let c = 0; c < s; ++c) u[c] = (a - c) / -o;else for (let c = 0; c < s; ++c) u[c] = (a - c) * o;
  } else if (o < 0) for (let c = 0; c < s; ++c) u[c] = (i + c) / -o;else for (let c = 0; c < s; ++c) u[c] = (i + c) * o;
  return u;
}
function Qe(t, e, n) {
  return e = +e, t = +t, n = +n, de(t, e, n)[2];
}
function Ol(t, e, n) {
  e = +e, t = +t, n = +n;
  const r = e < t,
    i = r ? Qe(e, t, n) : Qe(t, e, n);
  return (r ? -1 : 1) * (i < 0 ? 1 / -i : i);
}
function Hp(t, e) {
  let n;
  if (e === void 0) for (const r of t) r != null && (n < r || n === void 0 && r >= r) && (n = r);else {
    let r = -1;
    for (let i of t) (i = e(i, ++r, t)) != null && (n < i || n === void 0 && i >= i) && (n = i);
  }
  return n;
}
function Pl(t, e) {
  let n;
  for (const r of t) r != null && (n > r || n === void 0 && r >= r) && (n = r);
  return n;
}
var $l = {
  value: () => {}
};
function Mi() {
  for (var t = 0, e = arguments.length, n = {}, r; t < e; ++t) {
    if (!(r = arguments[t] + "") || r in n || /[\s.]/.test(r)) throw new Error("illegal type: " + r);
    n[r] = [];
  }
  return new ue(n);
}
function ue(t) {
  this._ = t;
}
function El(t, e) {
  return t.trim().split(/^|\s+/).map(function (n) {
    var r = "",
      i = n.indexOf(".");
    if (i >= 0 && (r = n.slice(i + 1), n = n.slice(0, i)), n && !e.hasOwnProperty(n)) throw new Error("unknown type: " + n);
    return {
      type: n,
      name: r
    };
  });
}
ue.prototype = Mi.prototype = {
  constructor: ue,
  on: function (t, e) {
    var n = this._,
      r = El(t + "", n),
      i,
      a = -1,
      o = r.length;
    if (arguments.length < 2) {
      for (; ++a < o;) if ((i = (t = r[a]).type) && (i = Nl(n[i], t.name))) return i;
      return;
    }
    if (e != null && typeof e != "function") throw new Error("invalid callback: " + e);
    for (; ++a < o;) if (i = (t = r[a]).type) n[i] = gr(n[i], t.name, e);else if (e == null) for (i in n) n[i] = gr(n[i], t.name, null);
    return this;
  },
  copy: function () {
    var t = {},
      e = this._;
    for (var n in e) t[n] = e[n].slice();
    return new ue(t);
  },
  call: function (t, e) {
    if ((i = arguments.length - 2) > 0) for (var n = new Array(i), r = 0, i, a; r < i; ++r) n[r] = arguments[r + 2];
    if (!this._.hasOwnProperty(t)) throw new Error("unknown type: " + t);
    for (a = this._[t], r = 0, i = a.length; r < i; ++r) a[r].value.apply(e, n);
  },
  apply: function (t, e, n) {
    if (!this._.hasOwnProperty(t)) throw new Error("unknown type: " + t);
    for (var r = this._[t], i = 0, a = r.length; i < a; ++i) r[i].value.apply(e, n);
  }
};
function Nl(t, e) {
  for (var n = 0, r = t.length, i; n < r; ++n) if ((i = t[n]).name === e) return i.value;
}
function gr(t, e, n) {
  for (var r = 0, i = t.length; r < i; ++r) if (t[r].name === e) {
    t[r] = $l, t = t.slice(0, r).concat(t.slice(r + 1));
    break;
  }
  return n != null && t.push({
    name: e,
    value: n
  }), t;
}
var Ke = "http://www.w3.org/1999/xhtml";
const mr = {
  svg: "http://www.w3.org/2000/svg",
  xhtml: Ke,
  xlink: "http://www.w3.org/1999/xlink",
  xml: "http://www.w3.org/XML/1998/namespace",
  xmlns: "http://www.w3.org/2000/xmlns/"
};
function Ce(t) {
  var e = t += "",
    n = e.indexOf(":");
  return n >= 0 && (e = t.slice(0, n)) !== "xmlns" && (t = t.slice(n + 1)), mr.hasOwnProperty(e) ? {
    space: mr[e],
    local: t
  } : t;
}
function Cl(t) {
  return function () {
    var e = this.ownerDocument,
      n = this.namespaceURI;
    return n === Ke && e.documentElement.namespaceURI === Ke ? e.createElement(t) : e.createElementNS(n, t);
  };
}
function Dl(t) {
  return function () {
    return this.ownerDocument.createElementNS(t.space, t.local);
  };
}
function Si(t) {
  var e = Ce(t);
  return (e.local ? Dl : Cl)(e);
}
function Il() {}
function xn(t) {
  return t == null ? Il : function () {
    return this.querySelector(t);
  };
}
function Rl(t) {
  typeof t != "function" && (t = xn(t));
  for (var e = this._groups, n = e.length, r = new Array(n), i = 0; i < n; ++i) for (var a = e[i], o = a.length, s = r[i] = new Array(o), u, c, f = 0; f < o; ++f) (u = a[f]) && (c = t.call(u, u.__data__, f, a)) && ("__data__" in u && (c.__data__ = u.__data__), s[f] = c);
  return new F(r, this._parents);
}
function Fl(t) {
  return t == null ? [] : Array.isArray(t) ? t : Array.from(t);
}
function Ll() {
  return [];
}
function Ai(t) {
  return t == null ? Ll : function () {
    return this.querySelectorAll(t);
  };
}
function kl(t) {
  return function () {
    return Fl(t.apply(this, arguments));
  };
}
function Wl(t) {
  typeof t == "function" ? t = kl(t) : t = Ai(t);
  for (var e = this._groups, n = e.length, r = [], i = [], a = 0; a < n; ++a) for (var o = e[a], s = o.length, u, c = 0; c < s; ++c) (u = o[c]) && (r.push(t.call(u, u.__data__, c, o)), i.push(u));
  return new F(r, i);
}
function Oi(t) {
  return function () {
    return this.matches(t);
  };
}
function Pi(t) {
  return function (e) {
    return e.matches(t);
  };
}
var Yl = Array.prototype.find;
function Hl(t) {
  return function () {
    return Yl.call(this.children, t);
  };
}
function Gl() {
  return this.firstElementChild;
}
function Bl(t) {
  return this.select(t == null ? Gl : Hl(typeof t == "function" ? t : Pi(t)));
}
var jl = Array.prototype.filter;
function ql() {
  return Array.from(this.children);
}
function zl(t) {
  return function () {
    return jl.call(this.children, t);
  };
}
function Xl(t) {
  return this.selectAll(t == null ? ql : zl(typeof t == "function" ? t : Pi(t)));
}
function Ul(t) {
  typeof t != "function" && (t = Oi(t));
  for (var e = this._groups, n = e.length, r = new Array(n), i = 0; i < n; ++i) for (var a = e[i], o = a.length, s = r[i] = [], u, c = 0; c < o; ++c) (u = a[c]) && t.call(u, u.__data__, c, a) && s.push(u);
  return new F(r, this._parents);
}
function $i(t) {
  return new Array(t.length);
}
function Vl() {
  return new F(this._enter || this._groups.map($i), this._parents);
}
function ge(t, e) {
  this.ownerDocument = t.ownerDocument, this.namespaceURI = t.namespaceURI, this._next = null, this._parent = t, this.__data__ = e;
}
ge.prototype = {
  constructor: ge,
  appendChild: function (t) {
    return this._parent.insertBefore(t, this._next);
  },
  insertBefore: function (t, e) {
    return this._parent.insertBefore(t, e);
  },
  querySelector: function (t) {
    return this._parent.querySelector(t);
  },
  querySelectorAll: function (t) {
    return this._parent.querySelectorAll(t);
  }
};
function Ql(t) {
  return function () {
    return t;
  };
}
function Kl(t, e, n, r, i, a) {
  for (var o = 0, s, u = e.length, c = a.length; o < c; ++o) (s = e[o]) ? (s.__data__ = a[o], r[o] = s) : n[o] = new ge(t, a[o]);
  for (; o < u; ++o) (s = e[o]) && (i[o] = s);
}
function Zl(t, e, n, r, i, a, o) {
  var s,
    u,
    c = /* @__PURE__ */new Map(),
    f = e.length,
    l = a.length,
    h = new Array(f),
    d;
  for (s = 0; s < f; ++s) (u = e[s]) && (h[s] = d = o.call(u, u.__data__, s, e) + "", c.has(d) ? i[s] = u : c.set(d, u));
  for (s = 0; s < l; ++s) d = o.call(t, a[s], s, a) + "", (u = c.get(d)) ? (r[s] = u, u.__data__ = a[s], c.delete(d)) : n[s] = new ge(t, a[s]);
  for (s = 0; s < f; ++s) (u = e[s]) && c.get(h[s]) === u && (i[s] = u);
}
function Jl(t) {
  return t.__data__;
}
function th(t, e) {
  if (!arguments.length) return Array.from(this, Jl);
  var n = e ? Zl : Kl,
    r = this._parents,
    i = this._groups;
  typeof t != "function" && (t = Ql(t));
  for (var a = i.length, o = new Array(a), s = new Array(a), u = new Array(a), c = 0; c < a; ++c) {
    var f = r[c],
      l = i[c],
      h = l.length,
      d = eh(t.call(f, f && f.__data__, c, r)),
      g = d.length,
      m = s[c] = new Array(g),
      p = o[c] = new Array(g),
      v = u[c] = new Array(h);
    n(f, l, m, p, v, d, e);
    for (var w = 0, _ = 0, A, S; w < g; ++w) if (A = m[w]) {
      for (w >= _ && (_ = w + 1); !(S = p[_]) && ++_ < g;);
      A._next = S || null;
    }
  }
  return o = new F(o, r), o._enter = s, o._exit = u, o;
}
function eh(t) {
  return typeof t == "object" && "length" in t ? t : Array.from(t);
}
function nh() {
  return new F(this._exit || this._groups.map($i), this._parents);
}
function rh(t, e, n) {
  var r = this.enter(),
    i = this,
    a = this.exit();
  return typeof t == "function" ? (r = t(r), r && (r = r.selection())) : r = r.append(t + ""), e != null && (i = e(i), i && (i = i.selection())), n == null ? a.remove() : n(a), r && i ? r.merge(i).order() : i;
}
function ih(t) {
  for (var e = t.selection ? t.selection() : t, n = this._groups, r = e._groups, i = n.length, a = r.length, o = Math.min(i, a), s = new Array(i), u = 0; u < o; ++u) for (var c = n[u], f = r[u], l = c.length, h = s[u] = new Array(l), d, g = 0; g < l; ++g) (d = c[g] || f[g]) && (h[g] = d);
  for (; u < i; ++u) s[u] = n[u];
  return new F(s, this._parents);
}
function ah() {
  for (var t = this._groups, e = -1, n = t.length; ++e < n;) for (var r = t[e], i = r.length - 1, a = r[i], o; --i >= 0;) (o = r[i]) && (a && o.compareDocumentPosition(a) ^ 4 && a.parentNode.insertBefore(o, a), a = o);
  return this;
}
function oh(t) {
  t || (t = sh);
  function e(l, h) {
    return l && h ? t(l.__data__, h.__data__) : !l - !h;
  }
  for (var n = this._groups, r = n.length, i = new Array(r), a = 0; a < r; ++a) {
    for (var o = n[a], s = o.length, u = i[a] = new Array(s), c, f = 0; f < s; ++f) (c = o[f]) && (u[f] = c);
    u.sort(e);
  }
  return new F(i, this._parents).order();
}
function sh(t, e) {
  return t < e ? -1 : t > e ? 1 : t >= e ? 0 : NaN;
}
function uh() {
  var t = arguments[0];
  return arguments[0] = this, t.apply(null, arguments), this;
}
function ch() {
  return Array.from(this);
}
function fh() {
  for (var t = this._groups, e = 0, n = t.length; e < n; ++e) for (var r = t[e], i = 0, a = r.length; i < a; ++i) {
    var o = r[i];
    if (o) return o;
  }
  return null;
}
function lh() {
  let t = 0;
  for (const e of this) ++t;
  return t;
}
function hh() {
  return !this.node();
}
function dh(t) {
  for (var e = this._groups, n = 0, r = e.length; n < r; ++n) for (var i = e[n], a = 0, o = i.length, s; a < o; ++a) (s = i[a]) && t.call(s, s.__data__, a, i);
  return this;
}
function gh(t) {
  return function () {
    this.removeAttribute(t);
  };
}
function mh(t) {
  return function () {
    this.removeAttributeNS(t.space, t.local);
  };
}
function ph(t, e) {
  return function () {
    this.setAttribute(t, e);
  };
}
function yh(t, e) {
  return function () {
    this.setAttributeNS(t.space, t.local, e);
  };
}
function wh(t, e) {
  return function () {
    var n = e.apply(this, arguments);
    n == null ? this.removeAttribute(t) : this.setAttribute(t, n);
  };
}
function bh(t, e) {
  return function () {
    var n = e.apply(this, arguments);
    n == null ? this.removeAttributeNS(t.space, t.local) : this.setAttributeNS(t.space, t.local, n);
  };
}
function _h(t, e) {
  var n = Ce(t);
  if (arguments.length < 2) {
    var r = this.node();
    return n.local ? r.getAttributeNS(n.space, n.local) : r.getAttribute(n);
  }
  return this.each((e == null ? n.local ? mh : gh : typeof e == "function" ? n.local ? bh : wh : n.local ? yh : ph)(n, e));
}
function Ei(t) {
  return t.ownerDocument && t.ownerDocument.defaultView || t.document && t || t.defaultView;
}
function vh(t) {
  return function () {
    this.style.removeProperty(t);
  };
}
function xh(t, e, n) {
  return function () {
    this.style.setProperty(t, e, n);
  };
}
function Th(t, e, n) {
  return function () {
    var r = e.apply(this, arguments);
    r == null ? this.style.removeProperty(t) : this.style.setProperty(t, r, n);
  };
}
function Mh(t, e, n) {
  return arguments.length > 1 ? this.each((e == null ? vh : typeof e == "function" ? Th : xh)(t, e, n ?? "")) : _t(this.node(), t);
}
function _t(t, e) {
  return t.style.getPropertyValue(e) || Ei(t).getComputedStyle(t, null).getPropertyValue(e);
}
function Sh(t) {
  return function () {
    delete this[t];
  };
}
function Ah(t, e) {
  return function () {
    this[t] = e;
  };
}
function Oh(t, e) {
  return function () {
    var n = e.apply(this, arguments);
    n == null ? delete this[t] : this[t] = n;
  };
}
function Ph(t, e) {
  return arguments.length > 1 ? this.each((e == null ? Sh : typeof e == "function" ? Oh : Ah)(t, e)) : this.node()[t];
}
function Ni(t) {
  return t.trim().split(/^|\s+/);
}
function Tn(t) {
  return t.classList || new Ci(t);
}
function Ci(t) {
  this._node = t, this._names = Ni(t.getAttribute("class") || "");
}
Ci.prototype = {
  add: function (t) {
    var e = this._names.indexOf(t);
    e < 0 && (this._names.push(t), this._node.setAttribute("class", this._names.join(" ")));
  },
  remove: function (t) {
    var e = this._names.indexOf(t);
    e >= 0 && (this._names.splice(e, 1), this._node.setAttribute("class", this._names.join(" ")));
  },
  contains: function (t) {
    return this._names.indexOf(t) >= 0;
  }
};
function Di(t, e) {
  for (var n = Tn(t), r = -1, i = e.length; ++r < i;) n.add(e[r]);
}
function Ii(t, e) {
  for (var n = Tn(t), r = -1, i = e.length; ++r < i;) n.remove(e[r]);
}
function $h(t) {
  return function () {
    Di(this, t);
  };
}
function Eh(t) {
  return function () {
    Ii(this, t);
  };
}
function Nh(t, e) {
  return function () {
    (e.apply(this, arguments) ? Di : Ii)(this, t);
  };
}
function Ch(t, e) {
  var n = Ni(t + "");
  if (arguments.length < 2) {
    for (var r = Tn(this.node()), i = -1, a = n.length; ++i < a;) if (!r.contains(n[i])) return !1;
    return !0;
  }
  return this.each((typeof e == "function" ? Nh : e ? $h : Eh)(n, e));
}
function Dh() {
  this.textContent = "";
}
function Ih(t) {
  return function () {
    this.textContent = t;
  };
}
function Rh(t) {
  return function () {
    var e = t.apply(this, arguments);
    this.textContent = e ?? "";
  };
}
function Fh(t) {
  return arguments.length ? this.each(t == null ? Dh : (typeof t == "function" ? Rh : Ih)(t)) : this.node().textContent;
}
function Lh() {
  this.innerHTML = "";
}
function kh(t) {
  return function () {
    this.innerHTML = t;
  };
}
function Wh(t) {
  return function () {
    var e = t.apply(this, arguments);
    this.innerHTML = e ?? "";
  };
}
function Yh(t) {
  return arguments.length ? this.each(t == null ? Lh : (typeof t == "function" ? Wh : kh)(t)) : this.node().innerHTML;
}
function Hh() {
  this.nextSibling && this.parentNode.appendChild(this);
}
function Gh() {
  return this.each(Hh);
}
function Bh() {
  this.previousSibling && this.parentNode.insertBefore(this, this.parentNode.firstChild);
}
function jh() {
  return this.each(Bh);
}
function qh(t) {
  var e = typeof t == "function" ? t : Si(t);
  return this.select(function () {
    return this.appendChild(e.apply(this, arguments));
  });
}
function zh() {
  return null;
}
function Xh(t, e) {
  var n = typeof t == "function" ? t : Si(t),
    r = e == null ? zh : typeof e == "function" ? e : xn(e);
  return this.select(function () {
    return this.insertBefore(n.apply(this, arguments), r.apply(this, arguments) || null);
  });
}
function Uh() {
  var t = this.parentNode;
  t && t.removeChild(this);
}
function Vh() {
  return this.each(Uh);
}
function Qh() {
  var t = this.cloneNode(!1),
    e = this.parentNode;
  return e ? e.insertBefore(t, this.nextSibling) : t;
}
function Kh() {
  var t = this.cloneNode(!0),
    e = this.parentNode;
  return e ? e.insertBefore(t, this.nextSibling) : t;
}
function Zh(t) {
  return this.select(t ? Kh : Qh);
}
function Jh(t) {
  return arguments.length ? this.property("__data__", t) : this.node().__data__;
}
function td(t) {
  return function (e) {
    t.call(this, e, this.__data__);
  };
}
function ed(t) {
  return t.trim().split(/^|\s+/).map(function (e) {
    var n = "",
      r = e.indexOf(".");
    return r >= 0 && (n = e.slice(r + 1), e = e.slice(0, r)), {
      type: e,
      name: n
    };
  });
}
function nd(t) {
  return function () {
    var e = this.__on;
    if (e) {
      for (var n = 0, r = -1, i = e.length, a; n < i; ++n) a = e[n], (!t.type || a.type === t.type) && a.name === t.name ? this.removeEventListener(a.type, a.listener, a.options) : e[++r] = a;
      ++r ? e.length = r : delete this.__on;
    }
  };
}
function rd(t, e, n) {
  return function () {
    var r = this.__on,
      i,
      a = td(e);
    if (r) {
      for (var o = 0, s = r.length; o < s; ++o) if ((i = r[o]).type === t.type && i.name === t.name) {
        this.removeEventListener(i.type, i.listener, i.options), this.addEventListener(i.type, i.listener = a, i.options = n), i.value = e;
        return;
      }
    }
    this.addEventListener(t.type, a, n), i = {
      type: t.type,
      name: t.name,
      value: e,
      listener: a,
      options: n
    }, r ? r.push(i) : this.__on = [i];
  };
}
function id(t, e, n) {
  var r = ed(t + ""),
    i,
    a = r.length,
    o;
  if (arguments.length < 2) {
    var s = this.node().__on;
    if (s) {
      for (var u = 0, c = s.length, f; u < c; ++u) for (i = 0, f = s[u]; i < a; ++i) if ((o = r[i]).type === f.type && o.name === f.name) return f.value;
    }
    return;
  }
  for (s = e ? rd : nd, i = 0; i < a; ++i) this.each(s(r[i], e, n));
  return this;
}
function Ri(t, e, n) {
  var r = Ei(t),
    i = r.CustomEvent;
  typeof i == "function" ? i = new i(e, n) : (i = r.document.createEvent("Event"), n ? (i.initEvent(e, n.bubbles, n.cancelable), i.detail = n.detail) : i.initEvent(e, !1, !1)), t.dispatchEvent(i);
}
function ad(t, e) {
  return function () {
    return Ri(this, t, e);
  };
}
function od(t, e) {
  return function () {
    return Ri(this, t, e.apply(this, arguments));
  };
}
function sd(t, e) {
  return this.each((typeof e == "function" ? od : ad)(t, e));
}
function* ud() {
  for (var t = this._groups, e = 0, n = t.length; e < n; ++e) for (var r = t[e], i = 0, a = r.length, o; i < a; ++i) (o = r[i]) && (yield o);
}
var cd = [null];
function F(t, e) {
  this._groups = t, this._parents = e;
}
function qt() {
  return new F([[document.documentElement]], cd);
}
function fd() {
  return this;
}
F.prototype = qt.prototype = {
  constructor: F,
  select: Rl,
  selectAll: Wl,
  selectChild: Bl,
  selectChildren: Xl,
  filter: Ul,
  data: th,
  enter: Vl,
  exit: nh,
  join: rh,
  merge: ih,
  selection: fd,
  order: ah,
  sort: oh,
  call: uh,
  nodes: ch,
  node: fh,
  size: lh,
  empty: hh,
  each: dh,
  attr: _h,
  style: Mh,
  property: Ph,
  classed: Ch,
  text: Fh,
  html: Yh,
  raise: Gh,
  lower: jh,
  append: qh,
  insert: Xh,
  remove: Vh,
  clone: Zh,
  datum: Jh,
  on: id,
  dispatch: sd,
  [Symbol.iterator]: ud
};
function ld(t) {
  let e;
  for (; e = t.sourceEvent;) t = e;
  return t;
}
function hd(t, e) {
  if (t = ld(t), e === void 0 && (e = t.currentTarget), e) {
    var n = e.ownerSVGElement || e;
    if (n.createSVGPoint) {
      var r = n.createSVGPoint();
      return r.x = t.clientX, r.y = t.clientY, r = r.matrixTransform(e.getScreenCTM().inverse()), [r.x, r.y];
    }
    if (e.getBoundingClientRect) {
      var i = e.getBoundingClientRect();
      return [t.clientX - i.left - e.clientLeft, t.clientY - i.top - e.clientTop];
    }
  }
  return [t.pageX, t.pageY];
}
function Mn(t, e, n) {
  t.prototype = e.prototype = n, n.constructor = t;
}
function Fi(t, e) {
  var n = Object.create(t.prototype);
  for (var r in e) n[r] = e[r];
  return n;
}
function zt() {}
var kt = 0.7,
  me = 1 / kt,
  pt = "\\s*([+-]?\\d+)\\s*",
  Wt = "\\s*([+-]?(?:\\d*\\.)?\\d+(?:[eE][+-]?\\d+)?)\\s*",
  Y = "\\s*([+-]?(?:\\d*\\.)?\\d+(?:[eE][+-]?\\d+)?)%\\s*",
  dd = /^#([0-9a-f]{3,8})$/,
  gd = new RegExp(`^rgb\\(${pt},${pt},${pt}\\)$`),
  md = new RegExp(`^rgb\\(${Y},${Y},${Y}\\)$`),
  pd = new RegExp(`^rgba\\(${pt},${pt},${pt},${Wt}\\)$`),
  yd = new RegExp(`^rgba\\(${Y},${Y},${Y},${Wt}\\)$`),
  wd = new RegExp(`^hsl\\(${Wt},${Y},${Y}\\)$`),
  bd = new RegExp(`^hsla\\(${Wt},${Y},${Y},${Wt}\\)$`),
  pr = {
    aliceblue: 15792383,
    antiquewhite: 16444375,
    aqua: 65535,
    aquamarine: 8388564,
    azure: 15794175,
    beige: 16119260,
    bisque: 16770244,
    black: 0,
    blanchedalmond: 16772045,
    blue: 255,
    blueviolet: 9055202,
    brown: 10824234,
    burlywood: 14596231,
    cadetblue: 6266528,
    chartreuse: 8388352,
    chocolate: 13789470,
    coral: 16744272,
    cornflowerblue: 6591981,
    cornsilk: 16775388,
    crimson: 14423100,
    cyan: 65535,
    darkblue: 139,
    darkcyan: 35723,
    darkgoldenrod: 12092939,
    darkgray: 11119017,
    darkgreen: 25600,
    darkgrey: 11119017,
    darkkhaki: 12433259,
    darkmagenta: 9109643,
    darkolivegreen: 5597999,
    darkorange: 16747520,
    darkorchid: 10040012,
    darkred: 9109504,
    darksalmon: 15308410,
    darkseagreen: 9419919,
    darkslateblue: 4734347,
    darkslategray: 3100495,
    darkslategrey: 3100495,
    darkturquoise: 52945,
    darkviolet: 9699539,
    deeppink: 16716947,
    deepskyblue: 49151,
    dimgray: 6908265,
    dimgrey: 6908265,
    dodgerblue: 2003199,
    firebrick: 11674146,
    floralwhite: 16775920,
    forestgreen: 2263842,
    fuchsia: 16711935,
    gainsboro: 14474460,
    ghostwhite: 16316671,
    gold: 16766720,
    goldenrod: 14329120,
    gray: 8421504,
    green: 32768,
    greenyellow: 11403055,
    grey: 8421504,
    honeydew: 15794160,
    hotpink: 16738740,
    indianred: 13458524,
    indigo: 4915330,
    ivory: 16777200,
    khaki: 15787660,
    lavender: 15132410,
    lavenderblush: 16773365,
    lawngreen: 8190976,
    lemonchiffon: 16775885,
    lightblue: 11393254,
    lightcoral: 15761536,
    lightcyan: 14745599,
    lightgoldenrodyellow: 16448210,
    lightgray: 13882323,
    lightgreen: 9498256,
    lightgrey: 13882323,
    lightpink: 16758465,
    lightsalmon: 16752762,
    lightseagreen: 2142890,
    lightskyblue: 8900346,
    lightslategray: 7833753,
    lightslategrey: 7833753,
    lightsteelblue: 11584734,
    lightyellow: 16777184,
    lime: 65280,
    limegreen: 3329330,
    linen: 16445670,
    magenta: 16711935,
    maroon: 8388608,
    mediumaquamarine: 6737322,
    mediumblue: 205,
    mediumorchid: 12211667,
    mediumpurple: 9662683,
    mediumseagreen: 3978097,
    mediumslateblue: 8087790,
    mediumspringgreen: 64154,
    mediumturquoise: 4772300,
    mediumvioletred: 13047173,
    midnightblue: 1644912,
    mintcream: 16121850,
    mistyrose: 16770273,
    moccasin: 16770229,
    navajowhite: 16768685,
    navy: 128,
    oldlace: 16643558,
    olive: 8421376,
    olivedrab: 7048739,
    orange: 16753920,
    orangered: 16729344,
    orchid: 14315734,
    palegoldenrod: 15657130,
    palegreen: 10025880,
    paleturquoise: 11529966,
    palevioletred: 14381203,
    papayawhip: 16773077,
    peachpuff: 16767673,
    peru: 13468991,
    pink: 16761035,
    plum: 14524637,
    powderblue: 11591910,
    purple: 8388736,
    rebeccapurple: 6697881,
    red: 16711680,
    rosybrown: 12357519,
    royalblue: 4286945,
    saddlebrown: 9127187,
    salmon: 16416882,
    sandybrown: 16032864,
    seagreen: 3050327,
    seashell: 16774638,
    sienna: 10506797,
    silver: 12632256,
    skyblue: 8900331,
    slateblue: 6970061,
    slategray: 7372944,
    slategrey: 7372944,
    snow: 16775930,
    springgreen: 65407,
    steelblue: 4620980,
    tan: 13808780,
    teal: 32896,
    thistle: 14204888,
    tomato: 16737095,
    turquoise: 4251856,
    violet: 15631086,
    wheat: 16113331,
    white: 16777215,
    whitesmoke: 16119285,
    yellow: 16776960,
    yellowgreen: 10145074
  };
Mn(zt, it, {
  copy(t) {
    return Object.assign(new this.constructor(), this, t);
  },
  displayable() {
    return this.rgb().displayable();
  },
  hex: yr,
  // Deprecated! Use color.formatHex.
  formatHex: yr,
  formatHex8: _d,
  formatHsl: vd,
  formatRgb: wr,
  toString: wr
});
function yr() {
  return this.rgb().formatHex();
}
function _d() {
  return this.rgb().formatHex8();
}
function vd() {
  return Li(this).formatHsl();
}
function wr() {
  return this.rgb().formatRgb();
}
function it(t) {
  var e, n;
  return t = (t + "").trim().toLowerCase(), (e = dd.exec(t)) ? (n = e[1].length, e = parseInt(e[1], 16), n === 6 ? br(e) : n === 3 ? new P(e >> 8 & 15 | e >> 4 & 240, e >> 4 & 15 | e & 240, (e & 15) << 4 | e & 15, 1) : n === 8 ? te(e >> 24 & 255, e >> 16 & 255, e >> 8 & 255, (e & 255) / 255) : n === 4 ? te(e >> 12 & 15 | e >> 8 & 240, e >> 8 & 15 | e >> 4 & 240, e >> 4 & 15 | e & 240, ((e & 15) << 4 | e & 15) / 255) : null) : (e = gd.exec(t)) ? new P(e[1], e[2], e[3], 1) : (e = md.exec(t)) ? new P(e[1] * 255 / 100, e[2] * 255 / 100, e[3] * 255 / 100, 1) : (e = pd.exec(t)) ? te(e[1], e[2], e[3], e[4]) : (e = yd.exec(t)) ? te(e[1] * 255 / 100, e[2] * 255 / 100, e[3] * 255 / 100, e[4]) : (e = wd.exec(t)) ? xr(e[1], e[2] / 100, e[3] / 100, 1) : (e = bd.exec(t)) ? xr(e[1], e[2] / 100, e[3] / 100, e[4]) : pr.hasOwnProperty(t) ? br(pr[t]) : t === "transparent" ? new P(NaN, NaN, NaN, 0) : null;
}
function br(t) {
  return new P(t >> 16 & 255, t >> 8 & 255, t & 255, 1);
}
function te(t, e, n, r) {
  return r <= 0 && (t = e = n = NaN), new P(t, e, n, r);
}
function xd(t) {
  return t instanceof zt || (t = it(t)), t ? (t = t.rgb(), new P(t.r, t.g, t.b, t.opacity)) : new P();
}
function Ze(t, e, n, r) {
  return arguments.length === 1 ? xd(t) : new P(t, e, n, r ?? 1);
}
function P(t, e, n, r) {
  this.r = +t, this.g = +e, this.b = +n, this.opacity = +r;
}
Mn(P, Ze, Fi(zt, {
  brighter(t) {
    return t = t == null ? me : Math.pow(me, t), new P(this.r * t, this.g * t, this.b * t, this.opacity);
  },
  darker(t) {
    return t = t == null ? kt : Math.pow(kt, t), new P(this.r * t, this.g * t, this.b * t, this.opacity);
  },
  rgb() {
    return this;
  },
  clamp() {
    return new P(et(this.r), et(this.g), et(this.b), pe(this.opacity));
  },
  displayable() {
    return -0.5 <= this.r && this.r < 255.5 && -0.5 <= this.g && this.g < 255.5 && -0.5 <= this.b && this.b < 255.5 && 0 <= this.opacity && this.opacity <= 1;
  },
  hex: _r,
  // Deprecated! Use color.formatHex.
  formatHex: _r,
  formatHex8: Td,
  formatRgb: vr,
  toString: vr
}));
function _r() {
  return `#${tt(this.r)}${tt(this.g)}${tt(this.b)}`;
}
function Td() {
  return `#${tt(this.r)}${tt(this.g)}${tt(this.b)}${tt((isNaN(this.opacity) ? 1 : this.opacity) * 255)}`;
}
function vr() {
  const t = pe(this.opacity);
  return `${t === 1 ? "rgb(" : "rgba("}${et(this.r)}, ${et(this.g)}, ${et(this.b)}${t === 1 ? ")" : `, ${t})`}`;
}
function pe(t) {
  return isNaN(t) ? 1 : Math.max(0, Math.min(1, t));
}
function et(t) {
  return Math.max(0, Math.min(255, Math.round(t) || 0));
}
function tt(t) {
  return t = et(t), (t < 16 ? "0" : "") + t.toString(16);
}
function xr(t, e, n, r) {
  return r <= 0 ? t = e = n = NaN : n <= 0 || n >= 1 ? t = e = NaN : e <= 0 && (t = NaN), new I(t, e, n, r);
}
function Li(t) {
  if (t instanceof I) return new I(t.h, t.s, t.l, t.opacity);
  if (t instanceof zt || (t = it(t)), !t) return new I();
  if (t instanceof I) return t;
  t = t.rgb();
  var e = t.r / 255,
    n = t.g / 255,
    r = t.b / 255,
    i = Math.min(e, n, r),
    a = Math.max(e, n, r),
    o = NaN,
    s = a - i,
    u = (a + i) / 2;
  return s ? (e === a ? o = (n - r) / s + (n < r) * 6 : n === a ? o = (r - e) / s + 2 : o = (e - n) / s + 4, s /= u < 0.5 ? a + i : 2 - a - i, o *= 60) : s = u > 0 && u < 1 ? 0 : o, new I(o, s, u, t.opacity);
}
function Md(t, e, n, r) {
  return arguments.length === 1 ? Li(t) : new I(t, e, n, r ?? 1);
}
function I(t, e, n, r) {
  this.h = +t, this.s = +e, this.l = +n, this.opacity = +r;
}
Mn(I, Md, Fi(zt, {
  brighter(t) {
    return t = t == null ? me : Math.pow(me, t), new I(this.h, this.s, this.l * t, this.opacity);
  },
  darker(t) {
    return t = t == null ? kt : Math.pow(kt, t), new I(this.h, this.s, this.l * t, this.opacity);
  },
  rgb() {
    var t = this.h % 360 + (this.h < 0) * 360,
      e = isNaN(t) || isNaN(this.s) ? 0 : this.s,
      n = this.l,
      r = n + (n < 0.5 ? n : 1 - n) * e,
      i = 2 * n - r;
    return new P(We(t >= 240 ? t - 240 : t + 120, i, r), We(t, i, r), We(t < 120 ? t + 240 : t - 120, i, r), this.opacity);
  },
  clamp() {
    return new I(Tr(this.h), ee(this.s), ee(this.l), pe(this.opacity));
  },
  displayable() {
    return (0 <= this.s && this.s <= 1 || isNaN(this.s)) && 0 <= this.l && this.l <= 1 && 0 <= this.opacity && this.opacity <= 1;
  },
  formatHsl() {
    const t = pe(this.opacity);
    return `${t === 1 ? "hsl(" : "hsla("}${Tr(this.h)}, ${ee(this.s) * 100}%, ${ee(this.l) * 100}%${t === 1 ? ")" : `, ${t})`}`;
  }
}));
function Tr(t) {
  return t = (t || 0) % 360, t < 0 ? t + 360 : t;
}
function ee(t) {
  return Math.max(0, Math.min(1, t || 0));
}
function We(t, e, n) {
  return (t < 60 ? e + (n - e) * t / 60 : t < 180 ? n : t < 240 ? e + (n - e) * (240 - t) / 60 : e) * 255;
}
const Sn = t => () => t;
function Sd(t, e) {
  return function (n) {
    return t + n * e;
  };
}
function Ad(t, e, n) {
  return t = Math.pow(t, n), e = Math.pow(e, n) - t, n = 1 / n, function (r) {
    return Math.pow(t + r * e, n);
  };
}
function Od(t) {
  return (t = +t) == 1 ? ki : function (e, n) {
    return n - e ? Ad(e, n, t) : Sn(isNaN(e) ? n : e);
  };
}
function ki(t, e) {
  var n = e - t;
  return n ? Sd(t, n) : Sn(isNaN(t) ? e : t);
}
const ye = function t(e) {
  var n = Od(e);
  function r(i, a) {
    var o = n((i = Ze(i)).r, (a = Ze(a)).r),
      s = n(i.g, a.g),
      u = n(i.b, a.b),
      c = ki(i.opacity, a.opacity);
    return function (f) {
      return i.r = o(f), i.g = s(f), i.b = u(f), i.opacity = c(f), i + "";
    };
  }
  return r.gamma = t, r;
}(1);
function Pd(t, e) {
  e || (e = []);
  var n = t ? Math.min(e.length, t.length) : 0,
    r = e.slice(),
    i;
  return function (a) {
    for (i = 0; i < n; ++i) r[i] = t[i] * (1 - a) + e[i] * a;
    return r;
  };
}
function $d(t) {
  return ArrayBuffer.isView(t) && !(t instanceof DataView);
}
function Ed(t, e) {
  var n = e ? e.length : 0,
    r = t ? Math.min(n, t.length) : 0,
    i = new Array(r),
    a = new Array(n),
    o;
  for (o = 0; o < r; ++o) i[o] = An(t[o], e[o]);
  for (; o < n; ++o) a[o] = e[o];
  return function (s) {
    for (o = 0; o < r; ++o) a[o] = i[o](s);
    return a;
  };
}
function Nd(t, e) {
  var n = /* @__PURE__ */new Date();
  return t = +t, e = +e, function (r) {
    return n.setTime(t * (1 - r) + e * r), n;
  };
}
function D(t, e) {
  return t = +t, e = +e, function (n) {
    return t * (1 - n) + e * n;
  };
}
function Cd(t, e) {
  var n = {},
    r = {},
    i;
  (t === null || typeof t != "object") && (t = {}), (e === null || typeof e != "object") && (e = {});
  for (i in e) i in t ? n[i] = An(t[i], e[i]) : r[i] = e[i];
  return function (a) {
    for (i in n) r[i] = n[i](a);
    return r;
  };
}
var Je = /[-+]?(?:\d+\.?\d*|\.?\d+)(?:[eE][-+]?\d+)?/g,
  Ye = new RegExp(Je.source, "g");
function Dd(t) {
  return function () {
    return t;
  };
}
function Id(t) {
  return function (e) {
    return t(e) + "";
  };
}
function Wi(t, e) {
  var n = Je.lastIndex = Ye.lastIndex = 0,
    r,
    i,
    a,
    o = -1,
    s = [],
    u = [];
  for (t = t + "", e = e + ""; (r = Je.exec(t)) && (i = Ye.exec(e));) (a = i.index) > n && (a = e.slice(n, a), s[o] ? s[o] += a : s[++o] = a), (r = r[0]) === (i = i[0]) ? s[o] ? s[o] += i : s[++o] = i : (s[++o] = null, u.push({
    i: o,
    x: D(r, i)
  })), n = Ye.lastIndex;
  return n < e.length && (a = e.slice(n), s[o] ? s[o] += a : s[++o] = a), s.length < 2 ? u[0] ? Id(u[0].x) : Dd(e) : (e = u.length, function (c) {
    for (var f = 0, l; f < e; ++f) s[(l = u[f]).i] = l.x(c);
    return s.join("");
  });
}
function An(t, e) {
  var n = typeof e,
    r;
  return e == null || n === "boolean" ? Sn(e) : (n === "number" ? D : n === "string" ? (r = it(e)) ? (e = r, ye) : Wi : e instanceof it ? ye : e instanceof Date ? Nd : $d(e) ? Pd : Array.isArray(e) ? Ed : typeof e.valueOf != "function" && typeof e.toString != "function" || isNaN(e) ? Cd : D)(t, e);
}
function Rd(t, e) {
  return t = +t, e = +e, function (n) {
    return Math.round(t * (1 - n) + e * n);
  };
}
var Mr = 180 / Math.PI,
  tn = {
    translateX: 0,
    translateY: 0,
    rotate: 0,
    skewX: 0,
    scaleX: 1,
    scaleY: 1
  };
function Yi(t, e, n, r, i, a) {
  var o, s, u;
  return (o = Math.sqrt(t * t + e * e)) && (t /= o, e /= o), (u = t * n + e * r) && (n -= t * u, r -= e * u), (s = Math.sqrt(n * n + r * r)) && (n /= s, r /= s, u /= s), t * r < e * n && (t = -t, e = -e, u = -u, o = -o), {
    translateX: i,
    translateY: a,
    rotate: Math.atan2(e, t) * Mr,
    skewX: Math.atan(u) * Mr,
    scaleX: o,
    scaleY: s
  };
}
var ne;
function Fd(t) {
  const e = new (typeof DOMMatrix == "function" ? DOMMatrix : WebKitCSSMatrix)(t + "");
  return e.isIdentity ? tn : Yi(e.a, e.b, e.c, e.d, e.e, e.f);
}
function Ld(t) {
  return t == null || (ne || (ne = document.createElementNS("http://www.w3.org/2000/svg", "g")), ne.setAttribute("transform", t), !(t = ne.transform.baseVal.consolidate())) ? tn : (t = t.matrix, Yi(t.a, t.b, t.c, t.d, t.e, t.f));
}
function Hi(t, e, n, r) {
  function i(c) {
    return c.length ? c.pop() + " " : "";
  }
  function a(c, f, l, h, d, g) {
    if (c !== l || f !== h) {
      var m = d.push("translate(", null, e, null, n);
      g.push({
        i: m - 4,
        x: D(c, l)
      }, {
        i: m - 2,
        x: D(f, h)
      });
    } else (l || h) && d.push("translate(" + l + e + h + n);
  }
  function o(c, f, l, h) {
    c !== f ? (c - f > 180 ? f += 360 : f - c > 180 && (c += 360), h.push({
      i: l.push(i(l) + "rotate(", null, r) - 2,
      x: D(c, f)
    })) : f && l.push(i(l) + "rotate(" + f + r);
  }
  function s(c, f, l, h) {
    c !== f ? h.push({
      i: l.push(i(l) + "skewX(", null, r) - 2,
      x: D(c, f)
    }) : f && l.push(i(l) + "skewX(" + f + r);
  }
  function u(c, f, l, h, d, g) {
    if (c !== l || f !== h) {
      var m = d.push(i(d) + "scale(", null, ",", null, ")");
      g.push({
        i: m - 4,
        x: D(c, l)
      }, {
        i: m - 2,
        x: D(f, h)
      });
    } else (l !== 1 || h !== 1) && d.push(i(d) + "scale(" + l + "," + h + ")");
  }
  return function (c, f) {
    var l = [],
      h = [];
    return c = t(c), f = t(f), a(c.translateX, c.translateY, f.translateX, f.translateY, l, h), o(c.rotate, f.rotate, l, h), s(c.skewX, f.skewX, l, h), u(c.scaleX, c.scaleY, f.scaleX, f.scaleY, l, h), c = f = null, function (d) {
      for (var g = -1, m = h.length, p; ++g < m;) l[(p = h[g]).i] = p.x(d);
      return l.join("");
    };
  };
}
var kd = Hi(Fd, "px, ", "px)", "deg)"),
  Wd = Hi(Ld, ", ", ")", ")"),
  vt = 0,
  Et = 0,
  Pt = 0,
  Gi = 1e3,
  we,
  Nt,
  be = 0,
  at = 0,
  De = 0,
  Yt = typeof performance == "object" && performance.now ? performance : Date,
  Bi = typeof window == "object" && window.requestAnimationFrame ? window.requestAnimationFrame.bind(window) : function (t) {
    setTimeout(t, 17);
  };
function On() {
  return at || (Bi(Yd), at = Yt.now() + De);
}
function Yd() {
  at = 0;
}
function _e() {
  this._call = this._time = this._next = null;
}
_e.prototype = ji.prototype = {
  constructor: _e,
  restart: function (t, e, n) {
    if (typeof t != "function") throw new TypeError("callback is not a function");
    n = (n == null ? On() : +n) + (e == null ? 0 : +e), !this._next && Nt !== this && (Nt ? Nt._next = this : we = this, Nt = this), this._call = t, this._time = n, en();
  },
  stop: function () {
    this._call && (this._call = null, this._time = 1 / 0, en());
  }
};
function ji(t, e, n) {
  var r = new _e();
  return r.restart(t, e, n), r;
}
function Hd() {
  On(), ++vt;
  for (var t = we, e; t;) (e = at - t._time) >= 0 && t._call.call(void 0, e), t = t._next;
  --vt;
}
function Sr() {
  at = (be = Yt.now()) + De, vt = Et = 0;
  try {
    Hd();
  } finally {
    vt = 0, Bd(), at = 0;
  }
}
function Gd() {
  var t = Yt.now(),
    e = t - be;
  e > Gi && (De -= e, be = t);
}
function Bd() {
  for (var t, e = we, n, r = 1 / 0; e;) e._call ? (r > e._time && (r = e._time), t = e, e = e._next) : (n = e._next, e._next = null, e = t ? t._next = n : we = n);
  Nt = t, en(r);
}
function en(t) {
  if (!vt) {
    Et && (Et = clearTimeout(Et));
    var e = t - at;
    e > 24 ? (t < 1 / 0 && (Et = setTimeout(Sr, t - Yt.now() - De)), Pt && (Pt = clearInterval(Pt))) : (Pt || (be = Yt.now(), Pt = setInterval(Gd, Gi)), vt = 1, Bi(Sr));
  }
}
function Ar(t, e, n) {
  var r = new _e();
  return e = e == null ? 0 : +e, r.restart(i => {
    r.stop(), t(i + e);
  }, e, n), r;
}
var jd = Mi("start", "end", "cancel", "interrupt"),
  qd = [],
  qi = 0,
  Or = 1,
  nn = 2,
  ce = 3,
  Pr = 4,
  rn = 5,
  fe = 6;
function Ie(t, e, n, r, i, a) {
  var o = t.__transition;
  if (!o) t.__transition = {};else if (n in o) return;
  zd(t, n, {
    name: e,
    index: r,
    // For context during callback.
    group: i,
    // For context during callback.
    on: jd,
    tween: qd,
    time: a.time,
    delay: a.delay,
    duration: a.duration,
    ease: a.ease,
    timer: null,
    state: qi
  });
}
function Pn(t, e) {
  var n = k(t, e);
  if (n.state > qi) throw new Error("too late; already scheduled");
  return n;
}
function B(t, e) {
  var n = k(t, e);
  if (n.state > ce) throw new Error("too late; already running");
  return n;
}
function k(t, e) {
  var n = t.__transition;
  if (!n || !(n = n[e])) throw new Error("transition not found");
  return n;
}
function zd(t, e, n) {
  var r = t.__transition,
    i;
  r[e] = n, n.timer = ji(a, 0, n.time);
  function a(c) {
    n.state = Or, n.timer.restart(o, n.delay, n.time), n.delay <= c && o(c - n.delay);
  }
  function o(c) {
    var f, l, h, d;
    if (n.state !== Or) return u();
    for (f in r) if (d = r[f], d.name === n.name) {
      if (d.state === ce) return Ar(o);
      d.state === Pr ? (d.state = fe, d.timer.stop(), d.on.call("interrupt", t, t.__data__, d.index, d.group), delete r[f]) : +f < e && (d.state = fe, d.timer.stop(), d.on.call("cancel", t, t.__data__, d.index, d.group), delete r[f]);
    }
    if (Ar(function () {
      n.state === ce && (n.state = Pr, n.timer.restart(s, n.delay, n.time), s(c));
    }), n.state = nn, n.on.call("start", t, t.__data__, n.index, n.group), n.state === nn) {
      for (n.state = ce, i = new Array(h = n.tween.length), f = 0, l = -1; f < h; ++f) (d = n.tween[f].value.call(t, t.__data__, n.index, n.group)) && (i[++l] = d);
      i.length = l + 1;
    }
  }
  function s(c) {
    for (var f = c < n.duration ? n.ease.call(null, c / n.duration) : (n.timer.restart(u), n.state = rn, 1), l = -1, h = i.length; ++l < h;) i[l].call(t, f);
    n.state === rn && (n.on.call("end", t, t.__data__, n.index, n.group), u());
  }
  function u() {
    n.state = fe, n.timer.stop(), delete r[e];
    for (var c in r) return;
    delete t.__transition;
  }
}
function Xd(t, e) {
  var n = t.__transition,
    r,
    i,
    a = !0,
    o;
  if (n) {
    e = e == null ? null : e + "";
    for (o in n) {
      if ((r = n[o]).name !== e) {
        a = !1;
        continue;
      }
      i = r.state > nn && r.state < rn, r.state = fe, r.timer.stop(), r.on.call(i ? "interrupt" : "cancel", t, t.__data__, r.index, r.group), delete n[o];
    }
    a && delete t.__transition;
  }
}
function Ud(t) {
  return this.each(function () {
    Xd(this, t);
  });
}
function Vd(t, e) {
  var n, r;
  return function () {
    var i = B(this, t),
      a = i.tween;
    if (a !== n) {
      r = n = a;
      for (var o = 0, s = r.length; o < s; ++o) if (r[o].name === e) {
        r = r.slice(), r.splice(o, 1);
        break;
      }
    }
    i.tween = r;
  };
}
function Qd(t, e, n) {
  var r, i;
  if (typeof n != "function") throw new Error();
  return function () {
    var a = B(this, t),
      o = a.tween;
    if (o !== r) {
      i = (r = o).slice();
      for (var s = {
          name: e,
          value: n
        }, u = 0, c = i.length; u < c; ++u) if (i[u].name === e) {
        i[u] = s;
        break;
      }
      u === c && i.push(s);
    }
    a.tween = i;
  };
}
function Kd(t, e) {
  var n = this._id;
  if (t += "", arguments.length < 2) {
    for (var r = k(this.node(), n).tween, i = 0, a = r.length, o; i < a; ++i) if ((o = r[i]).name === t) return o.value;
    return null;
  }
  return this.each((e == null ? Vd : Qd)(n, t, e));
}
function $n(t, e, n) {
  var r = t._id;
  return t.each(function () {
    var i = B(this, r);
    (i.value || (i.value = {}))[e] = n.apply(this, arguments);
  }), function (i) {
    return k(i, r).value[e];
  };
}
function zi(t, e) {
  var n;
  return (typeof e == "number" ? D : e instanceof it ? ye : (n = it(e)) ? (e = n, ye) : Wi)(t, e);
}
function Zd(t) {
  return function () {
    this.removeAttribute(t);
  };
}
function Jd(t) {
  return function () {
    this.removeAttributeNS(t.space, t.local);
  };
}
function tg(t, e, n) {
  var r,
    i = n + "",
    a;
  return function () {
    var o = this.getAttribute(t);
    return o === i ? null : o === r ? a : a = e(r = o, n);
  };
}
function eg(t, e, n) {
  var r,
    i = n + "",
    a;
  return function () {
    var o = this.getAttributeNS(t.space, t.local);
    return o === i ? null : o === r ? a : a = e(r = o, n);
  };
}
function ng(t, e, n) {
  var r, i, a;
  return function () {
    var o,
      s = n(this),
      u;
    return s == null ? void this.removeAttribute(t) : (o = this.getAttribute(t), u = s + "", o === u ? null : o === r && u === i ? a : (i = u, a = e(r = o, s)));
  };
}
function rg(t, e, n) {
  var r, i, a;
  return function () {
    var o,
      s = n(this),
      u;
    return s == null ? void this.removeAttributeNS(t.space, t.local) : (o = this.getAttributeNS(t.space, t.local), u = s + "", o === u ? null : o === r && u === i ? a : (i = u, a = e(r = o, s)));
  };
}
function ig(t, e) {
  var n = Ce(t),
    r = n === "transform" ? Wd : zi;
  return this.attrTween(t, typeof e == "function" ? (n.local ? rg : ng)(n, r, $n(this, "attr." + t, e)) : e == null ? (n.local ? Jd : Zd)(n) : (n.local ? eg : tg)(n, r, e));
}
function ag(t, e) {
  return function (n) {
    this.setAttribute(t, e.call(this, n));
  };
}
function og(t, e) {
  return function (n) {
    this.setAttributeNS(t.space, t.local, e.call(this, n));
  };
}
function sg(t, e) {
  var n, r;
  function i() {
    var a = e.apply(this, arguments);
    return a !== r && (n = (r = a) && og(t, a)), n;
  }
  return i._value = e, i;
}
function ug(t, e) {
  var n, r;
  function i() {
    var a = e.apply(this, arguments);
    return a !== r && (n = (r = a) && ag(t, a)), n;
  }
  return i._value = e, i;
}
function cg(t, e) {
  var n = "attr." + t;
  if (arguments.length < 2) return (n = this.tween(n)) && n._value;
  if (e == null) return this.tween(n, null);
  if (typeof e != "function") throw new Error();
  var r = Ce(t);
  return this.tween(n, (r.local ? sg : ug)(r, e));
}
function fg(t, e) {
  return function () {
    Pn(this, t).delay = +e.apply(this, arguments);
  };
}
function lg(t, e) {
  return e = +e, function () {
    Pn(this, t).delay = e;
  };
}
function hg(t) {
  var e = this._id;
  return arguments.length ? this.each((typeof t == "function" ? fg : lg)(e, t)) : k(this.node(), e).delay;
}
function dg(t, e) {
  return function () {
    B(this, t).duration = +e.apply(this, arguments);
  };
}
function gg(t, e) {
  return e = +e, function () {
    B(this, t).duration = e;
  };
}
function mg(t) {
  var e = this._id;
  return arguments.length ? this.each((typeof t == "function" ? dg : gg)(e, t)) : k(this.node(), e).duration;
}
function pg(t, e) {
  if (typeof e != "function") throw new Error();
  return function () {
    B(this, t).ease = e;
  };
}
function yg(t) {
  var e = this._id;
  return arguments.length ? this.each(pg(e, t)) : k(this.node(), e).ease;
}
function wg(t, e) {
  return function () {
    var n = e.apply(this, arguments);
    if (typeof n != "function") throw new Error();
    B(this, t).ease = n;
  };
}
function bg(t) {
  if (typeof t != "function") throw new Error();
  return this.each(wg(this._id, t));
}
function _g(t) {
  typeof t != "function" && (t = Oi(t));
  for (var e = this._groups, n = e.length, r = new Array(n), i = 0; i < n; ++i) for (var a = e[i], o = a.length, s = r[i] = [], u, c = 0; c < o; ++c) (u = a[c]) && t.call(u, u.__data__, c, a) && s.push(u);
  return new z(r, this._parents, this._name, this._id);
}
function vg(t) {
  if (t._id !== this._id) throw new Error();
  for (var e = this._groups, n = t._groups, r = e.length, i = n.length, a = Math.min(r, i), o = new Array(r), s = 0; s < a; ++s) for (var u = e[s], c = n[s], f = u.length, l = o[s] = new Array(f), h, d = 0; d < f; ++d) (h = u[d] || c[d]) && (l[d] = h);
  for (; s < r; ++s) o[s] = e[s];
  return new z(o, this._parents, this._name, this._id);
}
function xg(t) {
  return (t + "").trim().split(/^|\s+/).every(function (e) {
    var n = e.indexOf(".");
    return n >= 0 && (e = e.slice(0, n)), !e || e === "start";
  });
}
function Tg(t, e, n) {
  var r,
    i,
    a = xg(e) ? Pn : B;
  return function () {
    var o = a(this, t),
      s = o.on;
    s !== r && (i = (r = s).copy()).on(e, n), o.on = i;
  };
}
function Mg(t, e) {
  var n = this._id;
  return arguments.length < 2 ? k(this.node(), n).on.on(t) : this.each(Tg(n, t, e));
}
function Sg(t) {
  return function () {
    var e = this.parentNode;
    for (var n in this.__transition) if (+n !== t) return;
    e && e.removeChild(this);
  };
}
function Ag() {
  return this.on("end.remove", Sg(this._id));
}
function Og(t) {
  var e = this._name,
    n = this._id;
  typeof t != "function" && (t = xn(t));
  for (var r = this._groups, i = r.length, a = new Array(i), o = 0; o < i; ++o) for (var s = r[o], u = s.length, c = a[o] = new Array(u), f, l, h = 0; h < u; ++h) (f = s[h]) && (l = t.call(f, f.__data__, h, s)) && ("__data__" in f && (l.__data__ = f.__data__), c[h] = l, Ie(c[h], e, n, h, c, k(f, n)));
  return new z(a, this._parents, e, n);
}
function Pg(t) {
  var e = this._name,
    n = this._id;
  typeof t != "function" && (t = Ai(t));
  for (var r = this._groups, i = r.length, a = [], o = [], s = 0; s < i; ++s) for (var u = r[s], c = u.length, f, l = 0; l < c; ++l) if (f = u[l]) {
    for (var h = t.call(f, f.__data__, l, u), d, g = k(f, n), m = 0, p = h.length; m < p; ++m) (d = h[m]) && Ie(d, e, n, m, h, g);
    a.push(h), o.push(f);
  }
  return new z(a, o, e, n);
}
var $g = qt.prototype.constructor;
function Eg() {
  return new $g(this._groups, this._parents);
}
function Ng(t, e) {
  var n, r, i;
  return function () {
    var a = _t(this, t),
      o = (this.style.removeProperty(t), _t(this, t));
    return a === o ? null : a === n && o === r ? i : i = e(n = a, r = o);
  };
}
function Xi(t) {
  return function () {
    this.style.removeProperty(t);
  };
}
function Cg(t, e, n) {
  var r,
    i = n + "",
    a;
  return function () {
    var o = _t(this, t);
    return o === i ? null : o === r ? a : a = e(r = o, n);
  };
}
function Dg(t, e, n) {
  var r, i, a;
  return function () {
    var o = _t(this, t),
      s = n(this),
      u = s + "";
    return s == null && (u = s = (this.style.removeProperty(t), _t(this, t))), o === u ? null : o === r && u === i ? a : (i = u, a = e(r = o, s));
  };
}
function Ig(t, e) {
  var n,
    r,
    i,
    a = "style." + e,
    o = "end." + a,
    s;
  return function () {
    var u = B(this, t),
      c = u.on,
      f = u.value[a] == null ? s || (s = Xi(e)) : void 0;
    (c !== n || i !== f) && (r = (n = c).copy()).on(o, i = f), u.on = r;
  };
}
function Rg(t, e, n) {
  var r = (t += "") == "transform" ? kd : zi;
  return e == null ? this.styleTween(t, Ng(t, r)).on("end.style." + t, Xi(t)) : typeof e == "function" ? this.styleTween(t, Dg(t, r, $n(this, "style." + t, e))).each(Ig(this._id, t)) : this.styleTween(t, Cg(t, r, e), n).on("end.style." + t, null);
}
function Fg(t, e, n) {
  return function (r) {
    this.style.setProperty(t, e.call(this, r), n);
  };
}
function Lg(t, e, n) {
  var r, i;
  function a() {
    var o = e.apply(this, arguments);
    return o !== i && (r = (i = o) && Fg(t, o, n)), r;
  }
  return a._value = e, a;
}
function kg(t, e, n) {
  var r = "style." + (t += "");
  if (arguments.length < 2) return (r = this.tween(r)) && r._value;
  if (e == null) return this.tween(r, null);
  if (typeof e != "function") throw new Error();
  return this.tween(r, Lg(t, e, n ?? ""));
}
function Wg(t) {
  return function () {
    this.textContent = t;
  };
}
function Yg(t) {
  return function () {
    var e = t(this);
    this.textContent = e ?? "";
  };
}
function Hg(t) {
  return this.tween("text", typeof t == "function" ? Yg($n(this, "text", t)) : Wg(t == null ? "" : t + ""));
}
function Gg(t) {
  return function (e) {
    this.textContent = t.call(this, e);
  };
}
function Bg(t) {
  var e, n;
  function r() {
    var i = t.apply(this, arguments);
    return i !== n && (e = (n = i) && Gg(i)), e;
  }
  return r._value = t, r;
}
function jg(t) {
  var e = "text";
  if (arguments.length < 1) return (e = this.tween(e)) && e._value;
  if (t == null) return this.tween(e, null);
  if (typeof t != "function") throw new Error();
  return this.tween(e, Bg(t));
}
function qg() {
  for (var t = this._name, e = this._id, n = Ui(), r = this._groups, i = r.length, a = 0; a < i; ++a) for (var o = r[a], s = o.length, u, c = 0; c < s; ++c) if (u = o[c]) {
    var f = k(u, e);
    Ie(u, t, n, c, o, {
      time: f.time + f.delay + f.duration,
      delay: 0,
      duration: f.duration,
      ease: f.ease
    });
  }
  return new z(r, this._parents, t, n);
}
function zg() {
  var t,
    e,
    n = this,
    r = n._id,
    i = n.size();
  return new Promise(function (a, o) {
    var s = {
        value: o
      },
      u = {
        value: function () {
          --i === 0 && a();
        }
      };
    n.each(function () {
      var c = B(this, r),
        f = c.on;
      f !== t && (e = (t = f).copy(), e._.cancel.push(s), e._.interrupt.push(s), e._.end.push(u)), c.on = e;
    }), i === 0 && a();
  });
}
var Xg = 0;
function z(t, e, n, r) {
  this._groups = t, this._parents = e, this._name = n, this._id = r;
}
function Ui() {
  return ++Xg;
}
var q = qt.prototype;
z.prototype = {
  constructor: z,
  select: Og,
  selectAll: Pg,
  selectChild: q.selectChild,
  selectChildren: q.selectChildren,
  filter: _g,
  merge: vg,
  selection: Eg,
  transition: qg,
  call: q.call,
  nodes: q.nodes,
  node: q.node,
  size: q.size,
  empty: q.empty,
  each: q.each,
  on: Mg,
  attr: ig,
  attrTween: cg,
  style: Rg,
  styleTween: kg,
  text: Hg,
  textTween: jg,
  remove: Ag,
  tween: Kd,
  delay: hg,
  duration: mg,
  ease: yg,
  easeVarying: bg,
  end: zg,
  [Symbol.iterator]: q[Symbol.iterator]
};
function Vi(t) {
  return ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2;
}
var Ug = {
  time: null,
  // Set on use.
  delay: 0,
  duration: 250,
  ease: Vi
};
function Vg(t, e) {
  for (var n; !(n = t.__transition) || !(n = n[e]);) if (!(t = t.parentNode)) throw new Error(`transition ${e} not found`);
  return n;
}
function Qg(t) {
  var e, n;
  t instanceof z ? (e = t._id, t = t._name) : (e = Ui(), (n = Ug).time = On(), t = t == null ? null : t + "");
  for (var r = this._groups, i = r.length, a = 0; a < i; ++a) for (var o = r[a], s = o.length, u, c = 0; c < s; ++c) (u = o[c]) && Ie(u, t, e, c, o, n || Vg(u, e));
  return new z(r, this._parents, t, e);
}
qt.prototype.interrupt = Ud;
qt.prototype.transition = Qg;
function Kg(t) {
  return Math.abs(t = Math.round(t)) >= 1e21 ? t.toLocaleString("en").replace(/,/g, "") : t.toString(10);
}
function ve(t, e) {
  if ((n = (t = e ? t.toExponential(e - 1) : t.toExponential()).indexOf("e")) < 0) return null;
  var n,
    r = t.slice(0, n);
  return [r.length > 1 ? r[0] + r.slice(2) : r, +t.slice(n + 1)];
}
function xt(t) {
  return t = ve(Math.abs(t)), t ? t[1] : NaN;
}
function Zg(t, e) {
  return function (n, r) {
    for (var i = n.length, a = [], o = 0, s = t[0], u = 0; i > 0 && s > 0 && (u + s + 1 > r && (s = Math.max(1, r - u)), a.push(n.substring(i -= s, i + s)), !((u += s + 1) > r));) s = t[o = (o + 1) % t.length];
    return a.reverse().join(e);
  };
}
function Jg(t) {
  return function (e) {
    return e.replace(/[0-9]/g, function (n) {
      return t[+n];
    });
  };
}
var tm = /^(?:(.)?([<>=^]))?([+\-( ])?([$#])?(0)?(\d+)?(,)?(\.\d+)?(~)?([a-z%])?$/i;
function xe(t) {
  if (!(e = tm.exec(t))) throw new Error("invalid format: " + t);
  var e;
  return new En({
    fill: e[1],
    align: e[2],
    sign: e[3],
    symbol: e[4],
    zero: e[5],
    width: e[6],
    comma: e[7],
    precision: e[8] && e[8].slice(1),
    trim: e[9],
    type: e[10]
  });
}
xe.prototype = En.prototype;
function En(t) {
  this.fill = t.fill === void 0 ? " " : t.fill + "", this.align = t.align === void 0 ? ">" : t.align + "", this.sign = t.sign === void 0 ? "-" : t.sign + "", this.symbol = t.symbol === void 0 ? "" : t.symbol + "", this.zero = !!t.zero, this.width = t.width === void 0 ? void 0 : +t.width, this.comma = !!t.comma, this.precision = t.precision === void 0 ? void 0 : +t.precision, this.trim = !!t.trim, this.type = t.type === void 0 ? "" : t.type + "";
}
En.prototype.toString = function () {
  return this.fill + this.align + this.sign + this.symbol + (this.zero ? "0" : "") + (this.width === void 0 ? "" : Math.max(1, this.width | 0)) + (this.comma ? "," : "") + (this.precision === void 0 ? "" : "." + Math.max(0, this.precision | 0)) + (this.trim ? "~" : "") + this.type;
};
function em(t) {
  t: for (var e = t.length, n = 1, r = -1, i; n < e; ++n) switch (t[n]) {
    case ".":
      r = i = n;
      break;
    case "0":
      r === 0 && (r = n), i = n;
      break;
    default:
      if (!+t[n]) break t;
      r > 0 && (r = 0);
      break;
  }
  return r > 0 ? t.slice(0, r) + t.slice(i + 1) : t;
}
var Qi;
function nm(t, e) {
  var n = ve(t, e);
  if (!n) return t + "";
  var r = n[0],
    i = n[1],
    a = i - (Qi = Math.max(-8, Math.min(8, Math.floor(i / 3))) * 3) + 1,
    o = r.length;
  return a === o ? r : a > o ? r + new Array(a - o + 1).join("0") : a > 0 ? r.slice(0, a) + "." + r.slice(a) : "0." + new Array(1 - a).join("0") + ve(t, Math.max(0, e + a - 1))[0];
}
function $r(t, e) {
  var n = ve(t, e);
  if (!n) return t + "";
  var r = n[0],
    i = n[1];
  return i < 0 ? "0." + new Array(-i).join("0") + r : r.length > i + 1 ? r.slice(0, i + 1) + "." + r.slice(i + 1) : r + new Array(i - r.length + 2).join("0");
}
const Er = {
  "%": (t, e) => (t * 100).toFixed(e),
  b: t => Math.round(t).toString(2),
  c: t => t + "",
  d: Kg,
  e: (t, e) => t.toExponential(e),
  f: (t, e) => t.toFixed(e),
  g: (t, e) => t.toPrecision(e),
  o: t => Math.round(t).toString(8),
  p: (t, e) => $r(t * 100, e),
  r: $r,
  s: nm,
  X: t => Math.round(t).toString(16).toUpperCase(),
  x: t => Math.round(t).toString(16)
};
function Nr(t) {
  return t;
}
var Cr = Array.prototype.map,
  Dr = ["y", "z", "a", "f", "p", "n", "µ", "m", "", "k", "M", "G", "T", "P", "E", "Z", "Y"];
function rm(t) {
  var e = t.grouping === void 0 || t.thousands === void 0 ? Nr : Zg(Cr.call(t.grouping, Number), t.thousands + ""),
    n = t.currency === void 0 ? "" : t.currency[0] + "",
    r = t.currency === void 0 ? "" : t.currency[1] + "",
    i = t.decimal === void 0 ? "." : t.decimal + "",
    a = t.numerals === void 0 ? Nr : Jg(Cr.call(t.numerals, String)),
    o = t.percent === void 0 ? "%" : t.percent + "",
    s = t.minus === void 0 ? "−" : t.minus + "",
    u = t.nan === void 0 ? "NaN" : t.nan + "";
  function c(l) {
    l = xe(l);
    var h = l.fill,
      d = l.align,
      g = l.sign,
      m = l.symbol,
      p = l.zero,
      v = l.width,
      w = l.comma,
      _ = l.precision,
      A = l.trim,
      S = l.type;
    S === "n" ? (w = !0, S = "g") : Er[S] || (_ === void 0 && (_ = 12), A = !0, S = "g"), (p || h === "0" && d === "=") && (p = !0, h = "0", d = "=");
    var Vt = m === "$" ? n : m === "#" && /[boxX]/.test(S) ? "0" + S.toLowerCase() : "",
      ya = m === "$" ? r : /[%p]/.test(S) ? o : "",
      In = Er[S],
      wa = /[defgprs%]/.test(S);
    _ = _ === void 0 ? 6 : /[gprs]/.test(S) ? Math.max(1, Math.min(21, _)) : Math.max(0, Math.min(20, _));
    function Rn(b) {
      var Z = Vt,
        E = ya,
        lt,
        Fn,
        Qt;
      if (S === "c") E = In(b) + E, b = "";else {
        b = +b;
        var Kt = b < 0 || 1 / b < 0;
        if (b = isNaN(b) ? u : In(Math.abs(b), _), A && (b = em(b)), Kt && +b == 0 && g !== "+" && (Kt = !1), Z = (Kt ? g === "(" ? g : s : g === "-" || g === "(" ? "" : g) + Z, E = (S === "s" ? Dr[8 + Qi / 3] : "") + E + (Kt && g === "(" ? ")" : ""), wa) {
          for (lt = -1, Fn = b.length; ++lt < Fn;) if (Qt = b.charCodeAt(lt), 48 > Qt || Qt > 57) {
            E = (Qt === 46 ? i + b.slice(lt + 1) : b.slice(lt)) + E, b = b.slice(0, lt);
            break;
          }
        }
      }
      w && !p && (b = e(b, 1 / 0));
      var Zt = Z.length + b.length + E.length,
        j = Zt < v ? new Array(v - Zt + 1).join(h) : "";
      switch (w && p && (b = e(j + b, j.length ? v - E.length : 1 / 0), j = ""), d) {
        case "<":
          b = Z + b + E + j;
          break;
        case "=":
          b = Z + j + b + E;
          break;
        case "^":
          b = j.slice(0, Zt = j.length >> 1) + Z + b + E + j.slice(Zt);
          break;
        default:
          b = j + Z + b + E;
          break;
      }
      return a(b);
    }
    return Rn.toString = function () {
      return l + "";
    }, Rn;
  }
  function f(l, h) {
    var d = c((l = xe(l), l.type = "f", l)),
      g = Math.max(-8, Math.min(8, Math.floor(xt(h) / 3))) * 3,
      m = Math.pow(10, -g),
      p = Dr[8 + g / 3];
    return function (v) {
      return d(m * v) + p;
    };
  }
  return {
    format: c,
    formatPrefix: f
  };
}
var re, Ki, Zi;
im({
  thousands: ",",
  grouping: [3],
  currency: ["$", ""]
});
function im(t) {
  return re = rm(t), Ki = re.format, Zi = re.formatPrefix, re;
}
function am(t) {
  return Math.max(0, -xt(Math.abs(t)));
}
function om(t, e) {
  return Math.max(0, Math.max(-8, Math.min(8, Math.floor(xt(e) / 3))) * 3 - xt(Math.abs(t)));
}
function sm(t, e) {
  return t = Math.abs(t), e = Math.abs(e) - t, Math.max(0, xt(e) - xt(t)) + 1;
}
function Nn(t, e) {
  switch (arguments.length) {
    case 0:
      break;
    case 1:
      this.range(t);
      break;
    default:
      this.range(e).domain(t);
      break;
  }
  return this;
}
const Ir = Symbol("implicit");
function um() {
  var t = new hr(),
    e = [],
    n = [],
    r = Ir;
  function i(a) {
    let o = t.get(a);
    if (o === void 0) {
      if (r !== Ir) return r;
      t.set(a, o = e.push(a) - 1);
    }
    return n[o % n.length];
  }
  return i.domain = function (a) {
    if (!arguments.length) return e.slice();
    e = [], t = new hr();
    for (const o of a) t.has(o) || t.set(o, e.push(o) - 1);
    return i;
  }, i.range = function (a) {
    return arguments.length ? (n = Array.from(a), i) : n.slice();
  }, i.unknown = function (a) {
    return arguments.length ? (r = a, i) : r;
  }, i.copy = function () {
    return um(e, n).unknown(r);
  }, Nn.apply(i, arguments), i;
}
function cm(t) {
  return function () {
    return t;
  };
}
function fm(t) {
  return +t;
}
var Rr = [0, 1];
function dt(t) {
  return t;
}
function an(t, e) {
  return (e -= t = +t) ? function (n) {
    return (n - t) / e;
  } : cm(isNaN(e) ? NaN : 0.5);
}
function lm(t, e) {
  var n;
  return t > e && (n = t, t = e, e = n), function (r) {
    return Math.max(t, Math.min(e, r));
  };
}
function hm(t, e, n) {
  var r = t[0],
    i = t[1],
    a = e[0],
    o = e[1];
  return i < r ? (r = an(i, r), a = n(o, a)) : (r = an(r, i), a = n(a, o)), function (s) {
    return a(r(s));
  };
}
function dm(t, e, n) {
  var r = Math.min(t.length, e.length) - 1,
    i = new Array(r),
    a = new Array(r),
    o = -1;
  for (t[r] < t[0] && (t = t.slice().reverse(), e = e.slice().reverse()); ++o < r;) i[o] = an(t[o], t[o + 1]), a[o] = n(e[o], e[o + 1]);
  return function (s) {
    var u = Ti(t, s, 1, r) - 1;
    return a[u](i[u](s));
  };
}
function gm(t, e) {
  return e.domain(t.domain()).range(t.range()).interpolate(t.interpolate()).clamp(t.clamp()).unknown(t.unknown());
}
function mm() {
  var t = Rr,
    e = Rr,
    n = An,
    r,
    i,
    a,
    o = dt,
    s,
    u,
    c;
  function f() {
    var h = Math.min(t.length, e.length);
    return o !== dt && (o = lm(t[0], t[h - 1])), s = h > 2 ? dm : hm, u = c = null, l;
  }
  function l(h) {
    return h == null || isNaN(h = +h) ? a : (u || (u = s(t.map(r), e, n)))(r(o(h)));
  }
  return l.invert = function (h) {
    return o(i((c || (c = s(e, t.map(r), D)))(h)));
  }, l.domain = function (h) {
    return arguments.length ? (t = Array.from(h, fm), f()) : t.slice();
  }, l.range = function (h) {
    return arguments.length ? (e = Array.from(h), f()) : e.slice();
  }, l.rangeRound = function (h) {
    return e = Array.from(h), n = Rd, f();
  }, l.clamp = function (h) {
    return arguments.length ? (o = h ? !0 : dt, f()) : o !== dt;
  }, l.interpolate = function (h) {
    return arguments.length ? (n = h, f()) : n;
  }, l.unknown = function (h) {
    return arguments.length ? (a = h, l) : a;
  }, function (h, d) {
    return r = h, i = d, f();
  };
}
function pm() {
  return mm()(dt, dt);
}
function ym(t, e, n, r) {
  var i = Ol(t, e, n),
    a;
  switch (r = xe(r ?? ",f"), r.type) {
    case "s":
      {
        var o = Math.max(Math.abs(t), Math.abs(e));
        return r.precision == null && !isNaN(a = om(i, o)) && (r.precision = a), Zi(r, o);
      }
    case "":
    case "e":
    case "g":
    case "p":
    case "r":
      {
        r.precision == null && !isNaN(a = sm(i, Math.max(Math.abs(t), Math.abs(e)))) && (r.precision = a - (r.type === "e"));
        break;
      }
    case "f":
    case "%":
      {
        r.precision == null && !isNaN(a = am(i)) && (r.precision = a - (r.type === "%") * 2);
        break;
      }
  }
  return Ki(r);
}
function Ji(t) {
  var e = t.domain;
  return t.ticks = function (n) {
    var r = e();
    return Al(r[0], r[r.length - 1], n ?? 10);
  }, t.tickFormat = function (n, r) {
    var i = e();
    return ym(i[0], i[i.length - 1], n ?? 10, r);
  }, t.nice = function (n) {
    n == null && (n = 10);
    var r = e(),
      i = 0,
      a = r.length - 1,
      o = r[i],
      s = r[a],
      u,
      c,
      f = 10;
    for (s < o && (c = o, o = s, s = c, c = i, i = a, a = c); f-- > 0;) {
      if (c = Qe(o, s, n), c === u) return r[i] = o, r[a] = s, e(r);
      if (c > 0) o = Math.floor(o / c) * c, s = Math.ceil(s / c) * c;else if (c < 0) o = Math.ceil(o * c) / c, s = Math.floor(s * c) / c;else break;
      u = c;
    }
    return t;
  }, t;
}
function ta() {
  var t = pm();
  return t.copy = function () {
    return gm(t, ta());
  }, Nn.apply(t, arguments), Ji(t);
}
function ea() {
  var t = 0,
    e = 1,
    n = 1,
    r = [0.5],
    i = [0, 1],
    a;
  function o(u) {
    return u != null && u <= u ? i[Ti(r, u, 0, n)] : a;
  }
  function s() {
    var u = -1;
    for (r = new Array(n); ++u < n;) r[u] = ((u + 1) * e - (u - n) * t) / (n + 1);
    return o;
  }
  return o.domain = function (u) {
    return arguments.length ? ([t, e] = u, t = +t, e = +e, s()) : [t, e];
  }, o.range = function (u) {
    return arguments.length ? (n = (i = Array.from(u)).length - 1, s()) : i.slice();
  }, o.invertExtent = function (u) {
    var c = i.indexOf(u);
    return c < 0 ? [NaN, NaN] : c < 1 ? [t, r[0]] : c >= n ? [r[n - 1], e] : [r[c - 1], r[c]];
  }, o.unknown = function (u) {
    return arguments.length && (a = u), o;
  }, o.thresholds = function () {
    return r.slice();
  }, o.copy = function () {
    return ea().domain([t, e]).range(i).unknown(a);
  }, Nn.apply(Ji(o), arguments);
}
function Ct(t, e, n) {
  this.k = t, this.x = e, this.y = n;
}
Ct.prototype = {
  constructor: Ct,
  scale: function (t) {
    return t === 1 ? this : new Ct(this.k * t, this.x, this.y);
  },
  translate: function (t, e) {
    return t === 0 & e === 0 ? this : new Ct(this.k, this.x + this.k * t, this.y + this.k * e);
  },
  apply: function (t) {
    return [t[0] * this.k + this.x, t[1] * this.k + this.y];
  },
  applyX: function (t) {
    return t * this.k + this.x;
  },
  applyY: function (t) {
    return t * this.k + this.y;
  },
  invert: function (t) {
    return [(t[0] - this.x) / this.k, (t[1] - this.y) / this.k];
  },
  invertX: function (t) {
    return (t - this.x) / this.k;
  },
  invertY: function (t) {
    return (t - this.y) / this.k;
  },
  rescaleX: function (t) {
    return t.copy().domain(t.range().map(this.invertX, this).map(t.invert, t));
  },
  rescaleY: function (t) {
    return t.copy().domain(t.range().map(this.invertY, this).map(t.invert, t));
  },
  toString: function () {
    return "translate(" + this.x + "," + this.y + ") scale(" + this.k + ")";
  }
};
Ct.prototype;
const wm = "cds",
  bm = {
    opacity: {
      unselected: 0.05,
      selected: 0.4
    }
  },
  _m = {
    ticks: {
      number: 7,
      rotateIfSmallerThan: 30,
      verticalSpaceRatio: 2.5,
      horizontalSpaceRatio: 3.5
    },
    ratio: {
      reference: "value",
      compareTo: "marker"
    },
    paddingRatio: 0.1,
    hover: {
      rectanglePadding: 4
    }
  },
  vm = {
    duration: 1e3,
    ease: Vi,
    zoomLevel: 3
  },
  na = {
    circles: {
      fillOpacity: 0.3,
      hover: {
        stroke: "#FFF"
      }
    },
    padding: {
      mainGroup: 4,
      children: 2
    },
    depth: 2
  },
  xm = {
    pairingOptions: {
      "1-color": 4,
      "2-color": 5,
      "3-color": 5,
      "4-color": 3,
      "5-color": 2,
      "14-color": 1
    }
  },
  Tm = {
    circle: {
      radius: 4,
      opacity: {
        hovered: 1,
        default: 0.3
      }
    },
    box: {
      opacity: {
        hovered: 0.5,
        default: 0.3
      }
    },
    strokeWidth: {
      default: 1,
      thicker: 2
    }
  },
  Mm = {
    defaultBins: 10
  },
  Sm = {
    items: {
      status: {
        ACTIVE: 1,
        DISABLED: 0
      },
      horizontalSpace: 12,
      verticalSpace: 24,
      textYOffset: 8,
      spaceAfter: 4
    },
    checkbox: {
      radius: 6.5
    },
    radius: {
      iconData: [{
        cx: 7,
        cy: 7,
        r: 6.5
      }, {
        cx: 7,
        cy: 10,
        r: 3.5
      }],
      fill: null,
      stroke: "#8c8c8c"
    },
    line: {
      yPosition: 6,
      width: 24,
      strokeWidth: 1.4,
      fill: null,
      stroke: "#999999"
    },
    area: {
      width: 24,
      height: 14,
      fill: "#6f6f6f",
      stroke: null
    },
    size: {
      iconData: [{
        width: 23,
        height: 12
      }, {
        width: 13,
        height: 6
      }],
      fill: null,
      stroke: "#8D8D8D"
    },
    quartile: {
      iconData: [{
        x: 0,
        y: 0,
        width: 24,
        height: 13
      }, {
        x: 11,
        y: 4,
        width: 1,
        height: 4
      }]
    },
    zoom: {
      iconData: [{
        x: 0,
        y: 0,
        width: 12,
        height: 12
      }],
      color: "#8D8D8D"
    },
    color: {
      barWidth: 300,
      barHeight: 8,
      axisYTranslation: 10
    }
  },
  Am = {
    opacity: {
      unselected: 0.3,
      selected: 1
    },
    weight: {
      selected: 2,
      unselected: 1
    }
  },
  Om = {
    statusBar: {
      paddingRight: 5
    },
    status: {
      indicatorSize: 16,
      paddingLeft: 15
    },
    total: {
      paddingLeft: 36,
      paddingRight: 24
    },
    height: {
      default: 8,
      proportional: 16
    },
    dividerWidth: 2
  },
  Pm = {
    radiusOffset: -15,
    innerRadius: 2,
    padAngle: 7e-3,
    hoverArc: {
      outerRadiusOffset: 3
    },
    xOffset: 30,
    yOffset: 20,
    yOffsetCallout: 10,
    callout: {
      minSliceDegree: 5,
      offsetX: 15,
      offsetY: 12,
      horizontalLineLength: 8,
      textMargin: 2
    }
  },
  $m = {
    opacity: {
      unselected: 0.1,
      selected: 0.3
    },
    xLabelPadding: 10,
    yLabelPadding: 8,
    yTicksNumber: 4,
    minRange: 10,
    xAxisRectHeight: 50,
    dotsRadius: 5
  },
  Em = {
    nodeWidth: 4,
    nodeHoveredWidth: 8,
    minNodePadding: 24,
    opacity: {
      unfocus: 0.3,
      default: 0.8,
      selected: 1
    }
  },
  Nm = {
    minCellDividerDimension: 16,
    // Ensures axes lines are displayed with or without stroke disabled
    chartPadding: 0.5
  },
  Cm = {
    default: {
      size: 24
    }
  },
  Dm = {
    defaultOffset: 4,
    horizontalOffset: 10
  },
  Im = {
    default: {
      duration: 300
    },
    pie_slice_mouseover: {
      duration: 100
    },
    pie_slice_mouseout: {
      duration: 100
    },
    pie_chart_titles: {
      duration: 375
    },
    graph_element_mouseover_fill_update: {
      duration: 100
    },
    graph_element_mouseout_fill_update: {
      duration: 100
    }
  },
  Rm = {
    buttonSize: 32,
    iconSize: 20,
    height: 32,
    spacerHeight: 10,
    iconPadding: 6
  },
  Fm = {
    height: {
      [Ge.GRAPH_VIEW]: 32,
      [Ge.SLIDER_VIEW]: 10
    },
    spacerHeight: 8,
    handleWidth: 5,
    handleBarWidth: 1,
    handleBarHeight: 12
  },
  ra = [{
    type: W.RADIUS,
    name: "Radius"
  }, {
    type: W.AREA,
    name: "Poor area"
  }, {
    type: W.AREA,
    name: "Satisfactory area"
  }, {
    type: W.AREA,
    name: "Great area"
  }, {
    type: W.QUARTILE,
    name: "Quartiles"
  }],
  gt = {
    type: ae.END_LINE,
    threshold: 16,
    numCharacter: 14
  },
  Lm = {
    code: typeof navigator < "u" && (navigator == null ? void 0 : navigator.language) || "en-US",
    // read from browser's navigator.language
    number: (t, e = (navigator == null ? void 0 : navigator.language) || "en-US") => t.toLocaleString(e),
    // based on code property if specified
    date: (t, e = (navigator == null ? void 0 : navigator.language) || "en-US", n = {}, r = null) => r || t.toLocaleDateString(e, n),
    // based on code property if specified
    time: (t, e = (navigator == null ? void 0 : navigator.language) || "en-US", n = {}, r = null) => r || t.toLocaleTimeString(e, n),
    // based on code property if specified
    optionsObject: {
      "15seconds": {
        primary: {
          "MMM d, pp": {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h12"
          },
          "MMM d, h:mm:ss.SSS a": {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            fractionalSecondDigits: 3,
            hourCycle: "h12"
          }
        },
        secondary: {
          pp: {
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h12"
          },
          "h:mm:ss.SSS a": {
            hour: "numeric",
            minute: "2-digit",
            fractionalSecondDigits: 3,
            hourCycle: "h12"
          }
        },
        type: "time"
      },
      minute: {
        primary: {
          "MMM d, p": {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hourCycle: "h12"
          }
        },
        secondary: {
          p: {
            hour: "numeric",
            minute: "2-digit",
            hourCycle: "h12"
          }
        },
        type: "time"
      },
      "30minutes": {
        primary: {
          "MMM d, p": {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hourCycle: "h12"
          }
        },
        secondary: {
          p: {
            hour: "numeric",
            minute: "2-digit",
            hourCycle: "h12"
          }
        },
        type: "time"
      },
      hourly: {
        primary: {
          "MMM d, hh a": {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            hourCycle: "h12"
          }
        },
        secondary: {
          "hh a": {
            hour: "2-digit",
            hourCycle: "h12"
          }
        },
        type: "time"
      },
      daily: {
        primary: {
          "MMM d": {
            month: "short",
            day: "numeric"
          }
        },
        secondary: {
          d: {
            day: "numeric"
          }
        },
        type: "date"
      },
      weekly: {
        primary: {
          "eee, MMM d": {
            weekday: "short",
            month: "short",
            day: "numeric"
          }
        },
        secondary: {
          eee: {
            weekday: "short"
          }
        },
        type: "date"
      },
      monthly: {
        primary: {
          "MMM yyyy": {
            month: "short",
            year: "numeric"
          }
        },
        secondary: {
          MMM: {
            month: "short"
          }
        },
        type: "date"
      },
      quarterly: {
        primary: {},
        secondary: {},
        type: "date"
      },
      yearly: {
        primary: {
          yyyy: {
            year: "numeric"
          }
        },
        secondary: {
          yyyy: {
            year: "numeric"
          }
        },
        type: "date"
      }
    },
    translations: {
      group: "Group",
      total: "Total",
      meter: {
        title: ""
        //default is emply string as meter title is dataset label
      },
      tabularRep: {
        title: "Tabular representation",
        downloadAsCSV: "Download as CSV"
      },
      toolbar: {
        exportAsCSV: "Export to CSV",
        exportAsJPG: "Export to JPG",
        exportAsPNG: "Export to PNG",
        zoomIn: "Zoom in",
        zoomOut: "Zoom out",
        resetZoom: "Reset zoom",
        moreOptions: "More options",
        makeFullScreen: "Make fullscreen",
        exitFullScreen: "Exit fullscreen",
        showAsTable: "Show as table"
      }
    }
  },
  km = {
    enabled: !0,
    position: ba.BOTTOM,
    clickable: !0,
    truncation: gt,
    alignment: Tt.LEFT,
    order: null,
    additionalItems: []
  },
  ia = {
    x: {
      // set enable to false will not draw grid and stroke of grid backdrop
      enabled: !0,
      numberOfTicks: 15,
      alignWithAxisTicks: !1
    },
    y: {
      // set enable to false will not draw grid and stroke of grid backdrop
      enabled: !0,
      numberOfTicks: 5,
      alignWithAxisTicks: !1
    }
  },
  aa = {
    // enable or disable ruler
    enabled: !0
  },
  Cn = {
    enabled: !0,
    showTotal: !0,
    truncation: gt,
    groupLabel: "Group"
  },
  oa = {
    top: {
      visible: !0,
      includeZero: !0,
      truncation: gt
    },
    bottom: {
      visible: !0,
      includeZero: !0,
      truncation: gt
    },
    left: {
      visible: !0,
      includeZero: !0,
      truncation: gt
    },
    right: {
      visible: !0,
      includeZero: !0,
      truncation: gt
    }
  },
  Xt = {
    addSpaceOnEdges: 1,
    showDayName: !1,
    localeObject: Br,
    timeIntervalFormats: {
      "15seconds": {
        primary: "MMM d, pp",
        secondary: "pp"
      },
      minute: {
        primary: "MMM d, p",
        secondary: "p"
      },
      "30minutes": {
        primary: "MMM d, p",
        secondary: "p"
      },
      hourly: {
        primary: "MMM d, hh a",
        secondary: "hh a"
      },
      daily: {
        primary: "MMM d",
        secondary: "d"
      },
      weekly: {
        primary: "eee, MMM d",
        secondary: "eee"
      },
      monthly: {
        primary: "MMM yyyy",
        secondary: "MMM"
      },
      quarterly: {
        primary: "QQQ ''yy",
        secondary: "QQQ"
      },
      yearly: {
        primary: "yyyy",
        secondary: "yyyy"
      }
    }
  };
let sa = !1;
try {
  sa = typeof document < "u" && (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled);
} catch (t) {
  console.warn("Fullscreen capabilities check failed: ", t.message);
}
const O = {
    width: null,
    height: null,
    resizable: !0,
    theme: _a.WHITE,
    tooltip: Cn,
    legend: km,
    locale: Lm,
    style: {
      prefix: "cc"
    },
    data: {
      groupMapsTo: "group",
      loading: !1,
      selectedGroups: []
    },
    color: {
      scale: null,
      pairing: {
        numberOfVariants: null,
        option: 1
      },
      gradient: {
        enabled: !1
      }
    },
    toolbar: {
      enabled: !0,
      numberOfIcons: 3,
      controls: [{
        type: Mt.SHOW_AS_DATATABLE
      }, ...(sa ? [{
        type: Mt.MAKE_FULLSCREEN
      }] : []), {
        type: Mt.EXPORT_CSV
      }, {
        type: Mt.EXPORT_PNG
      }, {
        type: Mt.EXPORT_JPG
      }]
    }
  },
  ua = y({}, O, {
    thematic: {
      projection: va.geoNaturalEarth1
    }
  }),
  Ut = y({}, O, {
    axes: oa,
    timeScale: Xt,
    grid: ia,
    ruler: aa,
    zoomBar: {
      zoomRatio: 0.4,
      minZoomRatio: 0.01,
      top: {
        enabled: !1,
        type: Ge.GRAPH_VIEW
      }
    }
  }),
  ot = y({}, Ut, {
    bars: {
      maxWidth: 16,
      spacingFactor: 0.25
    },
    timeScale: y(Xt, {
      addSpaceOnEdges: 1
    })
  }),
  Wm = y({}, ot, {}),
  Ym = y({}, ot, {}),
  Hm = y({}, ot, {
    bars: y({}, ot.bars, {
      dividerSize: 1.5
    })
  }),
  Gm = y({}, ot, {}),
  Dn = y({}, Ut, {
    points: {
      // default point radius to 4
      radius: 4,
      fillOpacity: 0.3,
      filled: !0,
      enabled: !0
    }
  }),
  Bm = Dn,
  ca = y({}, Dn, {
    points: {
      // default point radius to 3
      radius: 3,
      filled: !1,
      enabled: !0
    }
  }),
  fa = y({}, ca, {
    timeScale: y(Xt, {
      addSpaceOnEdges: 0
    })
  }),
  jm = fa,
  qm = y({}, Ut, {
    bubble: {
      radiusMapsTo: "radius",
      radiusLabel: "Radius",
      radiusRange: t => {
        const e = Math.min(t.width, t.height);
        return [e * 3 / 400, e * 25 / 400];
      },
      fillOpacity: 0.2,
      enabled: !0
    },
    points: {
      filled: !0
    },
    legend: {
      additionalItems: [{
        type: W.RADIUS,
        name: "Radius"
      }]
    }
  }),
  zm = y({}, Ut, {
    bullet: {
      performanceAreaTitles: ["Poor", "Satisfactory", "Great"]
    },
    grid: {
      x: {
        enabled: !1
      },
      y: {
        enabled: !1
      }
    },
    legend: {
      additionalItems: [{
        type: W.AREA,
        name: "Poor area"
      }, {
        type: W.AREA,
        name: "Satisfactory area"
      }, {
        type: W.AREA,
        name: "Great area"
      }, {
        type: W.QUARTILE,
        name: "Quartiles"
      }]
    }
  }),
  Xm = y({}, ot, {
    bars: {
      dividerSize: 1.5
    },
    timeScale: y(Xt, {
      addSpaceOnEdges: 0
    })
  }),
  Um = y({}, O, {
    tooltip: y({}, Cn, {
      wordLabel: "Word",
      valueLabel: "Value"
    }),
    wordCloud: {
      fontSizeMapsTo: "value",
      fontSizeRange: t => {
        const e = Math.min(t.width, t.height);
        return [e * 20 / 400, e * 75 / 400];
      },
      wordMapsTo: "word"
    }
  }),
  la = y({}, O, {
    pie: {
      labels: {
        formatter: null,
        enabled: !0
      },
      alignment: Tt.LEFT,
      sortFunction: null,
      valueMapsTo: "value"
    }
  }),
  Vm = y({}, O, {
    legend: {
      enabled: !1
    },
    gauge: {
      type: xa.SEMI,
      arcWidth: 16,
      deltaArrow: {
        size: t => t / 8,
        enabled: !0
      },
      showPercentageSymbol: !0,
      status: null,
      numberSpacing: 10,
      deltaFontSize: t => t / 8,
      valueFontSize: t => t / 2.5,
      alignment: Tt.LEFT
    }
  }),
  Qm = y({}, la, {
    donut: {
      center: {
        numberFontSize: t => `${Math.min(t / 100 * 24, 24)}px`,
        titleFontSize: t => `${Math.min(t / 100 * 15, 15)}px`,
        titleYPosition: t => Math.min(t / 80 * 20, 20)
      },
      alignment: Tt.LEFT
    }
  }),
  ha = y({}, O, {
    legend: {
      enabled: !1,
      clickable: !1
    },
    meter: {
      showLabels: !0,
      proportional: null,
      statusBar: {
        percentageIndicator: {
          enabled: !0
        }
      }
    }
  }),
  Km = y({}, ha, {
    legend: {
      enabled: !0
    }
  }),
  Zm = y({}, O, {
    radar: {
      axes: {
        angle: "key",
        value: "value"
      },
      alignment: Tt.LEFT
    },
    tooltip: {
      gridline: {
        enabled: !0
      }
    }
  }),
  Jm = y({}, ot, {
    comboChartTypes: []
  }),
  tp = y({
    tree: {
      type: Ta.TREE
    }
  }, O, {}),
  ep = y({}, O, {
    data: y(O.data, {
      groupMapsTo: "name"
    })
  }),
  np = y({}, O, na, {
    data: y(O.data, {
      groupMapsTo: "name"
    })
  }),
  rp = y({}, O, {
    alluvial: {
      data: y(O.data, {
        groupMapsTo: "source"
      }),
      nodeAlignment: Tt.CENTER,
      nodePadding: 24,
      monochrome: !1,
      nodes: []
    }
  }),
  ip = y({}, O, {
    axes: oa,
    heatmap: {
      divider: {
        state: Ma.AUTO
      },
      colorLegend: {
        type: "linear"
      }
    }
  }),
  ap = y({}, ua, {
    choropleth: {
      colorLegend: {
        type: "linear"
      }
    }
  }),
  op = {
    alluvialChart: rp,
    areaChart: fa,
    axisChart: Ut,
    boxplotChart: Gm,
    bubbleChart: qm,
    bulletChart: zm,
    chart: O,
    circlePackChart: np,
    choroplethChart: ap,
    comboChart: Jm,
    donutChart: Qm,
    gaugeChart: Vm,
    groupedBarChart: Ym,
    heatmapChart: ip,
    histogramChart: Xm,
    lineChart: ca,
    lollipopChart: Bm,
    meterChart: ha,
    pieChart: la,
    proportionalMeterChart: Km,
    radarChart: Zm,
    scatterChart: Dn,
    simpleBarChart: Wm,
    stackedAreaChart: jm,
    stackedBarChart: Hm,
    thematicChart: ua,
    treeChart: tp,
    treemapChart: ep,
    wordCloudChart: Um
  },
  Gp = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    alluvial: Em,
    area: bm,
    axis: _m,
    baseTooltip: Cn,
    boxplot: Tm,
    canvasZoomSettings: vm,
    carbonPrefix: wm,
    circlePack: na,
    color: xm,
    defaultLegendAdditionalItems: ra,
    grid: ia,
    heatmap: Nm,
    histogram: Mm,
    legend: Sm,
    lines: Am,
    meter: Om,
    options: op,
    pie: Pm,
    radar: $m,
    ruler: aa,
    spacers: Cm,
    timeScale: Xt,
    toolbar: Rm,
    tooltips: Dm,
    transitions: Im,
    zoomBar: Fm
  }, Symbol.toStringTag, {
    value: "Module"
  }));
function Bp(t, e, n) {
  let r = null;
  return function (...i) {
    const a = this;
    a.mousePosition = hd(i[0], n), clearTimeout(r), r = setTimeout(function () {
      t.apply(a, i);
    }, e);
  };
}
function jp(t, e) {
  var i;
  const n = lf(t),
    r = Object.keys(e.axes || {});
  (i = e == null ? void 0 : e.toolbar) != null && i.controls && delete n.toolbar.controls, r.length === 0 && delete n.axes;
  for (const a in n.axes) if (r.includes(a)) {
    const o = e.axes[a];
    (o.primary || o.secondary) && console.warn("`primary` & `secondary` are no longer needed for axis configurations. Read more here https://charts.carbondesignsystem.com/");
    const s = o.mapsTo;
    if (s == null) {
      const u = o.scaleType;
      u == null ? o.mapsTo = "value" : u === Ln.TIME ? o.mapsTo = "date" : u === Ln.LABELS && (o.mapsTo = "key");
    }
  } else delete n.axes[a];
  return sp(n, e), y(n, e);
}
function qp(t) {
  if (!t) return;
  const e = /translate\([0-9]+\.?[0-9]*,[0-9]+\.?[0-9]*\)/,
    n = t.getAttribute("transform").match(e);
  if (!n) return null;
  if (n[0]) {
    const r = n[0].replace(/translate\(/, "").replace(/\)/, "").split(",");
    return {
      tx: r[0],
      ty: r[1]
    };
  }
  return null;
}
function zp(t) {
  const n = /\(([^)]+)\)/.exec(t);
  if (n && n.length > 1) {
    const r = n[1].split(",");
    if (r.length > 1) return {
      x: parseFloat(r[0]),
      y: parseFloat(r[1])
    };
  }
  return {
    x: 0,
    y: 0
  };
}
function Xp(t, e, n = "value", r = !1) {
  const i = t / e.reduce((a, o) => a + o[n], 0) * 100;
  return r ? i : i % 1 !== 0 ? parseFloat(i.toFixed(1)) : i;
}
function Up(t, e, n) {
  if (n > t.length) return t;
  if (e === ae.MID_LINE) return t.substr(0, n / 2) + "..." + t.substr(-n / 2);
  if (e === ae.FRONT_LINE) return "..." + t.substr(-n);
  if (e === ae.END_LINE) return t.substr(0, n) + "...";
}
function sp(t, e) {
  const n = nt(t, "legend", "additionalItems"),
    r = nt(e, "legend", "additionalItems");
  if (n && r) {
    const i = r.map(s => s.type),
      a = n.map(s => s.type),
      o = ra.filter(s => a.includes(s.type) && !i.includes(s.type));
    t.legend.additionalItems = o, e.legend.additionalItems = gl(o, r, "name");
  }
}
const nt = (t, ...e) => {
    let n = t;
    if (n) {
      for (const r of e) if (n[r] !== null && n[r] !== void 0) n = n[r];else return null;
      return n;
    }
    return null;
  },
  up = (t, e) => e === Gr.HORIZONTAL ? {
    y0: t.x0,
    y1: t.x1,
    x0: t.y0,
    x1: t.y1
  } : t,
  Vp = (t, e) => {
    const {
      x0: n,
      x1: r,
      y0: i,
      y1: a
    } = up(t, e);
    return `M${n},${i}L${n},${a}L${r},${a}L${r},${i}L${n},${i}`;
  };
function Qp(t, e, n) {
  return n === Gr.VERTICAL ? [t, e] : [e, t];
}
const da = 6048e5,
  cp = 864e5,
  Kp = 6e4,
  Zp = 36e5;
function Fr(t) {
  const e = L(t);
  return e.setHours(0, 0, 0, 0), e;
}
function Lr(t) {
  const e = L(t),
    n = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate(), e.getHours(), e.getMinutes(), e.getSeconds(), e.getMilliseconds()));
  return n.setUTCFullYear(e.getFullYear()), +t - +n;
}
function fp(t, e) {
  const n = Fr(t),
    r = Fr(e),
    i = +n - Lr(n),
    a = +r - Lr(r);
  return Math.round((i - a) / cp);
}
function st(t, e) {
  return t instanceof Date ? new t.constructor(e) : new Date(e);
}
function lp(t) {
  const e = L(t),
    n = st(t, 0);
  return n.setFullYear(e.getFullYear(), 0, 1), n.setHours(0, 0, 0, 0), n;
}
function hp(t) {
  const e = L(t);
  return fp(e, lp(e)) + 1;
}
function Te(t) {
  return It(t, {
    weekStartsOn: 1
  });
}
function ga(t) {
  const e = L(t),
    n = e.getFullYear(),
    r = st(t, 0);
  r.setFullYear(n + 1, 0, 4), r.setHours(0, 0, 0, 0);
  const i = Te(r),
    a = st(t, 0);
  a.setFullYear(n, 0, 4), a.setHours(0, 0, 0, 0);
  const o = Te(a);
  return e.getTime() >= i.getTime() ? n + 1 : e.getTime() >= o.getTime() ? n : n - 1;
}
function dp(t) {
  const e = ga(t),
    n = st(t, 0);
  return n.setFullYear(e, 0, 4), n.setHours(0, 0, 0, 0), Te(n);
}
function gp(t) {
  const e = L(t),
    n = +Te(e) - +dp(e);
  return Math.round(n / da) + 1;
}
function ma(t, e) {
  var f, l, h, d;
  const n = L(t),
    r = n.getFullYear(),
    i = Se(),
    a = (e == null ? void 0 : e.firstWeekContainsDate) ?? ((l = (f = e == null ? void 0 : e.locale) == null ? void 0 : f.options) == null ? void 0 : l.firstWeekContainsDate) ?? i.firstWeekContainsDate ?? ((d = (h = i.locale) == null ? void 0 : h.options) == null ? void 0 : d.firstWeekContainsDate) ?? 1,
    o = st(t, 0);
  o.setFullYear(r + 1, 0, a), o.setHours(0, 0, 0, 0);
  const s = It(o, e),
    u = st(t, 0);
  u.setFullYear(r, 0, a), u.setHours(0, 0, 0, 0);
  const c = It(u, e);
  return n.getTime() >= s.getTime() ? r + 1 : n.getTime() >= c.getTime() ? r : r - 1;
}
function mp(t, e) {
  var s, u, c, f;
  const n = Se(),
    r = (e == null ? void 0 : e.firstWeekContainsDate) ?? ((u = (s = e == null ? void 0 : e.locale) == null ? void 0 : s.options) == null ? void 0 : u.firstWeekContainsDate) ?? n.firstWeekContainsDate ?? ((f = (c = n.locale) == null ? void 0 : c.options) == null ? void 0 : f.firstWeekContainsDate) ?? 1,
    i = ma(t, e),
    a = st(t, 0);
  return a.setFullYear(i, 0, r), a.setHours(0, 0, 0, 0), It(a, e);
}
function pp(t, e) {
  const n = L(t),
    r = +It(n, e) - +mp(n, e);
  return Math.round(r / da) + 1;
}
function x(t, e) {
  const n = t < 0 ? "-" : "",
    r = Math.abs(t).toString().padStart(e, "0");
  return n + r;
}
const Q = {
    // Year
    y(t, e) {
      const n = t.getFullYear(),
        r = n > 0 ? n : 1 - n;
      return x(e === "yy" ? r % 100 : r, e.length);
    },
    // Month
    M(t, e) {
      const n = t.getMonth();
      return e === "M" ? String(n + 1) : x(n + 1, 2);
    },
    // Day of the month
    d(t, e) {
      return x(t.getDate(), e.length);
    },
    // AM or PM
    a(t, e) {
      const n = t.getHours() / 12 >= 1 ? "pm" : "am";
      switch (e) {
        case "a":
        case "aa":
          return n.toUpperCase();
        case "aaa":
          return n;
        case "aaaaa":
          return n[0];
        case "aaaa":
        default:
          return n === "am" ? "a.m." : "p.m.";
      }
    },
    // Hour [1-12]
    h(t, e) {
      return x(t.getHours() % 12 || 12, e.length);
    },
    // Hour [0-23]
    H(t, e) {
      return x(t.getHours(), e.length);
    },
    // Minute
    m(t, e) {
      return x(t.getMinutes(), e.length);
    },
    // Second
    s(t, e) {
      return x(t.getSeconds(), e.length);
    },
    // Fraction of second
    S(t, e) {
      const n = e.length,
        r = t.getMilliseconds(),
        i = Math.trunc(r * Math.pow(10, n - 3));
      return x(i, e.length);
    }
  },
  ht = {
    am: "am",
    pm: "pm",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  kr = {
    // Era
    G: function (t, e, n) {
      const r = t.getFullYear() > 0 ? 1 : 0;
      switch (e) {
        case "G":
        case "GG":
        case "GGG":
          return n.era(r, {
            width: "abbreviated"
          });
        case "GGGGG":
          return n.era(r, {
            width: "narrow"
          });
        case "GGGG":
        default:
          return n.era(r, {
            width: "wide"
          });
      }
    },
    // Year
    y: function (t, e, n) {
      if (e === "yo") {
        const r = t.getFullYear(),
          i = r > 0 ? r : 1 - r;
        return n.ordinalNumber(i, {
          unit: "year"
        });
      }
      return Q.y(t, e);
    },
    // Local week-numbering year
    Y: function (t, e, n, r) {
      const i = ma(t, r),
        a = i > 0 ? i : 1 - i;
      if (e === "YY") {
        const o = a % 100;
        return x(o, 2);
      }
      return e === "Yo" ? n.ordinalNumber(a, {
        unit: "year"
      }) : x(a, e.length);
    },
    // ISO week-numbering year
    R: function (t, e) {
      const n = ga(t);
      return x(n, e.length);
    },
    // Extended year. This is a single number designating the year of this calendar system.
    // The main difference between `y` and `u` localizers are B.C. years:
    // | Year | `y` | `u` |
    // |------|-----|-----|
    // | AC 1 |   1 |   1 |
    // | BC 1 |   1 |   0 |
    // | BC 2 |   2 |  -1 |
    // Also `yy` always returns the last two digits of a year,
    // while `uu` pads single digit years to 2 characters and returns other years unchanged.
    u: function (t, e) {
      const n = t.getFullYear();
      return x(n, e.length);
    },
    // Quarter
    Q: function (t, e, n) {
      const r = Math.ceil((t.getMonth() + 1) / 3);
      switch (e) {
        case "Q":
          return String(r);
        case "QQ":
          return x(r, 2);
        case "Qo":
          return n.ordinalNumber(r, {
            unit: "quarter"
          });
        case "QQQ":
          return n.quarter(r, {
            width: "abbreviated",
            context: "formatting"
          });
        case "QQQQQ":
          return n.quarter(r, {
            width: "narrow",
            context: "formatting"
          });
        case "QQQQ":
        default:
          return n.quarter(r, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // Stand-alone quarter
    q: function (t, e, n) {
      const r = Math.ceil((t.getMonth() + 1) / 3);
      switch (e) {
        case "q":
          return String(r);
        case "qq":
          return x(r, 2);
        case "qo":
          return n.ordinalNumber(r, {
            unit: "quarter"
          });
        case "qqq":
          return n.quarter(r, {
            width: "abbreviated",
            context: "standalone"
          });
        case "qqqqq":
          return n.quarter(r, {
            width: "narrow",
            context: "standalone"
          });
        case "qqqq":
        default:
          return n.quarter(r, {
            width: "wide",
            context: "standalone"
          });
      }
    },
    // Month
    M: function (t, e, n) {
      const r = t.getMonth();
      switch (e) {
        case "M":
        case "MM":
          return Q.M(t, e);
        case "Mo":
          return n.ordinalNumber(r + 1, {
            unit: "month"
          });
        case "MMM":
          return n.month(r, {
            width: "abbreviated",
            context: "formatting"
          });
        case "MMMMM":
          return n.month(r, {
            width: "narrow",
            context: "formatting"
          });
        case "MMMM":
        default:
          return n.month(r, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // Stand-alone month
    L: function (t, e, n) {
      const r = t.getMonth();
      switch (e) {
        case "L":
          return String(r + 1);
        case "LL":
          return x(r + 1, 2);
        case "Lo":
          return n.ordinalNumber(r + 1, {
            unit: "month"
          });
        case "LLL":
          return n.month(r, {
            width: "abbreviated",
            context: "standalone"
          });
        case "LLLLL":
          return n.month(r, {
            width: "narrow",
            context: "standalone"
          });
        case "LLLL":
        default:
          return n.month(r, {
            width: "wide",
            context: "standalone"
          });
      }
    },
    // Local week of year
    w: function (t, e, n, r) {
      const i = pp(t, r);
      return e === "wo" ? n.ordinalNumber(i, {
        unit: "week"
      }) : x(i, e.length);
    },
    // ISO week of year
    I: function (t, e, n) {
      const r = gp(t);
      return e === "Io" ? n.ordinalNumber(r, {
        unit: "week"
      }) : x(r, e.length);
    },
    // Day of the month
    d: function (t, e, n) {
      return e === "do" ? n.ordinalNumber(t.getDate(), {
        unit: "date"
      }) : Q.d(t, e);
    },
    // Day of year
    D: function (t, e, n) {
      const r = hp(t);
      return e === "Do" ? n.ordinalNumber(r, {
        unit: "dayOfYear"
      }) : x(r, e.length);
    },
    // Day of week
    E: function (t, e, n) {
      const r = t.getDay();
      switch (e) {
        case "E":
        case "EE":
        case "EEE":
          return n.day(r, {
            width: "abbreviated",
            context: "formatting"
          });
        case "EEEEE":
          return n.day(r, {
            width: "narrow",
            context: "formatting"
          });
        case "EEEEEE":
          return n.day(r, {
            width: "short",
            context: "formatting"
          });
        case "EEEE":
        default:
          return n.day(r, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // Local day of week
    e: function (t, e, n, r) {
      const i = t.getDay(),
        a = (i - r.weekStartsOn + 8) % 7 || 7;
      switch (e) {
        case "e":
          return String(a);
        case "ee":
          return x(a, 2);
        case "eo":
          return n.ordinalNumber(a, {
            unit: "day"
          });
        case "eee":
          return n.day(i, {
            width: "abbreviated",
            context: "formatting"
          });
        case "eeeee":
          return n.day(i, {
            width: "narrow",
            context: "formatting"
          });
        case "eeeeee":
          return n.day(i, {
            width: "short",
            context: "formatting"
          });
        case "eeee":
        default:
          return n.day(i, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // Stand-alone local day of week
    c: function (t, e, n, r) {
      const i = t.getDay(),
        a = (i - r.weekStartsOn + 8) % 7 || 7;
      switch (e) {
        case "c":
          return String(a);
        case "cc":
          return x(a, e.length);
        case "co":
          return n.ordinalNumber(a, {
            unit: "day"
          });
        case "ccc":
          return n.day(i, {
            width: "abbreviated",
            context: "standalone"
          });
        case "ccccc":
          return n.day(i, {
            width: "narrow",
            context: "standalone"
          });
        case "cccccc":
          return n.day(i, {
            width: "short",
            context: "standalone"
          });
        case "cccc":
        default:
          return n.day(i, {
            width: "wide",
            context: "standalone"
          });
      }
    },
    // ISO day of week
    i: function (t, e, n) {
      const r = t.getDay(),
        i = r === 0 ? 7 : r;
      switch (e) {
        case "i":
          return String(i);
        case "ii":
          return x(i, e.length);
        case "io":
          return n.ordinalNumber(i, {
            unit: "day"
          });
        case "iii":
          return n.day(r, {
            width: "abbreviated",
            context: "formatting"
          });
        case "iiiii":
          return n.day(r, {
            width: "narrow",
            context: "formatting"
          });
        case "iiiiii":
          return n.day(r, {
            width: "short",
            context: "formatting"
          });
        case "iiii":
        default:
          return n.day(r, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // AM or PM
    a: function (t, e, n) {
      const i = t.getHours() / 12 >= 1 ? "pm" : "am";
      switch (e) {
        case "a":
        case "aa":
          return n.dayPeriod(i, {
            width: "abbreviated",
            context: "formatting"
          });
        case "aaa":
          return n.dayPeriod(i, {
            width: "abbreviated",
            context: "formatting"
          }).toLowerCase();
        case "aaaaa":
          return n.dayPeriod(i, {
            width: "narrow",
            context: "formatting"
          });
        case "aaaa":
        default:
          return n.dayPeriod(i, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // AM, PM, midnight, noon
    b: function (t, e, n) {
      const r = t.getHours();
      let i;
      switch (r === 12 ? i = ht.noon : r === 0 ? i = ht.midnight : i = r / 12 >= 1 ? "pm" : "am", e) {
        case "b":
        case "bb":
          return n.dayPeriod(i, {
            width: "abbreviated",
            context: "formatting"
          });
        case "bbb":
          return n.dayPeriod(i, {
            width: "abbreviated",
            context: "formatting"
          }).toLowerCase();
        case "bbbbb":
          return n.dayPeriod(i, {
            width: "narrow",
            context: "formatting"
          });
        case "bbbb":
        default:
          return n.dayPeriod(i, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // in the morning, in the afternoon, in the evening, at night
    B: function (t, e, n) {
      const r = t.getHours();
      let i;
      switch (r >= 17 ? i = ht.evening : r >= 12 ? i = ht.afternoon : r >= 4 ? i = ht.morning : i = ht.night, e) {
        case "B":
        case "BB":
        case "BBB":
          return n.dayPeriod(i, {
            width: "abbreviated",
            context: "formatting"
          });
        case "BBBBB":
          return n.dayPeriod(i, {
            width: "narrow",
            context: "formatting"
          });
        case "BBBB":
        default:
          return n.dayPeriod(i, {
            width: "wide",
            context: "formatting"
          });
      }
    },
    // Hour [1-12]
    h: function (t, e, n) {
      if (e === "ho") {
        let r = t.getHours() % 12;
        return r === 0 && (r = 12), n.ordinalNumber(r, {
          unit: "hour"
        });
      }
      return Q.h(t, e);
    },
    // Hour [0-23]
    H: function (t, e, n) {
      return e === "Ho" ? n.ordinalNumber(t.getHours(), {
        unit: "hour"
      }) : Q.H(t, e);
    },
    // Hour [0-11]
    K: function (t, e, n) {
      const r = t.getHours() % 12;
      return e === "Ko" ? n.ordinalNumber(r, {
        unit: "hour"
      }) : x(r, e.length);
    },
    // Hour [1-24]
    k: function (t, e, n) {
      let r = t.getHours();
      return r === 0 && (r = 24), e === "ko" ? n.ordinalNumber(r, {
        unit: "hour"
      }) : x(r, e.length);
    },
    // Minute
    m: function (t, e, n) {
      return e === "mo" ? n.ordinalNumber(t.getMinutes(), {
        unit: "minute"
      }) : Q.m(t, e);
    },
    // Second
    s: function (t, e, n) {
      return e === "so" ? n.ordinalNumber(t.getSeconds(), {
        unit: "second"
      }) : Q.s(t, e);
    },
    // Fraction of second
    S: function (t, e) {
      return Q.S(t, e);
    },
    // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
    X: function (t, e, n) {
      const r = t.getTimezoneOffset();
      if (r === 0) return "Z";
      switch (e) {
        case "X":
          return Yr(r);
        case "XXXX":
        case "XX":
          return J(r);
        case "XXXXX":
        case "XXX":
        default:
          return J(r, ":");
      }
    },
    // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
    x: function (t, e, n) {
      const r = t.getTimezoneOffset();
      switch (e) {
        case "x":
          return Yr(r);
        case "xxxx":
        case "xx":
          return J(r);
        case "xxxxx":
        case "xxx":
        default:
          return J(r, ":");
      }
    },
    // Timezone (GMT)
    O: function (t, e, n) {
      const r = t.getTimezoneOffset();
      switch (e) {
        case "O":
        case "OO":
        case "OOO":
          return "GMT" + Wr(r, ":");
        case "OOOO":
        default:
          return "GMT" + J(r, ":");
      }
    },
    // Timezone (specific non-location)
    z: function (t, e, n) {
      const r = t.getTimezoneOffset();
      switch (e) {
        case "z":
        case "zz":
        case "zzz":
          return "GMT" + Wr(r, ":");
        case "zzzz":
        default:
          return "GMT" + J(r, ":");
      }
    },
    // Seconds timestamp
    t: function (t, e, n) {
      const r = Math.trunc(t.getTime() / 1e3);
      return x(r, e.length);
    },
    // Milliseconds timestamp
    T: function (t, e, n) {
      const r = t.getTime();
      return x(r, e.length);
    }
  };
function Wr(t, e = "") {
  const n = t > 0 ? "-" : "+",
    r = Math.abs(t),
    i = Math.trunc(r / 60),
    a = r % 60;
  return a === 0 ? n + String(i) : n + String(i) + e + x(a, 2);
}
function Yr(t, e) {
  return t % 60 === 0 ? (t > 0 ? "-" : "+") + x(Math.abs(t) / 60, 2) : J(t, e);
}
function J(t, e = "") {
  const n = t > 0 ? "-" : "+",
    r = Math.abs(t),
    i = x(Math.trunc(r / 60), 2),
    a = x(r % 60, 2);
  return n + i + e + a;
}
const Hr = (t, e) => {
    switch (t) {
      case "P":
        return e.date({
          width: "short"
        });
      case "PP":
        return e.date({
          width: "medium"
        });
      case "PPP":
        return e.date({
          width: "long"
        });
      case "PPPP":
      default:
        return e.date({
          width: "full"
        });
    }
  },
  pa = (t, e) => {
    switch (t) {
      case "p":
        return e.time({
          width: "short"
        });
      case "pp":
        return e.time({
          width: "medium"
        });
      case "ppp":
        return e.time({
          width: "long"
        });
      case "pppp":
      default:
        return e.time({
          width: "full"
        });
    }
  },
  yp = (t, e) => {
    const n = t.match(/(P+)(p+)?/) || [],
      r = n[1],
      i = n[2];
    if (!i) return Hr(t, e);
    let a;
    switch (r) {
      case "P":
        a = e.dateTime({
          width: "short"
        });
        break;
      case "PP":
        a = e.dateTime({
          width: "medium"
        });
        break;
      case "PPP":
        a = e.dateTime({
          width: "long"
        });
        break;
      case "PPPP":
      default:
        a = e.dateTime({
          width: "full"
        });
        break;
    }
    return a.replace("{{date}}", Hr(r, e)).replace("{{time}}", pa(i, e));
  },
  wp = {
    p: pa,
    P: yp
  },
  bp = /^D+$/,
  _p = /^Y+$/,
  vp = ["D", "DD", "YY", "YYYY"];
function xp(t) {
  return bp.test(t);
}
function Tp(t) {
  return _p.test(t);
}
function Mp(t, e, n) {
  const r = Sp(t, e, n);
  if (console.warn(r), vp.includes(t)) throw new RangeError(r);
}
function Sp(t, e, n) {
  const r = t[0] === "Y" ? "years" : "days of the month";
  return `Use \`${t.toLowerCase()}\` instead of \`${t}\` (in \`${e}\`) for formatting ${r} to the input \`${n}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
function Ap(t) {
  return t instanceof Date || typeof t == "object" && Object.prototype.toString.call(t) === "[object Date]";
}
function Op(t) {
  if (!Ap(t) && typeof t != "number") return !1;
  const e = L(t);
  return !isNaN(Number(e));
}
const Pp = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g,
  $p = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g,
  Ep = /^'([^]*?)'?$/,
  Np = /''/g,
  Cp = /[a-zA-Z]/;
function on(t, e, n) {
  var f, l, h, d, g, m, p, v;
  const r = Se(),
    i = (n == null ? void 0 : n.locale) ?? r.locale ?? Br,
    a = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((l = (f = n == null ? void 0 : n.locale) == null ? void 0 : f.options) == null ? void 0 : l.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((d = (h = r.locale) == null ? void 0 : h.options) == null ? void 0 : d.firstWeekContainsDate) ?? 1,
    o = (n == null ? void 0 : n.weekStartsOn) ?? ((m = (g = n == null ? void 0 : n.locale) == null ? void 0 : g.options) == null ? void 0 : m.weekStartsOn) ?? r.weekStartsOn ?? ((v = (p = r.locale) == null ? void 0 : p.options) == null ? void 0 : v.weekStartsOn) ?? 0,
    s = L(t);
  if (!Op(s)) throw new RangeError("Invalid time value");
  let u = e.match($p).map(w => {
    const _ = w[0];
    if (_ === "p" || _ === "P") {
      const A = wp[_];
      return A(w, i.formatLong);
    }
    return w;
  }).join("").match(Pp).map(w => {
    if (w === "''") return {
      isToken: !1,
      value: "'"
    };
    const _ = w[0];
    if (_ === "'") return {
      isToken: !1,
      value: Dp(w)
    };
    if (kr[_]) return {
      isToken: !0,
      value: w
    };
    if (_.match(Cp)) throw new RangeError("Format string contains an unescaped latin alphabet character `" + _ + "`");
    return {
      isToken: !1,
      value: w
    };
  });
  i.localize.preprocessor && (u = i.localize.preprocessor(s, u));
  const c = {
    firstWeekContainsDate: a,
    weekStartsOn: o,
    locale: i
  };
  return u.map(w => {
    if (!w.isToken) return w.value;
    const _ = w.value;
    (!(n != null && n.useAdditionalWeekYearTokens) && Tp(_) || !(n != null && n.useAdditionalDayOfYearTokens) && xp(_)) && Mp(_, e, String(t));
    const A = kr[_[0]];
    return A(s, _, i.localize, c);
  }).join("");
}
function Dp(t) {
  const e = t.match(Ep);
  return e ? e[1].replace(Np, "'") : t;
}
const He = [["15seconds", 15 * 1e3], ["minute", 60 * 1e3], ["30minutes", 30 * 60 * 1e3], ["hourly", 60 * 60 * 1e3], ["daily", 24 * 60 * 60 * 1e3], ["monthly", 30 * 24 * 60 * 60 * 1e3], ["quarterly", 3 * 30 * 24 * 60 * 60 * 1e3], ["yearly", 12 * 30 * 24 * 60 * 60 * 1e3]];
function Ip(t, e, n, r, i) {
  const a = e === 0,
    o = Number(on(new Date(t), "c")) === 2,
    s = Number(on(new Date(t), "q")) === 1,
    u = e !== 0 ? n[e - 1] : null;
  switch (r) {
    case "15seconds":
      return a || ie(t) || $t(t, u) || K(t);
    case "minute":
      return a || ie(t) || $t(t, u) || K(t);
    case "30minutes":
      return a || ie(t) || $t(t, u) || K(t);
    case "hourly":
      return a || ie(t) || $t(t, u) || K(t);
    case "daily":
      return i ? a || o || K(t) : a || $t(t, u) || K(t);
    case "weekly":
      return a || o || K(t);
    case "monthly":
      return a || K(t);
    case "quarterly":
      return a || s;
    case "yearly":
      return !1;
    default:
      throw new Error(`${r} is not a valid time interval.`);
  }
}
function Jp(t, e, n, r, i, a) {
  const o = i.showDayName,
    s = r === "daily" && o ? "weekly" : r,
    u = new Date(t),
    c = nt(i, "timeIntervalFormats")[s],
    f = nt(c, "primary"),
    l = nt(c, "secondary"),
    h = Ip(t, e, n, r, o);
  let d = h ? f : l;
  r === "15seconds" && u.getMilliseconds() !== 0 && (d = d.replace("pp", "h:mm:ss.SSS a"));
  const g = i.localeObject,
    {
      code: m,
      optionsObject: p
    } = a,
    v = p[r].type,
    w = p[r][h ? "primary" : "secondary"][d];
  if (r === "quarterly" || !w) {
    const A = on(u, d, {
      locale: g
    }).split("").map(S => {
      const Vt = Number(S);
      return S !== " " && !Number.isNaN(Vt) ? Vt.toLocaleString(m) : S;
    });
    return a[v](u, m, {}, A.join(""));
  } else return a[v](u, m, w);
}
function Me(t) {
  const e = new Date(t);
  return {
    M: e.getMonth() + 1,
    // month: 1-12
    d: e.getDate(),
    // day of the month: 1-31
    H: e.getHours(),
    // 24-hour clock: 0-23
    m: e.getMinutes(),
    // minute: 0-59
    s: e.getSeconds()
    // seconds: 0-59
  };
}
function Rp(t) {
  if (t) return t.slice(1).map((e, n) => e - t[n]);
}
function Fp(t) {
  const e = He.reduce((n, [, r], i) => {
    const a = He[n][1],
      o = Math.abs(a - t),
      s = Math.abs(r - t);
    return o < s ? n : i;
  }, 0);
  return He[e][0];
}
function t0(t, e) {
  if (Sa[e]) return e;
  if (t.length === 1) return "15seconds";
  const n = Rp(t),
    r = Pl(n);
  return Fp(r);
}
function ie(t) {
  const {
    s: e,
    m: n,
    H: r
  } = Me(t);
  return r === 0 && n === 0 && e === 0;
}
function $t(t, e) {
  const n = Me(t).M,
    r = Me(e).M;
  return n !== r;
}
function K(t) {
  const {
    M: e,
    d: n,
    s: r,
    m: i,
    H: a
  } = Me(t);
  return e === 1 && n === 1 && a === 0 && i === 0 && r === 0;
}
function e0(t) {
  return t === void 0 ? "" : t.toISOString();
}
function Lp(t) {
  const e = bl(t, r => r.value),
    n = ta().domain(e).nice().domain();
  if (n[0] > 0) n[0] = 0;else if (n[0] === 0 && n[1] === 0) return [0, 1];
  return n[0] < 0 && n[1] > 0 && (Math.abs(n[0]) > n[1] ? n[1] = Math.abs(n[0]) : n[0] = -n[1]), n;
}
function n0(t, e) {
  const n = nt(e, "gradient", "colors"),
    r = !fl(n);
  let i = nt(e, "pairing", "option");
  const a = Lp(t),
    o = a[0] < 0 && a[1] > 0 ? "diverge" : "mono";
  (i < 1 && i > 4 && o === "mono" || i < 1 && i > 2 && o === "diverge") && (i = 1);
  const s = r ? n : [];
  if (!r) {
    const u = o === "diverge" ? 17 : 11;
    for (let c = 1; c < u + 1; c++) s.push(`fill-${o}-${i}-${c}`);
  }
  return ea().domain(a).range(s);
}
export { Vr as $, $m as A, aa as B, Cm as C, Xt as D, Dm as E, Im as F, Rm as G, Fm as H, t0 as I, e0 as J, Jp as K, n0 as L, Lp as M, Me as N, Ip as O, sn as P, X as Q, G as R, F as S, He as T, cd as U, um as V, Nn as W, xi as X, Ol as Y, pm as Z, gm as _, y as a, tl as a0, $ as a1, po as a2, ii as a3, vn as a4, mf as a5, Mi as a6, An as a7, Xd as a8, hd as a9, sp as aA, ea as aB, Bo as aC, ts as aD, ri as aE, ni as aF, Ne as aG, al as aH, Iu as aI, Gt as aJ, Qu as aK, oe as aL, mm as aM, xe as aN, Ki as aO, L as aP, st as aQ, fp as aR, Zp as aS, Kp as aT, Ou as aa, Up as ab, fl as ac, bl as ad, Qp as ae, zp as af, qp as ag, Bp as ah, ta as ai, Vp as aj, up as ak, D as al, Xp as am, Rd as an, Wp as ao, Pl as ap, Nm as aq, Hp as ar, it as as, Md as at, ln as au, se as av, Qe as aw, Al as ax, Ti as ay, Yp as az, nl as b, lf as c, Gp as d, Em as e, bm as f, nt as g, _m as h, Bt as i, Cn as j, Pe as k, Tm as l, jp as m, vm as n, op as o, wm as p, na as q, xm as r, ra as s, Su as t, ia as u, Mm as v, Sm as w, Am as x, Om as y, Pm as z };
